import React, { useState, useEffect, useContext, useCallback } from "react";
import styled from "styled-components";
import * as Sentry from "@sentry/react";
import { useQuery, useMutation, useLazyQuery, gql } from "@apollo/client";
import moment from "moment-timezone";
import _ from "lodash";
import InfiniteScroll from "react-infinite-scroll-component";
import ReactTooltip from "react-tooltip";
import { useDebounce, useWindowSize } from "src/utils/hooks";
import { useFlags } from "launchdarkly-react-client-sdk";

import { theme } from "src/utils/theme";
import {
  AppText,
  AppSidebarCard,
  AppTextArea,
  AppSelect,
  Loading,
  AppErrorText,
  FlexDiv,
  StatCard,
  SkeletonBlock,
} from "src/Components/UI";
import { AppTabDiv } from "src/Components/UI/AppTabDiv";
import { NewAppButton } from "src/Components/UI/NewAppButton";
import { PhoenixAppButton, PhoenixIcon } from "src/Components/UI/Phoenix";
import { calendar, clock, keypad, mic, mic_off, phone_end, user_white } from "src/images/NewDesign";
import { iconStartRecording, iconStopRecording } from "src/images";

import { formatPhoneNumber, formatTime, formatUSD } from "src/utils/format";
import { MixpanelActions } from "src/services/mixpanel";
import { appToast } from "src/utils/toast";
import { currentCallState, inputDevices, outputDevices, twilioMuted, loggedInUser } from "src/apollo/cache";
import { IS_ON_CALL } from "src/apollo/query";
import { CallContext, ModalContext } from "src/context";
import { ScheduleContext } from "src/context/ScheduleContext";
import { MyScheduleCal } from "src/Components/Smart/MyScheduleCal";
import { MyMissedCal } from "src/Components/Smart/MyMissedCal";
import {
  Modal,
  CallFeedbackModal,
  JoinDetailsModal,
  CallParticipantsModal,
  RecordConsentModal,
  TwilioFeedbackModal,
} from "src/Components/modal";
import { TwilioWarnings } from "src/Components/Dumb/TwilioWarnings";

import { FETCH_CONFERENCE_PARTICIPANTS_POLL_INTERVAL } from "src/utils/variables";
import { ProfileImageWithText } from "../SettingSegments/ProfileImageWithText";
import { FavoriteDialsComponent } from "../CallSegments";
import CallMoreMenu from "./CallMoreMenu";
import CallParticipantsMenu from "./CallParticipantsMenu";

const COMPACT_BREAKPOINT = 1890;

const FETCH_LEAD = gql`
  query fetchLead($id: String) {
    fetchLead(id: $id) {
      id
      first_name
      last_name
      full_name
      business_name
      primary_email
      phase
      can_record_without_permission
      sequence_step {
        actions {
          id
          scheduled_event_behavior
        }
      }
    }
  }
`;

const FETCH_CONFERENCE_PARTICIPANTS = gql`
  query fetchConferenceParticipants {
    participants: fetchConferenceParticipants
  }
`;

const UPDATE_ACTIVE_TWILIO_RECORDING = gql`
  mutation updateActiveTwilioRecording($action: TwilioRecordingAction!) {
    updateActiveTwilioRecording(action: $action)
  }
`;

const UPDATE_DEFAULT_AUDIO = gql`
  mutation updateDefaultAudio($default_audio_input: String, $default_audio_output: String) {
    updateDefaultAudio(default_audio_input: $default_audio_input, default_audio_output: $default_audio_output) {
      id
      default_audio_input
      default_audio_output
    }
  }
`;

const FETCH_CALL_NOTES_TEMPLATE_PREVIEW = gql`
  query fetchCallNotesTemplatePreview($lead_id: String!) {
    fetchCallNotesTemplatePreview(lead_id: $lead_id) {
      body
    }
  }
`;

const FETCH_SCHEDULE_ITEMS_LIST = gql`
  query fetchScheduleItemsList($user_id: String!, $skip: Int!, $take: Int!) {
    fetchScheduleItemsList(user_id: $user_id, skip: $skip, take: $take) {
      id
      start_time
      end_time
      timezone
      deleted_at
      lead_intent {
        id
        event_type_label
        current_sequence_step
        lead {
          id
          full_name
          business_name
          lead_value {
            id
            value
          }
        }
      }
    }
  }
`;

const FETCH_SCHEDULE_ITEMS_LIST_WITH_HISTORICAL_EVENTS = gql`
  query fetchScheduleItemsListWithHistoricalEvents($user_id: String!, $skip: Int!, $take: Int!) {
    fetchScheduleItemsListWithHistoricalEvents(user_id: $user_id, skip: $skip, take: $take) {
      schedule_item {
        id
        start_time
        end_time
        timezone
        computed_event_type
        deleted_at
      }
      lead {
        id
        full_name
        business_name
        lead_value {
          id
          value
        }
      }
    }
  }
`;

interface CallComponentProps {
  callStartTime?: Date;
  phoneNumber?: string;
  callState?: boolean;
  setBlinds: React.Dispatch<React.SetStateAction<boolean>>;
  closeOtherModals?: () => void;
  popup: boolean;
  setPopup: (popup: boolean) => void;
  currentTask: string;
  setCurrentTask: (currentTask: string) => void;
}

const CallV2: React.FC<CallComponentProps> = ({ callStartTime, phoneNumber, callState, closeOtherModals }) => {
  const {
    showDialer,
    toggleDialer,
    showCallFeedbackModal,
    setShowCallFeedbackModal,
    showJoinDetailsModal,
    setShowJoinDetailsModal,
    setShowConsentModal,
  } = useContext(ModalContext);

  const {
    callNotes,
    updateCallNotes,
    recording,
    toggleDidStartRecording,
    startedRecording,
    toggleRecording,
    recordingButtonDisabled,
    persistCallNotes,
    callNotesLastUpdated,
    toggleMute,
    hangup,
    getOutputDevice,
    device,
    changeInputDevice,
    changeOutputDevice,
    isNonCallActivity,
    defaultInput,
    setDefaultInput,
    defaultOutput,
    setDefaultOutput,
    timeOnCall,
    setTimeOnCall,
    deviceResetCheck,
    setDeviceResetCheck,
    setIsConnectingToTwilio,
    isConnectingToTwilio,
    callLeadId,
  } = useContext(CallContext);

  const user = loggedInUser();
  const { width } = useWindowSize();
  const { connectionWarnings } = useFlags();
  const { expandSchedule } = useContext(ScheduleContext);

  const isExpandedCallView = width > COMPACT_BREAKPOINT;
  const showHistoricalEvents = user.organization?.show_historical_events ?? false;

  const [callSettingsModal, setCallSettingsModal] = useState(false);
  const [scheduleTab, setScheduleTab] = useState("calendar");
  const [scheduleListItems, setScheduleList] = useState<any[]>([]);
  const scheduleListTake = 4;
  const [scheduleSkip, setScheduleSkip] = useState(0);
  const [hasMoreSchedule, setHasMoreSchedule] = useState(true);
  const [selectedAudio, setSelectedAudio] = useState<"input" | "output" | undefined>(undefined);

  const { data, loading, error, refetch } = useQuery(FETCH_LEAD, {
    fetchPolicy: "no-cache",
    variables: { id: callLeadId },
    onCompleted: (data) => {
      if (currentCallState().onCall) {
        setIsConnectingToTwilio(false);
        if (data.fetchLead?.can_record_without_permission) {
          appToast(
            "The lead lives in a one-party state",
            {},
            "Recording of the lead and the rep will begin automatically",
          );
          return;
        }
        appToast(
          "This call is not being recorded by default",
          {},
          "This prospect resides in an all-party consent state",
        );
      }
    },
    onError({ message, name }) {
      console.log(`Error in ${name}: `, message);
    },
  });

  const { data: isOnCall, loading: isOnCallLoading } = useQuery(IS_ON_CALL, {
    onError({ message, name }) {
      console.log(`Error in ${name}: `, message);
    },
  });

  const { data: participants, refetch: refetchParticipants, startPolling, stopPolling } = useQuery(
    FETCH_CONFERENCE_PARTICIPANTS,
    {
      onError({ message, name }) {
        console.log(`Error in ${name}: `, message);
      },
    },
  );

  useQuery(FETCH_CALL_NOTES_TEMPLATE_PREVIEW, {
    fetchPolicy: "network-only",
    variables: { lead_id: callLeadId },
    onCompleted: ({ fetchCallNotesTemplatePreview }) => {
      updateCallNotes(fetchCallNotesTemplatePreview?.body || "", callLeadId || "");
    },
    skip: !callLeadId,
  });

  const [updateActiveTwilioRecording] = useMutation(UPDATE_ACTIVE_TWILIO_RECORDING, {
    onCompleted: ({ updateActiveTwilioRecording }) => {
      if (!updateActiveTwilioRecording) {
        appToast("Recording turned off");
        toggleRecording(false);
      } else {
        appToast("Successfully recording");
        toggleRecording(true);
        toggleDidStartRecording(true);
      }
    },
    onError: ({ message }) => {
      Sentry.captureEvent({
        message: `updateActiveTwilioRecording graphql error: ${message}`,
      });
      appToast(`Error updating recording: ${message}`);
    },
  });

  const [updateDefaultAudio] = useMutation(UPDATE_DEFAULT_AUDIO, {
    onCompleted: ({ updateDefaultAudio }) => {
      appToast("Successfully updated default audio");
      selectedAudio === "input" && setDefaultInput(updateDefaultAudio.default_audio_input);
      selectedAudio === "output" && setDefaultOutput(updateDefaultAudio.default_audio_output);
      setSelectedAudio(undefined);
    },
    onError: ({ message }) => {
      console.log("Error in updateDefaultAudio: ", message);
      appToast(message);
      Sentry.captureEvent({
        message: `updateDefaultAudio GraphQL Error: ${message}`,
      });
    },
  });

  const [fetchScheduleList] = useLazyQuery(FETCH_SCHEDULE_ITEMS_LIST, {
    fetchPolicy: "no-cache",
    onCompleted: (scheduleList) => {
      const newList = [...scheduleListItems, ...scheduleList.fetchScheduleItemsList].reduce(
        (acc, cv) => (acc?.map((a: any) => a?.id).includes(cv?.id) ? acc : [...acc, cv]),
        [],
      );
      if (scheduleList.fetchScheduleItemsList.length < scheduleListTake) {
        setHasMoreSchedule(false);
      } else setHasMoreSchedule(true);
      setScheduleList(newList);
    },
    onError: ({ message, name }) => {
      console.log(`Error in ${name}: `, message);
    },
  });

  const [fetchScheduleListWithHistoricalEvents] = useLazyQuery(FETCH_SCHEDULE_ITEMS_LIST_WITH_HISTORICAL_EVENTS, {
    fetchPolicy: "no-cache",
    onCompleted: (dataHistorical) => {
      const newList = [...scheduleListItems, ...dataHistorical.fetchScheduleItemsListWithHistoricalEvents].reduce(
        (acc, cv) => (acc.map((a: any) => a?.schedule_item?.id).includes(cv?.schedule_item?.id) ? acc : [...acc, cv]),
        [],
      );
      if (dataHistorical.fetchScheduleItemsListWithHistoricalEvents.length < scheduleListTake) {
        setHasMoreSchedule(false);
      } else setHasMoreSchedule(true);
      setScheduleList(newList);
    },
    onError: ({ message, name }) => {
      console.log(`Error in ${name}: `, message);
    },
  });

  useEffect(() => {
    if (scheduleTab === "list") {
      if (showHistoricalEvents) {
        fetchScheduleListWithHistoricalEvents({
          variables: {
            user_id: loggedInUser().id,
            skip: scheduleSkip,
            take: scheduleListTake,
          },
        });
      } else {
        fetchScheduleList({
          variables: {
            user_id: loggedInUser().id,
            skip: scheduleSkip,
            take: scheduleListTake,
          },
        });
      }
    }
  }, [scheduleTab, scheduleSkip]);

  const handleShowMore = () => {
    setScheduleSkip(scheduleSkip + scheduleListTake);
  };

  const debouncedSaveNotes = useCallback(() => {
    if (!isNonCallActivity) {
      persistCallNotes();
    }
  }, [callNotes]);

  useDebounce(debouncedSaveNotes, [callNotes], 1000);

  const [currentTime, setCurrentTime] = useState(moment());

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(moment());
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (callState) {
      startPolling(FETCH_CONFERENCE_PARTICIPANTS_POLL_INTERVAL);
    } else {
      stopPolling();
    }
    return () => {
      stopPolling();
    };
  }, [callState]);

  useEffect(() => {
    if (currentCallState().onCall) {
      const timeDifference = Math.abs(currentTime.diff(moment(callStartTime), "seconds"));
      setTimeOnCall(timeDifference);
      if (deviceResetCheck) {
        setDeviceResetCheck(false);
      }
    }
  }, [currentTime]);

  useEffect(() => {
    if (currentCallState().onCall) {
      refetchParticipants();
    }
  }, [currentCallState().onCall]);

  const handleRecordingClick = () => {
    if (startedRecording) {
      updateActiveTwilioRecording({
        variables: {
          action: recording ? "Pause" : "Resume",
        },
      });
    } else {
      if (!data?.fetchLead?.can_record_without_permission) setShowConsentModal(true);
      else
        updateActiveTwilioRecording({
          variables: {
            action: "Start",
          },
        });
    }
  };

  const renderScheduleTab = () => (
    <AppSidebarCard height={40} title="My Schedule" width={432} showExpand style={{ overflow: "hidden" }}>
      <div
        style={{
          maxHeight: expandSchedule ? "fit-content" : "100px",
          overflow: expandSchedule ? "visible" : "hidden",
        }}
      >
        <ButtonContainer>
          <AppTabDiv
            selected={scheduleTab === "calendar"}
            onClick={() => {
              MixpanelActions.track("Schedule Event", { type: "click", tab: `${scheduleTab}`, view: "Dashboard" });
              setScheduleTab("calendar");
            }}
          >
            Schedule
          </AppTabDiv>
          <AppTabDiv
            selected={scheduleTab === "list"}
            onClick={() => {
              MixpanelActions.track("Schedule Event", { type: "click", tab: `${scheduleTab}`, view: "Dashboard" });
              setScheduleTab("list");
            }}
          >
            List
          </AppTabDiv>
          <AppTabDiv
            selected={scheduleTab === "missed"}
            onClick={() => {
              MixpanelActions.track("Missed Event", { type: "click", tab: `${scheduleTab}`, view: "Dashboard" });
              setScheduleTab("missed");
            }}
          >
            Missed Demos
          </AppTabDiv>
          <TabFiller />
        </ButtonContainer>
        {scheduleTab === "calendar" ? (
          <MyScheduleCal />
        ) : scheduleTab === "missed" ? (
          <MyMissedCal />
        ) : (
          <ScheduleScrollDiv id="schedule-list-div">
            <InfiniteScroll
              dataLength={scheduleListItems.length}
              next={handleShowMore}
              hasMore={hasMoreSchedule}
              loader={<Loading />}
              scrollableTarget="schedule-list-div"
            >
              {scheduleListItems.length > 0 &&
                (showHistoricalEvents
                  ? scheduleListItems.map((item: any, index: number) => {
                      const prevStartDate =
                        !!scheduleListItems[index - 1] &&
                        moment(scheduleListItems[index - 1].schedule_item.start_time).format("MMMM Do");
                      const currentStartDate = moment(item.schedule_item.start_time).format("MMMM Do");
                      return (
                        <ScheduleListDiv red={!!item?.schedule_item?.deleted_at} key={index}>
                          {prevStartDate !== currentStartDate && (
                            <DayDivider>
                              <AppText>{currentStartDate}</AppText>
                            </DayDivider>
                          )}
                          <ScheduleListItem key={item.schedule_item.id}>
                            <FlexDiv justify="flex-end" style={{ marginRight: "16px" }}>
                              <AppText
                                fontSize={12}
                                variant="primary"
                                style={{ borderBottom: "none", cursor: "pointer" }}
                                onClick={() =>
                                  window.open(`/manage-schedule/${item.schedule_item?.id}`, "_blank", "noreferrer")
                                }
                              >
                                View Details
                              </AppText>
                            </FlexDiv>
                            <ScheduleListContent>
                              <FlexDiv direction="column" gap={8}>
                                <AppText fontSize={12} fontWeight={600}>
                                  {moment(item.schedule_item.start_time).format("h:mm A")} -{" "}
                                  {moment(item.schedule_item.end_time).format("h:mm A")}
                                </AppText>
                                <FlexDiv gap={4}>
                                  <AppText fontSize={12}>{item.schedule_item?.computed_event_type}</AppText>
                                  <AppText fontSize={12} style={{ color: theme.NEUTRAL300 }}>
                                    |
                                  </AppText>
                                  <AppText fontSize={12} variant="primary">
                                    {formatUSD(_.round(item?.lead?.lead_value?.value, 2))}
                                  </AppText>
                                </FlexDiv>
                              </FlexDiv>

                              <FlexDiv direction="column" gap={8}>
                                <AppText fontSize={12} fontWeight={600}>
                                  {item?.lead?.business_name || "No Name"}
                                </AppText>
                                <FlexDiv gap={8}>
                                  <StatCard icon={user_white} />
                                  <AppText fontSize={12}>{item?.lead?.full_name}</AppText>
                                </FlexDiv>
                              </FlexDiv>
                            </ScheduleListContent>
                          </ScheduleListItem>
                        </ScheduleListDiv>
                      );
                    })
                  : scheduleListItems.map((item: any, index: number) => {
                      const prevStartDate =
                        !!scheduleListItems[index - 1] &&
                        moment(scheduleListItems[index - 1].start_time).format("MMMM Do");
                      const currentStartDate = moment(item.start_time).format("MMMM Do");
                      return (
                        <ScheduleListDiv key={index}>
                          {prevStartDate !== currentStartDate && (
                            <DayDivider>
                              <AppText>{currentStartDate}</AppText>
                            </DayDivider>
                          )}
                          <ScheduleListItem key={item.id}>
                            <FlexDiv justify="flex-end" style={{ marginRight: "16px" }}>
                              <AppText
                                fontSize={12}
                                variant="primary"
                                style={{ borderBottom: "none", cursor: "pointer" }}
                                onClick={() => window.open(`/manage-schedule/${item.id}`, "_blank", "noreferrer")}
                              >
                                View Details
                              </AppText>
                            </FlexDiv>
                            <ScheduleListContent>
                              <FlexDiv direction="column" gap={8}>
                                <AppText fontSize={12} fontWeight={600}>
                                  {moment(item.start_time).format("h:mm A")} - {moment(item.end_time).format("h:mm A")}
                                </AppText>
                                <FlexDiv gap={4}>
                                  <AppText fontSize={12}>{item.lead_intent?.event_type_label}</AppText>
                                  <AppText fontSize={12} style={{ color: theme.NEUTRAL300 }}>
                                    |
                                  </AppText>
                                  <AppText fontSize={12} variant="primary">
                                    {formatUSD(_.round(item.lead_intent?.lead?.lead_value?.value, 2))}
                                  </AppText>
                                </FlexDiv>
                              </FlexDiv>

                              <FlexDiv direction="column" gap={8}>
                                <AppText fontSize={12} fontWeight={600}>
                                  {item.lead_intent?.lead?.business_name || "No Name"}
                                </AppText>
                                <FlexDiv gap={8}>
                                  <StatCard icon={user_white} />
                                  <AppText fontSize={12}>{item.lead_intent?.lead?.full_name}</AppText>
                                </FlexDiv>
                              </FlexDiv>
                            </ScheduleListContent>
                          </ScheduleListItem>
                        </ScheduleListDiv>
                      );
                    }))}
            </InfiniteScroll>
          </ScheduleScrollDiv>
        )}
      </div>
    </AppSidebarCard>
  );

  if ((loading && !isNonCallActivity) || isConnectingToTwilio)
    return (
      <CallComponentContainer>
        <MainContainer>
          <SkeletonBlock height={"95%"} width={"95%"} borderRadius={6} />
        </MainContainer>
      </CallComponentContainer>
    );

  if (error && callLeadId)
    return (
      <CallComponentContainer>
        <MainContainer>
          <AppErrorText>Failed to fetch lead</AppErrorText>
          <NewAppButton
            size={"lg"}
            variant={"secondary"}
            onClick={() => {
              refetch({ id: callLeadId });
            }}
          >
            retry
          </NewAppButton>
        </MainContainer>
      </CallComponentContainer>
    );

  const isTwilioMuted = twilioMuted();

  return (
    <CallComponentContainer>
      <ReactTooltip
        multiline
        place="top"
        effect="solid"
        id="callbar"
        backgroundColor={theme.PRIMARY800}
        getContent={(dataTip) => (
          <span
            style={{ fontFamily: "Inter", fontStyle: "normal", fontWeight: 600, fontSize: "10px", lineHeight: "14px" }}
          >
            {dataTip}
          </span>
        )}
      />
      {callSettingsModal && (
        <Modal open={callSettingsModal} onClose={() => setCallSettingsModal(false)} closeButtonSize={16}>
          <SettingsModalContainer>
            <TitleDiv>
              <TitleText>Settings</TitleText>
            </TitleDiv>
            <PaddingAndCenterDiv>
              <AppSelect
                name="inputSelect"
                options={inputDevices()}
                appSelectIcon={<BsMicIcon />}
                value={device?.audio?.inputDevice?.deviceId || "default"}
                onChange={(e: any) => {
                  setSelectedAudio("input");
                  updateDefaultAudio({
                    variables: {
                      default_audio_input: e.target.value,
                      default_audio_output: defaultOutput,
                    },
                  });
                  changeInputDevice(e.target.value);
                }}
              />
              <AppSelect
                name="outputSelect"
                options={outputDevices()}
                value={getOutputDevice()}
                onChange={(e: any) => {
                  setSelectedAudio("output");
                  updateDefaultAudio({
                    variables: {
                      default_audio_input: defaultInput,
                      default_audio_output: e.target.value,
                    },
                  });
                  changeOutputDevice(e.target.value);
                }}
                appSelectIcon={<BsVolumeIcon />}
              />
            </PaddingAndCenterDiv>
          </SettingsModalContainer>
        </Modal>
      )}
      <MainContainer>
        {showCallFeedbackModal && (
          <CallFeedbackModal blinds={showCallFeedbackModal} setBlinds={setShowCallFeedbackModal} role="rep" />
        )}
        <TwilioFeedbackModal />

        <BodyContainer callState={callState}>
          {renderScheduleTab()}

          {callState && (
            <AppSidebarCard
              style={{ border: "0px", marginTop: "24px" }}
              title="My Current Call Notes"
              width={432}
              render={
                <NotesInfoDiv>
                  <CallNoteLastUpdatedText
                    fontWeight={500}
                    fontSize={9}
                    style={{ color: theme.text.neutral.inverse }}
                    key={callNotesLastUpdated}
                  >
                    {!!callNotesLastUpdated && `(Updated: ${moment(callNotesLastUpdated).format("MM/DD/YYYY h:mm A")})`}
                  </CallNoteLastUpdatedText>
                  <NotesButton variant={"secondary"} onClick={() => persistCallNotes()}>
                    Save Notes
                  </NotesButton>
                </NotesInfoDiv>
              }
            >
              <CallNotesDiv>
                <NotesTextArea
                  noBorder
                  fixedSize
                  height={280}
                  width={380}
                  placeholder="Type here..."
                  value={callNotes}
                  onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) =>
                    updateCallNotes(event.target.value, callLeadId || "")
                  }
                />
              </CallNotesDiv>
            </AppSidebarCard>
          )}

          {!callState && <FavoriteDialsComponent closeOtherModals={closeOtherModals} />}
        </BodyContainer>

        {callState && currentCallState().onCall && (
          <>
            <RecordConsentModal />
            <JoinDetailsModal />
          </>
        )}

        {callState && (
          <FooterContainer>
            <FooterInner>
              <FlexDiv direction="column" gap={4}>
                <FlexDiv gap={24} align="center">
                  <FlexDiv gap={8} align="center">
                    <PhoenixIcon
                      svg={calendar}
                      color={theme.text.brand.secondary}
                      hoverColor={theme.text.brand.secondary}
                      size={16}
                    />
                    <AppText fontSize={16} color={theme.text.brand.secondary} style={{ whiteSpace: "nowrap" }}>
                      {data?.fetchLead?.can_record_without_permission ? "On Call" : "Scheduled"}
                    </AppText>
                  </FlexDiv>

                  <FlexDiv gap={4} align="center">
                    <PhoenixIcon svg={clock} color={theme.PILL_DARK_GRAY} size={16} hoverColor={theme.PILL_DARK_GRAY} />
                    <AppText fontSize={16} color={theme.text.neutral.inverse}>
                      {formatTime(timeOnCall)}
                    </AppText>
                    {connectionWarnings && <TwilioWarnings />}
                  </FlexDiv>
                </FlexDiv>

                <FlexDiv gap={16} align="center">
                  <FlexDiv gap={8} align="center">
                    <AppText color={theme.text.neutral.inverse} fontSize={14}>
                      {phoneNumber ? formatPhoneNumber(phoneNumber) : ""}
                    </AppText>
                    {isExpandedCallView ? (
                      <TruncatedText fontSize={14} color={theme.PILL_DARK_GRAY}>
                        {data?.fetchLead?.business_name || data?.fetchLead?.full_name || ""}
                      </TruncatedText>
                    ) : null}
                  </FlexDiv>

                  {isExpandedCallView ? (
                    <FlexDiv gap={8} align="center">
                      <AppText color={theme.text.neutral.inverse} fontSize={14}>
                        {formatPhoneNumber(user?.phone_number)}
                      </AppText>
                      <ProfileImageWithText
                        first_name={user?.first_name}
                        last_name={user?.last_name}
                        profile_image={user?.profile_image}
                        width={24}
                        height={24}
                        textSize={8}
                        hideTooltip
                      />
                    </FlexDiv>
                  ) : null}
                </FlexDiv>
              </FlexDiv>

              {currentCallState().onCall ? (
                <FlexDiv gap={isExpandedCallView ? 16 : 8} align="center">
                  <FlexDiv gap={8} align="center">
                    <ActionButton
                      buttonType={"primary"}
                      variant={!showDialer ? "brand-outline" : "brand"}
                      {...(!showDialer && { backgroundColor: "transparent" })}
                      onClick={toggleDialer}
                    >
                      <PhoenixIcon size={24} fillIcon variant={!showDialer ? "brand" : "white"} svg={keypad} pointer />
                    </ActionButton>
                    <ActionButton
                      buttonType="primary"
                      variant={!isTwilioMuted ? "brand-outline" : "brand"}
                      {...(!isTwilioMuted && { backgroundColor: "transparent" })}
                      onClick={toggleMute}
                    >
                      <PhoenixIcon
                        size={24}
                        svg={isTwilioMuted ? mic_off : mic}
                        color={isTwilioMuted ? theme.text.neutral.inverse : theme.text.brand.primary}
                        pointer
                        {...(isTwilioMuted ? { hoverColor: theme.text.neutral.inverse } : {})}
                      />
                    </ActionButton>
                    <ActionButton
                      buttonType="primary"
                      variant="danger-outline"
                      backgroundColor="transparent"
                      onClick={() => hangup()}
                      disabled={!(!isOnCallLoading && isOnCall?.isOnCall)}
                    >
                      <PhoenixIcon
                        pointer
                        size={24}
                        svg={phone_end}
                        color={theme.DANGER500}
                        hoverColor={theme.DANGER500}
                      />
                    </ActionButton>
                  </FlexDiv>

                  {isExpandedCallView ? (
                    <>
                      <CallParticipantsMenu
                        participants={participants?.participants}
                        businessName={data?.fetchLead?.business_name}
                        refetchParticipants={refetchParticipants}
                      />

                      <img
                        src={recording ? iconStopRecording : iconStartRecording}
                        alt="Toggle recording"
                        onClick={handleRecordingClick}
                        style={{ cursor: "pointer" }}
                        data-tip={recording ? "Stop Recording" : "Start Recording"}
                        data-for="callbar"
                      />
                    </>
                  ) : null}

                  <CallMoreMenu
                    participants={participants?.participants}
                    businessName={data?.fetchLead?.business_name}
                    refetchParticipants={refetchParticipants}
                    isExpandedCallView={isExpandedCallView}
                    onAudioSettings={() => setCallSettingsModal(true)}
                    onInviteLink={() => setShowJoinDetailsModal(!showJoinDetailsModal)}
                    onRecordingClick={handleRecordingClick}
                    recording={recording}
                  />
                </FlexDiv>
              ) : (
                <>
                  <FlexDiv direction="column">
                    <AppText fontSize={isExpandedCallView ? 16 : 14} color={theme.text.neutral.inverse}>
                      Call has ended. Please submit
                    </AppText>
                    <AppText fontSize={isExpandedCallView ? 16 : 14} color={theme.text.neutral.inverse}>
                      a call result to continue.
                    </AppText>
                  </FlexDiv>
                </>
              )}
            </FooterInner>
          </FooterContainer>
        )}
      </MainContainer>
    </CallComponentContainer>
  );
};

export default CallV2;

const CallComponentContainer = styled.div`
  height: 100vh;
  background: ${theme.surface.neutral.tertiary};
`;

const MainContainer = styled.div`
  position: relative;
  padding-top: 18px;
  margin: 0 auto;
  height: 100%;
  background: ${theme.surface.neutral.tertiary};
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: auto;
  min-height: 100vh;
`;

interface CallStateProps {
  callState?: boolean;
}

const BodyContainer = styled.div<CallStateProps>`
  max-height: ${(props) => (props.callState ? "calc(100% - 102px)" : "calc(100%)")};
  overflow-x: hidden;
  -ms-overflow-style: none;
  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none;
  }
`;

const FooterContainer = styled.div`
  background-color: #08122e;
  border-radius: 12px;
  max-width: 432px;
  padding: 16px;
  width: 100%;

  @media (min-width: ${COMPACT_BREAKPOINT}px) {
    bottom: 24px;
    left: 50%;
    max-width: 830px;
    padding: 16px 24px;
    position: fixed;
    transform: translateX(-50%);
    z-index: 101;
  }
`;

const FooterInner = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  gap: 16px;
  width: 100%;

  @media (min-width: ${COMPACT_BREAKPOINT}px) {
    gap: 32px;
  }
`;

const ActionButton = styled(PhoenixAppButton)`
  align-items: center;
  border-radius: 8px;
  display: flex;
  height: 48px;
  justify-content: center;
  padding: 8px;
  width: 48px;
`;

const SettingsModalContainer = styled.div`
  height: 226px;
  width: 432px;
  background-color: ${theme.text.neutral.inverse};
`;

const TitleText = styled(AppText)`
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
`;

const TitleDiv = styled.div`
  position: relative;
  height: 56px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${theme.NEUTRAL100};
  border-bottom: 1px solid ${theme.NEUTRAL200};
`;

const PaddingAndCenterDiv = styled.div`
  padding: 24px;
  padding-bottom: 14px;
`;

const BsMicIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  &::before {
    content: "🎤";
  }
`;

const BsVolumeIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  &::before {
    content: "🔊";
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

const TabFiller = styled.div`
  width: 100%;
  border-bottom: 1px solid ${theme.NEUTRAL200};
`;

interface RedTextProps {
  red?: boolean;
}

const ScheduleListDiv = styled.div<RedTextProps>`
  background-color: ${(props) => (props.red ? theme.DANGER50 : "inherit")};
`;

const DayDivider = styled.div`
  padding-top: 5px;
  padding-left: 12px;
  height: 27px;
  background-color: ${theme.NEUTRAL100};
`;

const ScheduleListItem = styled.div`
  padding-top: 16px;
  border: 1px solid ${theme.NEUTRAL100};
`;

const ScheduleListContent = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: 34px;
  margin: 16px;
`;

const ScheduleScrollDiv = styled.div`
  overflow: auto;
  height: 275px;
`;

const NotesInfoDiv = styled.div`
  height: 100%;
  width: 100%;
  max-width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const CallNotesDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  cursor: text;
`;

const TruncatedText = styled(AppText)`
  display: block;
  max-width: 130px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const NotesTextArea = styled(AppTextArea)`
  padding-left: 0px;
  padding-top: 20px;
  overflow: auto;
`;

const NotesButton = styled(NewAppButton)`
  width: 80px;
  height: 12px;
  font-size: 8px;
`;

const CallNoteLastUpdatedText = styled(AppText)`
  animation: flash 0.5s ease;
  @keyframes flash {
    from {
      color: ${theme.text.brand.primary};
    }
    to {
      color: ${theme.text.neutral.inverse};
    }
  }
`;
