import React, { useContext, useEffect } from "react";
import styled from "styled-components";
import { AppText, FlexDiv } from "../UI";
import { theme } from "../../utils/theme";
import { PhoenixIcon } from "../UI/Phoenix";
import { info } from "src/images/NewDesign";
import { ModalContext } from "src/context";
import { MixpanelActions } from "src/services/mixpanel";
import { loggedInUser } from "src/apollo/cache";
import { xIcon } from "../../images/NewDesign";

const NewDeployVersionBanner = () => {
  const { showNewDeployVersionBanner, setShowNewDeployVersionBanner } = useContext(ModalContext);

  const handleRefreshPage = () => {
    MixpanelActions.track("New Deploy Version Banner Clicked", {
      user_id: loggedInUser().id,
      organization_id: loggedInUser().organization_id,
      date: new Date().toISOString(),
    });

    window.location.reload();
  };

  const handleCloseBanner = () => {
    MixpanelActions.track("New Deploy Version Banner Closed Without Refresh", {
      user_id: loggedInUser().id,
      organization_id: loggedInUser().organization_id,
      date: new Date().toISOString(),
    });

    setShowNewDeployVersionBanner(false);
  };

  if (!showNewDeployVersionBanner) return null;

  return (
    <FlexDiv
      align="center"
      padding="10px"
      width="400px"
      gap={8}
      style={{
        // absolute positioning to viewport
        zIndex: 1000,
        position: "fixed",
        bottom: 0,
        right: 0,
        background: theme.fill.brand.secondary,
        borderRadius: "10px",
        margin: "10px",
      }}
    >
      <PhoenixIcon svg={info} size={22} color={theme.text.brand.primary} />
      <AppText color={theme.text.brand.primary} fontSize={14} fontWeight={500}>
        There's a new version of Sellfire available! 🎉 Please{" "}
        <ClickableSpan onClick={handleRefreshPage}>click here</ClickableSpan> to update.
      </AppText>
      <PhoenixIcon
        svg={xIcon}
        size={22}
        color={theme.text.brand.primary}
        onClick={handleCloseBanner}
        pointer
        style={{
          position: "absolute",
          right: 10,
          top: 10,
        }}
      />
    </FlexDiv>
  );
};

const ClickableSpan = styled.span`
  cursor: pointer;
  font-weight: 600;
  font-size: 14px;
  color: ${theme.text.brand.primary};
  text-decoration: underline;
`;

export default NewDeployVersionBanner;
