import { LeadFilterObject } from "src/context";
import { OptionItem } from "src/types";

export const formatLeadFiltersPayload = (filters: LeadFilterObject) => {
  return {
    reps: filters?.reps,
    status: filters?.status,
    industries: filters?.industries,
    custom_fields: filters?.custom_fields,
    sub_industry: filters?.sub_industry,
    lead_sources: filters?.lead_sources,
    lead_creation_source: filters?.lead_creation_source,
    states: filters?.states,
    timezones: filters?.timezones,
    channels: filters?.channels,
    history_filter: {
      amount: filters?.history_filter?.amount === 0 ? 0 : filters?.history_filter?.amount || undefined,
      amount_2: filters?.history_filter?.amount_2 === 0 ? 0 : filters?.history_filter?.amount_2 || undefined,
      operator: filters?.history_filter?.operator || undefined,
      metric: filters?.history_filter?.metric || undefined,
      lowerbound_date: filters?.history_filter?.lowerbound_date || undefined,
      upperbound_date: filters?.history_filter?.upperbound_date || undefined,
    },
    rep_activity_filter: {
      user_ids: filters?.rep_activity_filter?.user_ids?.map((item: any) => item?.value ?? item) || [],
      rep_lowerbound_date: filters?.rep_activity_filter?.rep_lowerbound_date || undefined,
      rep_upperbound_date: filters?.rep_activity_filter?.rep_upperbound_date || undefined,
    },
    call_result_filter: {
      call_result_type: filters?.call_result_filter?.call_result_type || undefined,
      call_result_option: filters?.call_result_filter?.call_result_option || [],
    },
    NextScheduledEventDays: Number(filters?.next_scheduled_event?.NextScheduledEventDays) || undefined,
    NextScheduledEventTypes: filters?.next_scheduled_event?.NextScheduledEventTypes || undefined,
    team: filters?.team || undefined,
    sequences: filters?.sequences ?? [],
    countries: filters?.countries ?? [],
    phone_country_codes: filters?.phone_country_codes ?? [],
    notes: filters?.notes || undefined,
    sourced_by_user: filters?.sourced_by_user || undefined,
    lead_creation_date_lowerbound: filters?.created_date?.lowerbound_date || undefined,
    lead_creation_date_upperbound: filters?.created_date?.upperbound_date || undefined,
    claimed_date_lowerbound: filters?.claimed_date?.lowerbound_date || undefined,
    claimed_date_upperbound: filters?.claimed_date?.upperbound_date || undefined,
    missed_event_lowerbound_date: filters?.missed_event?.lowerbound_date || undefined,
    missed_event_upperbound_date: filters?.missed_event?.upperbound_date || undefined,
    next_scheduled_event_lowerbound_date: filters?.next_scheduled_event?.lowerbound_date || undefined,
    next_scheduled_event_upperbound_date: filters?.next_scheduled_event?.upperbound_date || undefined,
    zip_codes: filters?.zip_codes || [],
    phases: filters?.phases ?? [],
    pipeline_stages: filters?.pipeline_stages ?? [],
  };
};

export const formatSavedViewFiltersToLeadFilterObject = (filters: any): LeadFilterObject => {
  return {
    reps: filters?.reps,
    status: filters?.status,
    industries: filters?.industries,
    custom_fields: filters?.custom_fields,
    sub_industry: filters?.sub_industry,
    lead_sources: filters?.lead_sources,
    lead_creation_source: filters?.lead_creation_source,
    states: filters?.states,
    timezones: filters?.timezones,
    channels: filters?.channels,
    zip_codes: filters?.zip_codes ?? [],
    sequences: filters?.sequences ?? [],
    sequence_tasks_types: filters?.sequence_tasks_types ?? [],
    countries: filters?.countries ?? [],
    phone_country_codes: filters?.phone_country_codes ?? [],
    history_filter: {
      amount: filters?.history_filter?.amount === 0 ? 0 : filters?.history_filter?.amount || undefined,
      amount_2: filters?.history_filter?.amount_2 === 0 ? 0 : filters?.history_filter?.amount_2 || undefined,
      operator: filters?.history_filter?.operator || undefined,
      metric: filters?.history_filter?.metric || undefined,
      lowerbound_date: filters?.history_filter?.lowerbound_date || undefined,
      upperbound_date: filters?.history_filter?.upperbound_date || undefined,
    },
    rep_activity_filter: {
      user_ids: filters?.rep_activity_filter?.user_ids?.map((item: any) => item?.value ?? item) || [],
      rep_lowerbound_date: filters?.rep_activity_filter?.rep_lowerbound_date || undefined,
      rep_upperbound_date: filters?.rep_activity_filter?.rep_upperbound_date || undefined,
    },
    call_result_filter: {
      call_result_type: filters?.call_result_filter?.call_result_type || undefined,
      call_result_option: filters?.call_result_filter?.call_result_option || [],
    },
    next_scheduled_event: {
      NextScheduledEventDays: filters?.NextScheduledEventDays || undefined,
      NextScheduledEventTypes: filters?.NextScheduledEventTypes || undefined,
      lowerbound_date: filters?.next_scheduled_event_lowerbound_date || undefined,
      upperbound_date: filters?.next_scheduled_event_upperbound_date || undefined,
    },
    team: filters?.team || undefined,
    notes: filters?.notes || undefined,
    sourced_by_user: filters?.sourced_by_user || undefined,
    created_date: {
      lowerbound_date: filters?.lead_creation_date_lowerbound || undefined,
      upperbound_date: filters?.lead_creation_date_upperbound || undefined,
    },
    claimed_date: {
      lowerbound_date: filters?.claimed_date_lowerbound || undefined,
      upperbound_date: filters?.claimed_date_upperbound || undefined,
    },
    missed_event: {
      lowerbound_date: filters?.missed_event_lowerbound_date || undefined,
      upperbound_date: filters?.missed_event_upperbound_date || undefined,
    },
    phases: filters?.phases ?? [],
    pipeline_stages: filters?.pipeline_stages ?? [],
  };
};

export const COMPARISON_MAP: Record<string, string> = {
  Equal: "=",
  NotEqual: "!=",
};

export const CHANNEL_OPTIONS: OptionItem[] = [
  {
    label: `Inbound`,
    value: "Inbound",
  },
  {
    label: `Outbound`,
    value: "Outbound",
  },
];

export const COMPARISON_OPTIONS: OptionItem[] = [
  {
    label: `= (equals)`,
    value: "Equal",
  },
  {
    label: `!= (not equals)`,
    value: "NotEqual",
  },
];

export const TYPES_OPTIONS: OptionItem[] = [
  {
    label: `ColdCall`,
    value: "ColdCall",
  },
  {
    label: `CallBack`,
    value: "CallBack",
  },
];

export const FILTER_OPERATIONS: OptionItem[] = [
  {
    label: `Includes`,
    value: "DoesInclude",
  },
  {
    label: `Excludes`,
    value: "DoesNotInclude",
  },
  {
    label: `Empty field`,
    value: "EmptyField",
  },
];

export const callResultsTypeOptions = [
  { label: "Cold Calls", value: "cold_call" },
  { label: "Call Followups", value: "call_followup" },
  { label: "General Callback Held", value: "general_callback_held" },
  { label: "General Callback Set", value: "general_callback_set_not_held" },
  { label: "Schedule Callback Held", value: "schedule_callback_held" },
  { label: "Schedule Callback Set", value: "schedule_callback_set_not_held" },
  { label: "Demo Held", value: "demo_held" },
  { label: "Demo Set", value: "demo_set_not_held" },
  { label: "Decision Call Held", value: "decision_call_held" },
  { label: "Decision Call Set", value: "decision_call_set_not_held" },
  { label: "Emails Sent", value: "email_sent" },
  { label: "Emails Received", value: "email_received" },
  { label: "Dials", value: "dial" },
];

export const leadHistoryOperators = [
  { label: "Greater than", value: "GreaterThan" },
  { label: "Less than", value: "LessThan" },
  { label: "Greater than or equal to", value: "GreaterThanOrEqualTo" },
  { label: "Less than or equal to", value: "LessThanOrEqualTo" },
  { label: "Equal to", value: "EqualTo" },
  { label: "Not equal to", value: "NotEqualTo" },
  { label: "Between", value: "Between" },
  { label: "Not Between", value: "NotBetween" },
];

export const STATUS_OPTIONS: OptionItem[] = [
  {
    label: "Assigned",
    value: "Assigned",
  },
  {
    label: "Customer",
    value: "Customer",
  },
  {
    label: "Owned",
    value: "Owned",
  },
  {
    label: "Resting",
    value: "Resting",
  },
  {
    label: "Retired",
    value: "Retired",
  },
  {
    label: "Unassigned",
    value: "Unassigned",
  },
];

export const NEXT_SCHEDULED_EVENT_OPTIONS: OptionItem[] = [
  { label: "Decision Call", value: "DecisionCall" },
  { label: "Reschedule Demo", value: "RescheduleDemo" },
  { label: "Scheduled Event", value: "ScheduledEvent" },
  { label: "Scheduled Callback", value: "ScheduleCallback" },
  { label: "Demo", value: "Demo" },
  { label: "Follow-Up Demo", value: "FollowUpDemo" },
];

export const ASSIGN_OPTIONS: OptionItem[] = [
  { label: "Only Assign", value: "Only Assign" },
  { label: "Do Not Assign", value: "Do Not Assign" },
];

export const LEAD_CREATION_SOURCE_OPTIONS: OptionItem[] = [
  { label: "Added by Admin", value: "as_admin" },
  { label: "Bulk Import", value: "CSV" },
  { label: "Hubspot", value: "hubspot" },
  { label: "Salesforce", value: "salesforce" },
  { label: "Self-Sourced", value: "self_sourced" },
];

export const PHASES_OPTIONS: OptionItem[] = [
  { label: "Cold Call (Cold)", value: "ColdCallCold" },
  { label: "Cold Call (Working Num)", value: "ColdCallWorkingNum" },
  { label: "Cold Call (NDM)", value: "ColdCallNDM" },
  { label: "Cold Call (DM)", value: "ColdCallDM" },
  { label: "Confirmation Phase", value: "ConfirmationPhase" },
  { label: "Held Phase", value: "HeldPhase" },
  { label: "Post Hold Phase", value: "PostHoldPhase" },
  { label: "Post Initial Held Phase (Demo Not Held)", value: "PostInitialHelPhaseDemoNotHeld" },
  { label: "Resting", value: "Resting" },
  { label: "Long Resting", value: "LongResting" },
  { label: "Customer", value: "Customer" },
  { label: "Retired", value: "Retired" },
  { label: "Resting Cold Call", value: "RestingColdCall" },
  { label: "Retired Cold Call", value: "RetiredColdCall" },
  { label: "Long Resting Cold Call", value: "LongRestingColdCall" },
];

export const PIPELINE_OPTIONS: OptionItem[] = [
  { label: "Sale", value: "Sale" },
  { label: "No Contact Made", value: "NoContactMade" },
  { label: "NDM Contact", value: "NDMContact" },
  { label: "NDM Contact Interested", value: "NDMContactInterested" },
  { label: "DM Contact", value: "DMContact" },
  { label: "DM Contact Interested", value: "DMContactInterested" },
  { label: "Demo Set", value: "DemoSet" },
  { label: "Demo Set Flaked", value: "DemoSetFlaked" },
  { label: "Demo Held", value: "DemoHeld" },
  { label: "Follow Up Demo Held", value: "FollowUpDemoHeld" },
  { label: "Decision Call Held", value: "DecisionCallHeld" },
  { label: "Do Not Contact", value: "DoNotContact" },
  { label: "Undialed", value: "Undialed" },
  { label: "Customer Non Sale", value: "CustomerNonSale" },
];

export type MoreFilterOption = OptionItem & {
  hideOperator?: boolean;
  isCreatable?: boolean;
  item?: Record<string, any>;
  type: string;
  value: string;
};

export const MORE_FILTERS_OPTIONS: MoreFilterOption[] = [
  {
    label: "Channel",
    value: "channels",
    type: "multi_select",
  },
  {
    label: "Sub Industry",
    value: "sub_industry",
    type: "multi_select",
  },
  {
    label: "Lead Sources",
    value: "lead_sources",
    type: "multi_select",
  },
  {
    label: "Lead Creation Source",
    value: "lead_creation_source",
    type: "multi_select",
  },
  {
    label: "State",
    value: "states",
    type: "multi_select",
  },
  {
    label: "Timezone",
    value: "timezones",
    type: "multi_select",
  },
  {
    label: "Rep Activity",
    value: "rep_activity_filter",
    type: "rep_activity_filter",
  },
  {
    label: "Next Scheduled Event",
    value: "next_scheduled_event",
    type: "next_scheduled_event",
  },
  {
    label: "Claimed Date",
    value: "claimed_date",
    type: "date",
  },
  {
    label: "Missed Event",
    value: "missed_event",
    type: "date",
  },
  {
    label: "Sourced By User",
    value: "sourced_by_user",
    type: "multi_select",
    hideOperator: true,
  },
  {
    label: "Teams",
    value: "team",
    type: "multi_select",
    hideOperator: true,
  },
  {
    label: "Sequences",
    value: "sequences",
    type: "multi_select",
  },
  {
    label: "Countries",
    value: "countries",
    type: "multi_select",
  },
  {
    label: "Country Code Primary Phone",
    value: "phone_country_codes",
    type: "multi_select",
  },
  {
    label: "Zip Code",
    value: "zip_codes",
    type: "zip_codes",
  },
  {
    label: "Notes",
    value: "notes",
    type: "notes",
  },
  {
    label: "Phases",
    value: "phases",
    type: "multi_select",
    hideOperator: true,
  },
  {
    label: "Pipeline Stages",
    value: "pipeline_stages",
    type: "multi_select",
    hideOperator: true,
  },
].sort((a, b) => a.label.localeCompare(b.label));
