import { gql, useMutation, useQuery } from "@apollo/client";
import { Formik, FormikProps } from "formik";
import * as React from "react";
import styled from "styled-components";
import * as Yup from "yup";
import { theme } from "../../utils/theme";
import { AppText, NewAppSelect } from "../UI";

import { useContext } from "react";
import { ModalContext } from "../../context";
import { convertDate } from "../../utils/format";
import { appToast, errorToast } from "../../utils/toast";
import { NewSelectField } from "../Field";
import CustomFieldSaleArray from "../Segments/CallSegments/MakeSaleComponents/CustomFieldSaleArray";
import HelpfulLinks from "../Segments/CallSegments/MakeSaleComponents/HelpfulLinks";
import { AppErrorText } from "../UI";
import { Loading } from "../UI/Loading";
import { PhoenixAppButton, PhoenixTextArea } from "../UI/Phoenix";
interface CustomField {
  id: string;
  value: string | number | boolean;
  num_value?: number;
  list_value?: string[];
  boo_value?: boolean;
  date_value?: string;
  type: string;
  key: string;
  options: string[];
  required_required_for_sale?: boolean;
}
interface MyFormikProps {
  sale_id: string;
  lead_id: string;
  customFields: CustomField[];
  contract_duration: string;
  payment_terms: string;
  prepayment: number;
  notes: string;
}

const FETCH_INDIVIDUAL_SALE = gql`
  query FetchSaleDetail($sale_id: String!) {
    fetchSaleDetail(sale_id: $sale_id) {
      id
      contract_duration
      payment_terms
      prepayment
      notes
      sale_items {
        id
        price_override
        quantity
        product {
          id
          product_title
          pricing
        }
        concession {
          id
          amount
        }
      }
    }
  }
`;

const FETCH_LEAD = gql`
  query fetchLead($id: String) {
    fetchLead(id: $id) {
      id
      first_name
      last_name
      business_name
      primary_phone_number
      primary_email
      address
      city
      state
      country
      local_primary_phone_number
      primary_phone_number_country_code
      timezone_by_state
      zip
      sale_flow_custom_fields {
        id
        computed_value
        key
        value
        list_value
        visible
        boo_value
        num_value
        date_value
        type
        lead_id
        options
        required_required_for_sale
      }
    }
  }
`;

export const UPDATE_LEAD = gql`
  mutation updateLead(
    $id: String!
    $first_name: String
    $last_name: String
    $business_name: String
    $primary_phone_number: String
    $primary_email: String
    $industry: String
    $sub_industry: String
    $lead_source: String
    $channel: CHANNEL
    $status: String
    $title: String
    $content: String
    $city: String
    $state: String
    $country: String
    $address: String
    $zip: String
    $country_code: String
    $custom_fields: [CustomFieldInput!]
  ) {
    updateLead(
      id: $id
      first_name: $first_name
      last_name: $last_name
      business_name: $business_name
      primary_phone_number: $primary_phone_number
      primary_email: $primary_email
      industry: $industry
      sub_industry: $sub_industry
      lead_source: $lead_source
      channel: $channel
      status: $status
      title: $title
      content: $content
      city: $city
      state: $state
      country: $country
      address: $address
      zip: $zip
      country_code: $country_code
      custom_fields: $custom_fields
    ) {
      id
      first_name
      last_name
      full_name
      business_name
      primary_phone_number
      local_primary_phone_number
      primary_phone_number_country_code
      primary_email
      industry
      sub_industry
      lead_source
      channel
      status
      title
      content
      city
      state
      country
      timezone_by_state
      address
      zip
      sale_flow_custom_fields {
        id
        computed_value
        key
        value
        list_value
        visible
        boo_value
        num_value
        date_value
        type
        lead_id
        options
        required_required_for_sale
      }
    }
  }
`;

const FETCH_ORG_OPTIONS = gql`
  query fetchOrganization {
    fetchOrganization {
      id
      show_payment_terms_sales
      show_contract_duration_sales
      prepayment
      default_prepayment_option
      default_payment_terms_option
      default_contract_duration
      prepayment_options
      payment_terms_options
      contract_duration_options
      require_sale_notes
      show_sale_flow_custom_fields
      mrr_required_on_sale
      mrr
    }
  }
`;

const EDIT_TERMS = gql`
  mutation EditTerms(
    $sale_id: String!
    $contract_duration: String
    $payment_terms: String
    $prepayment: Int
    $lead_id: String!
    $notes: String
    $custom_fields: [CustomFieldInput]
  ) {
    editTerms(
      sale_id: $sale_id
      contract_duration: $contract_duration
      payment_terms: $payment_terms
      prepayment: $prepayment
      lead_id: $lead_id
      notes: $notes
      custom_fields: $custom_fields
    ) {
      message
      status
      status_boolean
    }
  }
`;

const numberYupValidation = () => ({
  is: "Rate" || "Percentage" || "Number",
  then: Yup.number().typeError("Must be a number").nullable(),
});

const textYupValidation = () => ({
  is: "Text",
  then: Yup.string(),
});

const booleanYupValidation = () => ({
  is: "Boolean",
  then: Yup.boolean().notRequired(),
});

const UpdateTermsModal = () => {
  const { selectedSale, setShowUpdateTermsModal } = useContext(ModalContext);

  const UpdateTermsSchema = Yup.object().shape({
    sale_id: Yup.string().required("Required"),
    lead_id: Yup.string().required("Required"),
    customFields: Yup.array().of(
      Yup.object().shape({
        id: Yup.string(),
        type: Yup.string().notRequired(),
        value: Yup.string().when("type", textYupValidation()).nullable(),
        boo_value: Yup.boolean().when("type", booleanYupValidation()).nullable(),
        num_value: Yup.number().typeError("Must be a number").when("type", numberYupValidation()).nullable(),
        // date_value: Yup.string().typeError("Must be a string").when("type", textYupValidation()).nullable(),
      }),
    ),

    sale_notes: Yup.string().notRequired(),
  });
  const { data: orgData, loading: orgLoading, error: orgError } = useQuery(FETCH_ORG_OPTIONS, {
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      console.log("data", data);
    },
    onError({ message, name }) {
      console.log(`Error in ${name}: `, message);
    },
  });

  const { data: saleData, loading: saleLoading, error: saleError } = useQuery(FETCH_INDIVIDUAL_SALE, {
    variables: { sale_id: selectedSale?.id },
    skip: !selectedSale?.id,
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      console.log("data", data);
    },
    onError: (error) => {
      console.log("error", error);
    },
  });

  const { data, loading, error, refetch } = useQuery(FETCH_LEAD, {
    fetchPolicy: "network-only",
    variables: { id: selectedSale?.lead_id },
    skip: !selectedSale?.lead_id,
    onError({ message, name }) {
      console.log(`Error in ${name}: `, message);
    },
  });

  const [updateTerms, { loading: updateTermsLoading, error: updateTermsError }] = useMutation(EDIT_TERMS, {
    async onCompleted({ editTerms }) {
      if (!editTerms) {
        appToast("Error updating terms. Something went wrong.");
        return;
      }
      appToast("Terms updated successfully.");

      setShowUpdateTermsModal(false);
    },
    onError({ message }) {
      appToast(message);
    },

    refetchQueries: ["fetchAllSales", "fetchSaleDetail", "FetchSaleDetail"],
  });

  if (loading || saleLoading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          width: "100%",
        }}
      >
        <Loading />
      </div>
    );
  }

  if (error || saleError) return <AppErrorText>Error fetching Sale Details</AppErrorText>;

  const {
    id,

    sale_flow_custom_fields,
  } = data?.fetchLead || {};

  return (
    <Formik
      validateOnMount={true}
      initialValues={{
        notes: saleData?.fetchSaleDetail?.notes || "",
        sale_id: selectedSale?.id || "",
        contract_duration: orgData?.fetchOrganization?.show_contract_duration_sales
          ? saleData?.fetchSaleDetail?.contract_duration
            ? saleData?.fetchSaleDetail?.contract_duration
            : orgData?.fetchOrganization?.default_contract_duration
            ? orgData?.fetchOrganization?.default_contract_duration
            : undefined
          : undefined,
        prepayment: orgData?.fetchOrganization?.prepayment
          ? saleData?.fetchSaleDetail?.prepayment
            ? saleData?.fetchSaleDetail?.prepayment
            : orgData?.fetchOrganization?.default_prepayment_option
            ? orgData?.fetchOrganization?.default_prepayment_option
            : undefined
          : undefined,
        payment_terms: orgData?.fetchOrganization?.show_payment_terms_sales
          ? saleData?.fetchSaleDetail?.payment_terms
            ? saleData?.fetchSaleDetail?.payment_terms
            : orgData?.fetchOrganization?.default_payment_terms_option
            ? orgData?.fetchOrganization?.default_payment_terms_option
            : undefined
          : undefined,
        lead_id: selectedSale?.lead_id || id || "",
        // neeed to update type
        customFields: (sale_flow_custom_fields || [])?.map((customField: CustomField) => {
          let valueToUse = {
            id: customField.id,
            value: "",
            list_value: [],
            type: customField.type,
            key: customField.key,
            options: [],
            required_required_for_sale: customField.required_required_for_sale,
          } as any;

          switch (customField.type) {
            case "Dropdown":
              valueToUse.value = customField.value;
              valueToUse.options = customField.options;
              break;
            case "MultiDropdown":
              valueToUse.list_value = (customField.list_value || [])?.map((k: any) => ({ label: k, value: k }));
              valueToUse.options = customField.options;
              break;
            case "DateTime":
              valueToUse.date_value = customField.date_value ? convertDate(customField.date_value) : undefined;
              break;
            case "Date":
              valueToUse.value = customField.value;
              break;
            case "Number":
              valueToUse.num_value = customField.num_value;
              break;
            case "Text":
              valueToUse.value = customField.value;
              break;
            case "Boolean":
              valueToUse.boo_value = customField.boo_value;
              break;
            case "Rate":
              valueToUse.num_value = customField.num_value;
              break;
            case "Percentage":
              valueToUse.num_value = customField.num_value;
              break;
            default:
              valueToUse.value = "";
          }
          return valueToUse;
        }),
      }}
      validationSchema={UpdateTermsSchema}
      onSubmit={async (values) => {
        console.log("onSubmit values: ", values);
        // check validation
        for (const k of values.customFields.filter((cf: any) => cf.required_required_for_sale)) {
          switch (k.type) {
            case "Text":
            case "Dropdown":
              if (!k.value) {
                return errorToast(`${k.key} is a required field!`);
              }
              break;
            case "MultiDropdown":
              if (!k.list_value?.length) {
                return errorToast(`${k.key} is a required field!`);
              }
              break;
            case "Date":
              if (!k.value) {
                return errorToast(`${k.key} is a required field!`);
              }
              break;
            case "DateTime":
              if (!k.date_value) {
                return errorToast(`${k.key} is a required field!`);
              }
              break;
            case "Number":
            case "Rate":
            case "Percentage":
              if (!k.num_value) {
                return errorToast(`${k.key} is a required field!`);
              }
              break;
            // case "Boolean":
          }
        }
        // update type
        const final_custom_fields = values.customFields?.map((customField: any) => {
          switch (customField.type) {
            case "Dropdown":
              return {
                id: customField.id,
                value: customField.value,
              };
            case "MultiDropdown":
              return {
                id: customField.id,
                // @ts-ignore
                list_value: customField.list_value?.map((k) => k.value ?? k),
              };
            case "Date":
              return {
                id: customField.id,
                date_value: customField.date_value,
              };
            case "Number":
              return {
                id: customField.id,
                num_value: parseFloat(customField.num_value as any),
              };
            case "Text":
              return {
                id: customField.id,
                value: customField.value,
              };
            case "Boolean":
              return {
                id: customField.id,
                boo_value: customField.boo_value,
              };
            case "Rate":
              return {
                id: customField.id,
                num_value: parseFloat(customField.num_value as any),
              };
            case "Percentage":
              return {
                id: customField.id,
                num_value: parseFloat(customField.num_value as any),
              };
            default:
              return {
                id: customField.id,
                value: customField.value,
              };
          }
        });
        console.log("final_custom_fields: ", final_custom_fields);

        await updateTerms({
          variables: {
            sale_id: selectedSale?.id || "",
            contract_duration: values.contract_duration,
            payment_terms: values.payment_terms,
            prepayment: values.prepayment
              ? typeof values.prepayment === "number"
                ? values.prepayment
                : parseInt(values.prepayment)
              : undefined,
            notes: values.notes,
            custom_fields: final_custom_fields ?? undefined,
            lead_id: values.lead_id,
          },
        });
      }}
    >
      {({ submitForm, values, setFieldValue, isValid, dirty, errors }: FormikProps<MyFormikProps>) => {
        return (
          <>
            <TitleDiv>
              <PopupTitle>Update Terms</PopupTitle>
            </TitleDiv>

            <ScrollingDiv>
              {orgData?.fetchOrganization?.show_contract_duration_sales && (
                <Row>
                  <NewSelectField
                    title="Contract Duration"
                    name="contract_duration"
                    titleText="Contract Duration"
                    options={orgData?.fetchOrganization?.contract_duration_options || []}
                  />
                </Row>
              )}
              {orgData?.fetchOrganization?.show_payment_terms_sales && (
                <Row>
                  <NewSelectField
                    title="Payment Terms"
                    name="payment_terms"
                    titleText="Payment Terms"
                    options={orgData?.fetchOrganization?.payment_terms_options || []}
                  />
                </Row>
              )}
              {orgData?.fetchOrganization?.prepayment && (
                <Row>
                  <NewAppSelect
                    title="Prepayment"
                    titleText="Prepayment"
                    options={orgData?.fetchOrganization?.prepayment_options || []}
                    onChange={(value: any) => {
                      setFieldValue("prepayment", parseInt(value.value));
                    }}
                    // unfortunately, the option value from the BE is a string
                    value={`${values.prepayment}`}
                  />
                </Row>
              )}

              <FormContainer>
                <HeaderContainer>
                  <HelpfulLinks />
                </HeaderContainer>
                <FormContainerDiv>
                  <CustomFieldSaleArray values={values} setFieldValue={setFieldValue} />
                </FormContainerDiv>
              </FormContainer>
              <MakeSaleText>Sale Notes</MakeSaleText>
              {/* <FormAreaInputField fontSize={13} name="sale_notes" /> */}
              <PhoenixTextArea
                placeholder="Add notes here..."
                maxWidth={500}
                style={{ marginBottom: 20 }}
                value={values.notes}
                onChange={(e) => {
                  setFieldValue("notes", e.target.value);
                }}
              />
            </ScrollingDiv>
            <SubmitDiv>
              <PhoenixAppButton
                buttonType="secondary"
                variant="danger-outline"
                onClick={() => {
                  setShowUpdateTermsModal(false);
                }}
              >
                Cancel
              </PhoenixAppButton>
              <PhoenixAppButton
                disabled={updateTermsLoading || !isValid}
                buttonType="secondary"
                variant="brand"
                onClick={() => {
                  submitForm();
                }}
              >
                Save Changes
              </PhoenixAppButton>
            </SubmitDiv>
          </>
        );
      }}
    </Formik>
  );
};

const Row = styled.div``;
interface CustomHeightProps {
  customHeight?: boolean;
}

const SubmitDiv = styled.div<CustomHeightProps>`
  position: absolute;
  bottom: 0px;
  height: 80px;
  width: 100%;
  gap: 12px;
  padding: 0px 24px;
  margin-top: auto;
  margin-bottom: ${(props) => (props.customHeight ? "0px" : "5px")};
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${theme.NEUTRAL100};
  border-top: solid 1px ${theme.NEUTRAL200};
`;

const TitleDiv = styled.div`
  height: 56px;
  border-bottom: solid 1px ${theme.NEUTRAL200};
  background-color: ${theme.NEUTRAL100};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const PopupTitle = styled(AppText)`
  font-size: 14px;
  font-weight: 600;
`;

const ScrollingDiv = styled.div<CustomHeightProps>`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: auto;
  width: 100%;
  padding-top: 24px;
  padding-bottom: 90px;
  padding-left: 40px;
  padding-right: 40px;
  min-height: ${(props) => (props.customHeight ? "calc(100vh - 250px)" : "calc(100vh - 80px - 56px - 26px)")};
  max-height: ${(props) => (props.customHeight ? "calc(100vh - 250px)" : "calc(100vh - 80px - 56px - 26px)")};
`;

const FormContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const FormContainerDiv = styled.div`
  width: 100%;
  justify-content: center;
  flex-grow: 2;
`;

const HeaderContainer = styled.div`
  width: 100%;
  max-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow-y: auto;
  margin-bottom: 15px;
  margin-top: 25px;
`;

const MakeSaleText = styled(AppText)`
  font-size: 16px;
  font-weight: bold;
  width: 100%;
  margin-bottom: 20px;
  text-align: left;
`;

export { UpdateTermsModal };
