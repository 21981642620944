import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import DateTimePicker from "react-datetime-picker";
import * as Yup from "yup";
import { AppText, FlexDiv, Loading } from "src/Components/UI";
import { appToast } from "src/utils/toast";
import { chevron_left, refresh, trash } from "src/images/NewDesign";
import {
  DATE_RANGES_WITHOUT_SPACE,
  METRIC_TYPES_WITHOUT_SPACE,
  ConditionTypes,
  handleRateAndPercentage,
  formatTypeName,
} from "src/utils/format";
import { FieldArray, Formik, FormikErrors, FormikProps } from "formik";

import { PhoenixAppButton, PhoenixIcon } from "src/Components/UI/Phoenix";
import { PhoenixInput, PhoenixMultiSelect, PhoenixRadio } from "src/Components/UI/Phoenix";
import {
  testForArrayFormatedAsAString,
  testForArrayWithMultipleElements,
  testIfFieldsAreUnique,
  returnIndividualDataFromArrayBasedOnID,
  arraysAreEqual,
} from "src/utils/misc";
import { theme } from "src/utils/theme";
import { useHistory, useLocation } from "react-router-dom";
import CreatableSelect from "react-select/creatable";
import { gql, useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { LabelT, OptionItem } from "src/types";
import RankSelectedRepsTable from "./routing-rules/RankSelectedRepsTable";
import { LabelSelect } from "src/Components/Smart";
import "src/Components/Segments/Onboarding/transfer-tab/TransferRulesTable.css";

interface ICondition {
  field: string;
  operator: string;
  value: string;
  type: string;
  cadence?: string;
}
interface IRep {
  id: string;
  rep_id: string;
  full_name: string;
  close_rate: string;
  priority: number;
  userLabels?: {
    label: {
      color: string;
      id: string;
      name: string;
    };
  }[];
}

interface ConditionField {
  key: string;
  label: string;
  options: string[];
  type: ConditionTypes;
}

interface FetchedIndustry {
  id: string;
  label: string;
  sub_industries: string[];
}

interface MyFormikProps {
  name: string;
  active: boolean;
  distribution_method: string;
  entity_type: string;
  conditions: any;
  reps: IRep[];
  transfer_type: string;
  backup_rule: string;
  rank_sort_type: string;
  metric: string;
  date_range: string;
  labels: LabelT[];
  rep_ids: string[];
}

interface FetchIndustryOptionsExpectedResponse {
  fetchIndustryOptions?: {
    id: string;
    label: string;
    sub_industries: string[];
  }[];
}

interface CreateOrUpdateCustomTransferRuleExpectedResponse {
  createOrUpdateCustomTransferRule?: {
    id?: string;
    priority?: number;
    active?: boolean;
    rep_selection_method?: string;
    distribution_method?: string;
    updated_at?: string;
    conditions?: {
      id?: string;
    }[];
    routings?: {
      rep_id?: string;
      rule_id?: string;
    }[];
  };
}

interface RepOrderExpectedResponse {
  fetchRepOrder?: { id?: string; full_name?: string }[];
}

const FETCH_REP_ORDER = gql`
  query fetchRepOrder($user_ids: [String!]!) {
    fetchRepOrder(user_ids: $user_ids) {
      id
      full_name
    }
  }
`;

const CREATE_OR_UPDATE_CUSTOM_RULE = gql`
  mutation createOrUpdateCustomTransferRule($rule_id: String, $data: TransferRuleInput!) {
    createOrUpdateCustomTransferRule(rule_id: $rule_id, data: $data) {
      id
      priority
      active
      rep_selection_method
      distribution_method
      updated_at
      conditions {
        id
      }
      routings {
        rep_id
        rule_id
      }
    }
  }
`;

const FETCH_INDUSTRY_OPTIONS = gql`
  query fetchIndustryOptions {
    fetchIndustryOptions {
      id
      label
      sub_industries
    }
  }
`;

const FETCH_ALL_AVAILABLE_CONDITION = gql`
  query fetchAllAvailableCondition {
    fetchAllAvailableCondition
  }
`;

const FETCH_ONE_CUSTOM_RULE = gql`
  query fetchOneCustomTransferRule($rule_id: String!) {
    fetchOneCustomTransferRule(rule_id: $rule_id) {
      id
      rule_type
      name
      priority
      active
      updated_at
      rep_selection_method
      distribution_method
      entity_type
      rank_sort_type
      date_range
      metric
      conditions {
        field
        operator
        value
        type
        cadence
      }
      routings {
        rep_id
        close_rate
      }
      labels {
        label_id
        label {
          id
          name
          color
          description
          reps {
            user_id
            user {
              id
              full_name
            }
          }
        }
      }
    }
  }
`;

const GET_ORG_INFO = gql`
  query fetchOrganization {
    fetchOrganization {
      id
      Teams {
        id
        name
      }
      can_edit_sdr_transfer_rules
      Reps {
        id
        full_name
        userLabels {
          label {
            color
            id
            name
          }
        }
      }
      Sites {
        id
        name
      }
    }
  }
`;

const formatMultipleTypeNames = (item: string) => {
  switch (item) {
    case "In":
      return "Equals Multiple";
    case "NotIn":
      return "Does Not Equal Multiple";
    default:
      return formatTypeName(item);
  }
};

const getUserLabels = (userId: string, labels: LabelT[]) => {
  const userLabels = labels.filter((label) => label.reps?.some((rep) => rep.user_id === userId));

  if (userLabels.length === 0) return [];
  return userLabels.map((label) => label.id);
};

const yupValidationSchema = Yup.object().shape({
  name: Yup.string().required("Field is required"),
  conditions: Yup.array()
    .required("Field is required")
    .test("has at least one condition", "Must have at least one condition", (value) => {
      return !!value && value?.length > 0;
    })
    .test("unique fields", "Each field can only be used once", (value) => testIfFieldsAreUnique(value ?? []))
    .of(
      Yup.object().shape({
        field: Yup.string()
          .required("Field is required")
          .test("Field is required", "Field is required", (value) => value !== "" && value !== undefined),
        operator: Yup.string()
          .required("Operator is required")
          .test("Operator is required", "Operator is required", (value) => value !== "" && value !== undefined),
        value: Yup.string()
          .when("operator", {
            is: (value) => value === "In" || value === "NotIn",
            then: Yup.string().test("value", "Requires multiple values", (value) =>
              testForArrayWithMultipleElements(value ?? ""),
            ),
          })
          .when("operator", {
            is: (value) => value === "IsNull" || value === "NotNull",
            then: Yup.string().notRequired(),
          })
          // default is required
          .when("operator", {
            is: (value) => value !== "In" && value !== "NotIn" && value !== "IsNull" && value !== "NotNull",
            then: Yup.string().required("Value is required"),
          }),
        cadence: Yup.string().when("operator", {
          is: (value) => value === "InTheNext" || value === "SinceTheLast", // Corrected
          then: Yup.string().required("Cadence is required"),
        }),
      }),
    ),
  labels: Yup.array().notRequired().nullable(),
  rep_ids: Yup.array().of(Yup.string()),
});

const YES_NO_OPTIONS = [
  { label: "True", value: "true" },
  { label: "False", value: "false" },
];

const AddOrEditTransferRule = ({ rule_id }: { rule_id?: string }) => {
  const history = useHistory();
  const { search } = useLocation();

  const formikRef = useRef() as any;
  const isNew = rule_id === "new";

  const searchParams = new URLSearchParams(search);
  const ruleType = searchParams.get("type")?.toLowerCase() === "booking" ? "Booking" : "Transfer";

  const { data: dataSelectedRule, loading: loadingSelectedRule, refetch: refetchSelectedRule } = useQuery(
    FETCH_ONE_CUSTOM_RULE,
    {
      fetchPolicy: "network-only",
      skip: !rule_id,
      variables: {
        rule_id: rule_id,
      },
      onError({ message, name }) {
        console.log(`Error in ${name}: `, message);
      },
    },
  );

  const { data: dataOrgInfo, error: errorOrgInfo, loading: loadingOrgInfo } = useQuery(GET_ORG_INFO, {
    fetchPolicy: "network-only",
    onError({ message, name }) {
      appToast("Error loading org info.");
    },
  });

  const { data: dataOptions, loading: loadingOptions } = useQuery(FETCH_ALL_AVAILABLE_CONDITION, {
    fetchPolicy: "network-only",
    onError({ message, name }) {
      appToast("Error fetching available conditions");
    },
  });

  const { data: industryOptions } = useQuery<FetchIndustryOptionsExpectedResponse>(FETCH_INDUSTRY_OPTIONS, {
    fetchPolicy: "network-only",
    onError({ message, name }) {
      appToast("Error fetching industry options");
    },
  });

  const [fetchRepOrder, { data: dataOrder }] = useLazyQuery<RepOrderExpectedResponse>(FETCH_REP_ORDER, {
    fetchPolicy: "network-only",
  });

  const [
    createOrUpdateCustomTransferRule,
    { data: updateRule, loading: loadingUpdate, error: errorUpdate },
  ] = useMutation<CreateOrUpdateCustomTransferRuleExpectedResponse>(CREATE_OR_UPDATE_CUSTOM_RULE, {
    onCompleted({ createOrUpdateCustomTransferRule }) {
      if (!createOrUpdateCustomTransferRule) {
        return;
      }

      appToast("Rules Saved");
      history.push("/system-config/transfer-rules");
    },
    onError({ message }) {
      appToast(message);
    },
    refetchQueries: ["fetchAllCustomTransferRule", "FetchAllCustomTransferRule"],
  });

  useEffect(() => {
    if (
      !!dataOrder &&
      !!dataOrder.fetchRepOrder &&
      !!dataOrder.fetchRepOrder.length &&
      !!formikRef.current &&
      !!formikRef.current.setFieldValue &&
      !!formikRef.current.values
    ) {
      const sortedIDs = dataOrder.fetchRepOrder?.map((item: any) => item.id);
      if (arraysAreEqual(sortedIDs, formikRef.current.values.rep_ids)) {
        formikRef.current.setFieldValue("rep_ids", sortedIDs);
      }
    }
  }, [dataOrder]);

  const loading = loadingSelectedRule || loadingOptions || loadingOrgInfo || loadingUpdate;

  const DEFAULT_RULE = {
    id: "",
    name: "",
    active: true,
    distribution_method: "RoundRobin",
    entity_type: "User",
    conditions: [{ field: "", operator: "", value: "", type: "" }],
    reps: [],
    transfer_type: "Auto",
    backup_rule: "ScheduleMeeting",
    rank_sort_type: "Manually",
    metric: "CloseRate",
    date_range: "Today",
  } as const;

  const findConditionTypeFromField = (value: string, PersonType: "Lead" | "User"): ConditionTypes => {
    const availableFields: ConditionField[] = [
      ...(dataOptions?.fetchAllAvailableCondition?.fields?.[PersonType] ?? []),
    ];
    const type = availableFields?.slice().filter((item: any) => item?.key === value)[0]?.type;

    if (!type) {
      return ConditionTypes.Text;
    }

    return handleRateAndPercentage(type) as ConditionTypes;
  };

  const findOperatorsFromFieldType = (conditionType: ConditionTypes) => {
    const val = conditionType === "Rate" || conditionType === "Percentage" ? "Number" : conditionType;

    return !!conditionType &&
      !!dataOptions?.fetchAllAvailableCondition?.operations &&
      !!dataOptions.fetchAllAvailableCondition.operations[val]
      ? conditionType === "Text"
        ? [
            ...dataOptions.fetchAllAvailableCondition.operations[val]?.map((item: string) => ({
              label: formatMultipleTypeNames(item),
              value: item,
            })),
            ...dataOptions.fetchAllAvailableCondition.operations["MultiText"]?.map((item: string) => ({
              label: formatMultipleTypeNames(item),
              value: item,
            })),
          ]
        : dataOptions.fetchAllAvailableCondition.operations[val]?.map((item: string) => ({
            label: formatMultipleTypeNames(item),
            value: item,
          }))
      : [{ label: "Select field first", value: "" }];
  };

  const generateSubIndustryOptions = ({ conditions }: { conditions: ICondition[] }) => {
    const industryConditions = conditions.filter((item: any) => item.field === "industry");

    if (!industryConditions.length) {
      return [{ label: "Select industry first", value: "", disabled: true }];
    }

    if (industryConditions.some((item: any) => item.operator === "IsNull")) {
      return [{ label: "Subindustry condition requires industry to be not null", value: "", disabled: true }];
    }

    const sub_industries: OptionItem[] = [];

    const allowedIndustries: string[] = [];
    const notAllowedIndustries: string[] = [];

    industryConditions.forEach((item: ICondition) => {
      if (item.operator === "Equal") {
        allowedIndustries.push(item.value ?? "N/A");
      }

      if (item.operator === "In") {
        const industries = testForArrayFormatedAsAString(item.value)
          ? JSON.parse(item.value) ?? item.value
          : item.value;
        industries.length && allowedIndustries.push(...industries);
      }

      if (item.operator === "NotEqual") {
        notAllowedIndustries.push(item.value ?? "");
      }

      if (item.operator === "NotIn") {
        const industries = testForArrayFormatedAsAString(item.value)
          ? JSON.parse(item.value) ?? item.value
          : item.value;
        industries.length && notAllowedIndustries.push(...industries);
      }
    });

    const specifiesNotNull = industryConditions.every((item: ICondition) => item.operator === "NotNull");
    const noEqualsOrEqualsMultiple = industryConditions.every(
      (item: ICondition) => item.operator !== "Equal" && item.operator !== "In",
    );

    const shouldStartWithAllIndustries = specifiesNotNull || noEqualsOrEqualsMultiple;

    if (shouldStartWithAllIndustries) {
      // push all sub industries
      industryOptions?.fetchIndustryOptions?.forEach((industry: FetchedIndustry) => {
        industry.sub_industries.forEach((sub_industry: string) => {
          sub_industries.push({ label: sub_industry ?? "", value: sub_industry ?? "" });
        });
      });
    }

    industryOptions?.fetchIndustryOptions?.forEach((industry: FetchedIndustry) => {
      if (allowedIndustries.includes(industry.label)) {
        industry.sub_industries.forEach((sub_industry: string) => {
          // if not already in the array
          if (!sub_industries.some((item: any) => item.label === sub_industry)) {
            sub_industries.push({ label: sub_industry ?? "", value: sub_industry ?? "" });
          }
        });
      }
      // if not allowed check and remove any
      if (notAllowedIndustries.includes(industry.label)) {
        industry.sub_industries.forEach((sub_industry: string) => {
          // if already in the array
          if (sub_industries.some((item: any) => item.label === sub_industry)) {
            sub_industries.splice(
              sub_industries.findIndex((item: any) => item.label === sub_industry),
              1,
            );
          }
        });
      }
    });

    return sub_industries.sort((a: any, b: any) => a.label.localeCompare(b.label));
  };

  const findDropdownOptionsFromField = (value: string, personType: "User" | "Lead", values: any) => {
    // sub industry is a special case with lots of extra logic
    if (value === "sub_industry") {
      return generateSubIndustryOptions({
        conditions: values.conditions,
      });
    }

    // BE fetched Data is split betwen User and Lead
    const fields = [...dataOptions?.fetchAllAvailableCondition?.fields?.[personType]];

    if (!fields) {
      return "";
    }
    return !!value && fields?.slice().filter((item: any) => item.key === value).length
      ? fields
          ?.slice()
          .filter((item: any) => item.key === value)[0]
          .options?.map((item: string) => ({
            label: item ?? "N/A",
            value: item ?? "",
          }))
      : [{ label: "Select field first", value: "" }];
  };

  const isRepValid = (repId: string) => {
    const repIsValid =
      !!dataOrgInfo?.fetchOrganization?.Reps &&
      dataOrgInfo?.fetchOrganization?.Reps.slice().filter((item: IRep) => repId === item.id);
    return repIsValid;
  };

  if (loadingSelectedRule) return <Loading />;

  return (
    <Page>
      <Container editState={true}>
        <Header>
          <FlexDiv direction="column" gap={4}>
            <FlexDiv
              gap={4}
              align="center"
              style={{ cursor: "pointer", marginBottom: "8px" }}
              onClick={() => history.replace("/system-config/transfer-rules")}
            >
              <PhoenixIcon svg={chevron_left} size={16} pointer />
              <AppText
                fontSize={10}
                fontWeight={600}
                lineHeight={16}
                uppercase
                letterSpacing={1}
                color={theme.buttontext.brand_outline.default}
              >
                Back
              </AppText>
            </FlexDiv>

            <AppText fontSize={22} fontWeight={500} lineHeight={28}>
              {isNew ? "Add" : "Edit"} Transfer Rule
            </AppText>
          </FlexDiv>

          <FlexDiv align="center" gap={8}>
            {isNew ? (
              <PhoenixAppButton
                onClick={() => history.replace("/system-config/transfer-rules")}
                variant="danger-outline"
                buttonType="secondary"
              >
                Cancel
              </PhoenixAppButton>
            ) : null}

            <PhoenixAppButton
              buttonType="secondary"
              onClick={() => formikRef.current?.handleSubmit()}
              type="button"
              disabled={loadingUpdate}
              variant="brand"
            >
              Save Rule
            </PhoenixAppButton>
          </FlexDiv>
        </Header>

        <Body>
          <Formik
            enableReinitialize={true}
            innerRef={formikRef}
            initialValues={{
              name: dataSelectedRule?.fetchOneCustomTransferRule?.name || DEFAULT_RULE.name,
              active: dataSelectedRule?.fetchOneCustomTransferRule?.active ?? DEFAULT_RULE.active,
              distribution_method:
                dataSelectedRule?.fetchOneCustomTransferRule?.distribution_method || DEFAULT_RULE.distribution_method,
              entity_type: dataSelectedRule?.fetchOneCustomTransferRule?.entity_type || DEFAULT_RULE.entity_type,
              conditions: dataSelectedRule?.fetchOneCustomTransferRule?.conditions
                ? dataSelectedRule?.fetchOneCustomTransferRule?.conditions?.map((condition: ICondition) => ({
                    field: condition.field,
                    operator: condition.operator,
                    value: parseInt(condition.value) ? parseInt(condition.value) : condition.value,
                    type: condition.type,
                    cadence: condition.cadence,
                  }))
                : DEFAULT_RULE.conditions,
              reps: dataSelectedRule?.fetchOneCustomTransferRule?.routings
                ? dataSelectedRule?.fetchOneCustomTransferRule?.routings?.map((rep: IRep, index: number) => ({
                    rep_id: rep.rep_id,
                    close_rate: rep.close_rate,
                    priority: rep.priority ?? index + 1,
                  }))
                : DEFAULT_RULE.reps,
              rank_sort_type:
                dataSelectedRule?.fetchOneCustomTransferRule?.rank_sort_type || DEFAULT_RULE.rank_sort_type,
              transfer_type: dataSelectedRule?.fetchOneCustomTransferRule?.transfer_type || DEFAULT_RULE.transfer_type,
              backup_rule: dataSelectedRule?.fetchOneCustomTransferRule?.backup_rule || DEFAULT_RULE.backup_rule,
              metric: dataSelectedRule?.fetchOneCustomTransferRule?.metric || DEFAULT_RULE.metric,
              date_range: dataSelectedRule?.fetchOneCustomTransferRule?.date_range || DEFAULT_RULE.date_range,
              labels: dataSelectedRule?.fetchOneCustomTransferRule?.labels?.map((v: any) => v.label) ?? [],
              rep_ids: (
                dataSelectedRule?.fetchOneCustomTransferRule?.routings?.map((el: any) => el.rep_id) || []
              ).filter((id: string) => isRepValid(id)),
            }}
            validationSchema={yupValidationSchema}
            onSubmit={async (values) => {
              const reps = values.rep_ids.map((id) => ({
                rep_id: id,
                label_ids: getUserLabels(id, values.labels),
              }));

              await createOrUpdateCustomTransferRule({
                variables: {
                  data: {
                    name: values.name,
                    conditions: values.conditions?.map((item: ICondition) => ({
                      field: item.field,
                      operator: item.operator,
                      value: !!item.value ? item.value.toString() : "",
                      type: item.type,
                      cadence: item.cadence,
                    })),
                    date_range: values.date_range,
                    metric: values.metric,
                    rank_sort_type: values.rank_sort_type,
                    distribution_method: values.distribution_method,
                    entity_type: values.entity_type,
                    rule_type: dataSelectedRule?.fetchOneCustomTransferRule?.rule_type ?? ruleType,
                    reps,
                    labels_id: values.labels.map((item) => item.id),
                  },
                  rule_id: !isNew ? rule_id ?? undefined : undefined,
                },
              });
            }}
          >
            {({ errors, values, setFieldValue }: FormikProps<MyFormikProps>) => {
              const selectedLabelRepIDs = values.labels?.reduce(
                (acc, label) => [...acc, ...(label.reps?.map((r) => r.user_id) || [])],
                [] as string[],
              );

              const repOptions = loadingOrgInfo
                ? [{ label: "Loading...", disabled: true, value: "" }]
                : (dataOrgInfo?.fetchOrganization?.Reps ?? [])
                    .filter((item: IRep) => !selectedLabelRepIDs?.includes(item.id!))
                    .map((item: IRep) => ({
                      label: item.full_name ?? "",
                      value: item.id!,
                    }))
                    .sort((a: any, b: any) => a.label.localeCompare(b.label));

              const reorderReps = (list: string[], startIndex: number, endIndex: number) => {
                const result = Array.from(list);
                const [removed] = result.splice(startIndex, 1);
                result.splice(endIndex, 0, removed);

                setFieldValue("rep_ids", result);
              };

              const onDragEndReps = (result: any) => {
                // dropped outside the list
                if (!result.destination) {
                  return;
                }

                reorderReps(values.rep_ids, result.source.index, result.destination.index);
              };

              const renderCadenceSelect = ({
                currentCondition,
                index,
              }: {
                currentCondition: ICondition;
                index: number;
              }) => {
                const fieldType = findConditionTypeFromField(
                  currentCondition.field,
                  values.entity_type as "User" | "Lead",
                );

                const dateTypeCondition = fieldType === "Date" || fieldType === "DateTime";

                const operatorRequiresCadence = ["InTheNext", "SinceTheLast"].includes(currentCondition?.operator);

                if (dateTypeCondition && operatorRequiresCadence) {
                  return (
                    <PhoenixMultiSelect
                      name={`conditions[${index}].cadence`}
                      error={(errors as any)?.conditions?.[index]?.cadence}
                      isMulti={false}
                      isClearable={false}
                      placeholder="Select one"
                      options={dataOptions?.fetchAllAvailableCondition?.date_cadence}
                      value={{
                        label: dataOptions?.fetchAllAvailableCondition?.date_cadence.find(
                          (item: { label: string; value: string }) => item.value === currentCondition?.cadence,
                        )?.label,
                        value: currentCondition?.cadence,
                      }}
                      onChange={(value: any) => {
                        setFieldValue(`conditions[${index}].cadence`, value.value);
                      }}
                    />
                  );
                } else {
                  return null;
                }
              };

              const renderValueSelect = ({
                currentCondition,
                index,
              }: {
                currentCondition: ICondition;
                index: number;
              }) => {
                // for Booleons there can be only 1 type of input regardless of the field
                if (!currentCondition?.field) {
                  return null;
                }

                // date checks

                const dateCondition =
                  findConditionTypeFromField(currentCondition.field, values.entity_type as "User" | "Lead") === "Date";

                const operatorRequiresCadence = ["InTheNext", "SinceTheLast"].includes(currentCondition?.operator);

                const handleDateValueArrowClick = (direction: 1 | -1) => {
                  // check if value would be negative
                  if (direction === -1 && parseInt(currentCondition.value || "1") <= 1) {
                    appToast("Value must be a positive number");
                    return;
                  }

                  setFieldValue("conditions", [
                    ...values.conditions?.map((item: ICondition, i: number) =>
                      i !== index
                        ? item
                        : {
                            ...currentCondition,
                            value: ((parseInt(currentCondition.value) || 0) + direction).toString(),
                          },
                    ),
                  ]);
                };

                const handleDateValueChange = (e: any) => {
                  const parsedValue = parseInt(e?.target?.value);

                  if (parsedValue <= 0 || isNaN(parsedValue)) {
                    setFieldValue("conditions", [
                      ...values.conditions?.map((item: ICondition, i: number) =>
                        i !== index
                          ? item
                          : {
                              ...currentCondition,
                              value: "",
                            },
                      ),
                    ]);
                    return;
                  }

                  setFieldValue("conditions", [
                    ...values.conditions?.map((item: ICondition, i: number) =>
                      i !== index
                        ? item
                        : {
                            ...currentCondition,
                            value: parsedValue.toString(),
                          },
                    ),
                  ]);
                };

                if (dateCondition && !operatorRequiresCadence) {
                  return (
                    <DateTimePickerWrapper>
                      <DateTimePicker
                        format="MM/dd/yyyy"
                        // no clock
                        disableClock={true}
                        onChange={(date) => {
                          setFieldValue(`conditions[${index}].value`, date);
                        }}
                        value={currentCondition.value ? new Date(currentCondition.value) : undefined}
                      />
                    </DateTimePickerWrapper>
                  );
                }

                if (dateCondition && operatorRequiresCadence) {
                  return (
                    <PhoenixInput
                      inputValueType="number"
                      value={
                        typeof currentCondition.value === "string"
                          ? parseInt(currentCondition.value)
                          : currentCondition.value
                      }
                      showNumberArrows={true}
                      handleNumberArrowDownClick={() => handleDateValueArrowClick(-1)}
                      handleNumberArrowUpClick={() => handleDateValueArrowClick(1)}
                      onChange={handleDateValueChange}
                    />
                  );
                }

                if (
                  !!currentCondition.field &&
                  findConditionTypeFromField(currentCondition.field, values.entity_type as "User" | "Lead") ===
                    "Boolean"
                ) {
                  return (
                    <PhoenixMultiSelect
                      isMulti={false}
                      marginBottom={false}
                      placeholder="Select one"
                      name={`conditions[${index}].value`}
                      error={(errors as any)?.conditions?.[index]?.value}
                      options={YES_NO_OPTIONS}
                      value={YES_NO_OPTIONS.find((item: any) => item.value === currentCondition.value)}
                      onChange={(option: any) => {
                        setFieldValue(`conditions[${index}].value`, option.value);
                      }}
                    />
                  );
                }

                if (
                  currentCondition.operator === "IsNull" ||
                  currentCondition.operator === "NotNull" ||
                  !currentCondition.operator
                ) {
                  return (
                    <div
                      style={{
                        width: "100%",
                      }}
                    />
                  );
                }

                if (
                  (!!currentCondition.field &&
                    findConditionTypeFromField(currentCondition.field, values.entity_type as "User" | "Lead") ===
                      "Dropdown") || // if the field is a multi
                  findConditionTypeFromField(currentCondition.field, values.entity_type as "User" | "Lead") ===
                    "MultiDropdown"
                ) {
                  switch (currentCondition.operator) {
                    // this is different from the text field which is a normal input not a select
                    case "Equal":
                    case "NotEqual":
                      const options = findDropdownOptionsFromField(
                        currentCondition.field,
                        values.entity_type as "User" | "Lead",
                        values,
                      );
                      return (
                        <PhoenixMultiSelect
                          isMulti={false}
                          marginBottom={false}
                          placeholder="Select one"
                          name={`conditions[${index}].value`}
                          error={(errors as any)?.conditions?.[index]?.value}
                          options={options}
                          value={options?.find((item: any) => item.value === currentCondition.value)}
                          onChange={(option: any) => {
                            setFieldValue(`conditions[${index}].value`, option.value);
                          }}
                        />
                      );

                    default:
                      return (
                        <PhoenixMultiSelect
                          creatableOptions
                          isMulti={true}
                          name={`conditions[${index}].value`}
                          error={(errors as any)?.conditions?.[index]?.value}
                          placeholder="Select two or more"
                          options={findDropdownOptionsFromField(
                            currentCondition.field,
                            values.entity_type as "User" | "Lead",
                            values,
                          )}
                          onChange={(newValue: any) => {
                            const fullList = newValue?.map((item: any) => item.value);
                            setFieldValue("conditions", [
                              ...values.conditions.slice()?.map((item: ICondition, i: number) =>
                                i !== index
                                  ? item
                                  : {
                                      field: currentCondition.field,
                                      operator: currentCondition.operator,
                                      value: JSON.stringify(fullList),
                                      type: currentCondition.type,
                                    },
                              ),
                            ]);
                          }}
                          value={
                            testForArrayFormatedAsAString(currentCondition.value)
                              ? JSON.parse(currentCondition.value)?.map((item: string) => ({
                                  label: item,
                                  value: item,
                                }))
                              : []
                          }
                        />
                      );
                  }
                }

                // the default for the rest of the field types is the the same currently but this should be changed in the future by adding more cases above
                // this is applied currently applied to Text, List, Number, and Date field types

                switch (currentCondition.operator) {
                  case "In":
                  case "NotIn":
                    return (
                      <>
                        <CreatableSelect
                          isMulti={true}
                          onChange={(newValue: any) => {
                            const emailsList = newValue?.map((item: any) => item.value);
                            setFieldValue("conditions", [
                              ...values.conditions.slice()?.map((item: ICondition, i: number) =>
                                i !== index
                                  ? item
                                  : {
                                      field: currentCondition.field,
                                      operator: currentCondition.operator,
                                      value: JSON.stringify(emailsList),
                                      type: currentCondition.type,
                                    },
                              ),
                            ]);
                          }}
                          placeholder="Enter multiple options..."
                          defaultValue={
                            testForArrayFormatedAsAString(currentCondition.value)
                              ? JSON.parse(currentCondition.value)?.map((item: string) => ({
                                  label: item,
                                  value: item,
                                }))
                              : []
                          }
                        />
                      </>
                    );
                  default:
                    // unlike the dropdown and multi dropdown the Equal and NotEqual operators for text fields are standard inputs not selects
                    return (
                      <PhoenixInput
                        value={currentCondition.value}
                        onChange={(e: any) => {
                          setFieldValue("conditions", [
                            ...values.conditions.slice()?.map((item: ICondition, i: number) =>
                              i !== index
                                ? item
                                : {
                                    field: currentCondition.field,
                                    operator: currentCondition.operator,
                                    value: e.target.value,
                                    type: currentCondition.type,
                                  },
                            ),
                          ]);
                        }}
                      />
                    );
                }
              };

              const handleDateRangeChange = ({
                dateRange,
                setFieldValue,
              }: {
                dateRange: string;
                setFieldValue: any;
              }) => {
                setFieldValue("date_range", dateRange);
              };

              return (
                <>
                  <PhoenixInput
                    titleText="Transfer Rule Name"
                    titleTextSpacing={8}
                    width={376}
                    value={values.name}
                    onChange={(e: any) => setFieldValue("name", e.target.value)}
                    displayNoContextText
                  />
                  <SpacerDiv />
                  <ContainerBoldText>How would you like to manage event transfers for this rule?</ContainerBoldText>
                  <RadioSection>
                    <RadioDiv>
                      <PhoenixRadio
                        selected={values.entity_type === "User"}
                        onClick={() => {
                          setFieldValue("entity_type", "User");
                          setFieldValue("conditions", []);
                        }}
                      />
                      <AppText>Rep Based</AppText>
                    </RadioDiv>
                    <RadioDiv>
                      <PhoenixRadio
                        selected={values.entity_type === "Lead"}
                        onClick={() => {
                          setFieldValue("entity_type", "Lead");
                          setFieldValue("conditions", []);
                        }}
                      />
                      <AppText>Lead Based</AppText>
                    </RadioDiv>
                  </RadioSection>
                  <SpacerDiv />
                  <SpacerDiv />
                  <ContainerBoldText>
                    How would you like to prioritize lead distribution for this rule?
                  </ContainerBoldText>
                  <RadioSection>
                    <RadioDiv>
                      <PhoenixRadio
                        selected={values.distribution_method === "RoundRobin"}
                        onClick={() => {
                          setFieldValue("distribution_method", "RoundRobin");
                        }}
                      />
                      <AppText>Round Robin (even distribution) </AppText>
                    </RadioDiv>
                    <RadioDiv>
                      <PhoenixRadio
                        selected={values.distribution_method === "Ranking"}
                        onClick={async () => {
                          setFieldValue("distribution_method", "Ranking");
                        }}
                      />
                      <AppText>By Rank (always prioritize leads to your top ranked Reps)</AppText>
                    </RadioDiv>
                  </RadioSection>
                  <SpacerDiv
                    style={{
                      borderBottom: `solid 1px ${theme.NEUTRAL200}`,
                      marginTop: "16px",
                      marginBottom: "16px",
                    }}
                  />
                  <ConditionContainer>
                    <FlexDiv justify="space-between" align="center" style={{ marginBottom: "24px" }}>
                      <AppText fontSize={20} fontWeight={500} lineHeight={26}>
                        If a Rep Meets These Criteria...
                      </AppText>

                      <PhoenixAppButton
                        variant="brand"
                        buttonType="secondary"
                        onClick={() => {
                          setFieldValue("conditions", [
                            ...values.conditions,
                            {
                              field: "",
                              operator: "",
                              value: "",
                              type: "",
                            },
                          ]);
                        }}
                      >
                        ADD CRITERIA
                      </PhoenixAppButton>
                    </FlexDiv>

                    <CriteriaDiv>
                      <FieldArray name="conditions">
                        {({ remove }) => (
                          <>
                            {values.conditions?.map((item: any, index: number) => {
                              const conditionFieldOptions =
                                dataOptions?.fetchAllAvailableCondition?.fields?.[values.entity_type]?.map(
                                  (item: any) => ({
                                    label: item.label,
                                    value: item.key,
                                    type: item.type,
                                  }),
                                ) || [];

                              const conditionOperatorOptions = findOperatorsFromFieldType(
                                findConditionTypeFromField(
                                  values.conditions[index].field,
                                  values.entity_type as "User" | "Lead",
                                ),
                              );

                              return (
                                <ConditionBlock key={`conditionblock-${index}`}>
                                  <AppText
                                    fontSize={10}
                                    fontWeight={600}
                                    lineHeight={16}
                                    uppercase
                                    letterSpacing={1}
                                    color={theme.text.brand.primary}
                                    noWrap
                                  >
                                    Criteria {index + 1}
                                  </AppText>
                                  <ConditionBody>
                                    <FlexDiv direction="column" grow={1}>
                                      <PhoenixMultiSelect
                                        isMulti={false}
                                        marginBottom={false}
                                        placeholder="Select one"
                                        name={`conditions.${index}.field`}
                                        error={(errors as any)?.conditions?.[index]?.field}
                                        options={conditionFieldOptions}
                                        value={conditionFieldOptions?.find(
                                          (item: any) => item.value === values.conditions[index]?.field,
                                        )}
                                        onChange={(option: any) => {
                                          setFieldValue(`conditions[${index}].field`, option?.value);
                                          setFieldValue(`conditions[${index}].type`, option?.type);
                                          setFieldValue(`conditions[${index}].operator`, "");
                                          setFieldValue(`conditions[${index}].value`, undefined);
                                          setFieldValue(`conditions[${index}].cadence`, undefined);
                                        }}
                                      />
                                    </FlexDiv>

                                    <FlexDiv direction="column" grow={1}>
                                      <PhoenixMultiSelect
                                        isMulti={false}
                                        marginBottom={false}
                                        key={values.conditions[index]?.field ?? `condition ${index}`}
                                        placeholder="Select one"
                                        name={`conditions.${index}.operator`}
                                        error={(errors as any)?.conditions?.[index]?.operator}
                                        options={conditionOperatorOptions}
                                        value={conditionOperatorOptions?.find(
                                          (item: any) => item.value === values.conditions[index]?.operator,
                                        )}
                                        onChange={(option: any) => {
                                          setFieldValue(`conditions.${index}.operator`, option?.value);
                                          setFieldValue(`conditions[${index}].value`, "");
                                          setFieldValue(`conditions[${index}].cadence`, undefined);
                                        }}
                                      />
                                    </FlexDiv>

                                    <FlexDiv direction="column" grow={1}>
                                      {!!values.conditions[index]?.operator && (
                                        <ValueDiv>
                                          <div>
                                            {renderValueSelect({
                                              currentCondition: values.conditions[index],
                                              index,
                                            })}
                                          </div>
                                          <div>
                                            {renderCadenceSelect({
                                              currentCondition: values.conditions[index],
                                              index,
                                            })}
                                          </div>
                                        </ValueDiv>
                                      )}
                                    </FlexDiv>
                                  </ConditionBody>

                                  {values.conditions.length > 1 ? (
                                    <PhoenixIcon
                                      svg={trash}
                                      size={16}
                                      onClick={() => {
                                        remove(index);
                                      }}
                                    />
                                  ) : null}
                                </ConditionBlock>
                              );
                            })}
                          </>
                        )}
                      </FieldArray>
                    </CriteriaDiv>
                  </ConditionContainer>

                  <SpacerDiv />

                  <ConditionContainer>
                    <AppText fontSize={20} fontWeight={500} lineHeight={26} style={{ marginBottom: "24px" }}>
                      ...Then Distribute to Reps According to These Rules
                    </AppText>

                    <RepSelectionContainer>
                      <div style={{ width: "100%" }}>
                        <FlexDiv direction="column" gap={16} style={{ padding: "16px" }}>
                          <FlexDiv align="center" justify="space-between" style={{ marginBottom: "16px" }}>
                            <AppText
                              fontSize={10}
                              fontWeight={600}
                              letterSpacing={1}
                              uppercase
                              color={theme.text.neutral.secondary}
                            >
                              Select reps to include in this rule
                            </AppText>

                            <PhoenixAppButton
                              onClick={() => {
                                setFieldValue("labels", []);
                                setFieldValue("rep_ids", []);
                              }}
                              variant="danger-outline"
                              buttonType="ghost-small"
                            >
                              Clear Selection
                            </PhoenixAppButton>
                          </FlexDiv>

                          <FlexDiv direction="column" gap={16} style={{ maxWidth: 380 }}>
                            <LabelSelect
                              titleText="Reps With This Label"
                              titleTextSpacing={8}
                              value={values.labels?.map((x) => x.id)}
                              includeReps
                              onChange={(arr) => {
                                const newLabelRepIDs = arr.reduce(
                                  (acc, o) => [...acc, ...o.data.reps.map((r) => r.user_id)],
                                  [] as string[],
                                );

                                const oldLabelRepIDs = values.labels.reduce(
                                  (acc, label) => [...acc, ...label.reps.map((r) => r.user_id)],
                                  [] as string[],
                                );

                                const repIDsToAdd = newLabelRepIDs.filter((id) => !oldLabelRepIDs.includes(id));

                                const repIDsToRemove = oldLabelRepIDs.filter((id) => !newLabelRepIDs.includes(id));

                                setFieldValue(
                                  "labels",
                                  arr.map((o) => o.data),
                                );

                                setFieldValue("rep_ids", [
                                  ...values.rep_ids.filter((id) => !repIDsToRemove.includes(id)),
                                  ...repIDsToAdd,
                                ]);
                              }}
                            />

                            <PhoenixMultiSelect
                              marginBottom={false}
                              hideErrorMessage
                              isClearable
                              name="reps"
                              isMulti
                              titleText="Add Rep Individually"
                              isOptionDisabled={(option) => option.disabled}
                              titleTextSpacing={8}
                              options={repOptions}
                              value={values.rep_ids.map((item: any) =>
                                repOptions.find((option: OptionItem) => option.value === item),
                              )}
                              onChange={(v: any) => {
                                const newSelectedRepIDs = v.map((item: any) => item.value);

                                const labelAssociatedRepIDs = values.labels.reduce(
                                  (acc, label) => [...acc, ...(label.reps?.map((r) => r.user_id) || [])],
                                  [] as string[],
                                );

                                const currentIndividualRepIDs = values.rep_ids.filter(
                                  (id) => !labelAssociatedRepIDs.includes(id),
                                );

                                const repIDsToAdd = newSelectedRepIDs.filter(
                                  (id: any) => !currentIndividualRepIDs.includes(id),
                                );
                                const repIDsToRemove = currentIndividualRepIDs.filter(
                                  (id: any) => !newSelectedRepIDs.includes(id),
                                );

                                const updatedRepIDs = [
                                  ...labelAssociatedRepIDs,
                                  ...currentIndividualRepIDs.filter((id) => !repIDsToRemove.includes(id)),
                                  ...repIDsToAdd,
                                ];

                                const uniqueUpdatedRepIDs = [...new Set(updatedRepIDs)];
                                setFieldValue("rep_ids", uniqueUpdatedRepIDs);
                              }}
                            />
                          </FlexDiv>
                        </FlexDiv>
                      </div>

                      <RankSelectedRepsContainer>
                        <FlexDiv align="center" justify="space-between">
                          <AppText
                            fontSize={10}
                            fontWeight={600}
                            letterSpacing={1}
                            uppercase
                            color={theme.text.neutral.secondary}
                            style={{ marginBottom: "16px" }}
                          >
                            Rank Selected Reps
                          </AppText>

                          {values?.rep_ids?.length > 1 && (
                            <FlexDiv
                              onClick={async () => {
                                await fetchRepOrder({
                                  variables: {
                                    user_ids: values.rep_ids,
                                  },
                                });
                              }}
                              align="center"
                              gap={4}
                              style={{ cursor: "pointer" }}
                            >
                              <PhoenixIcon
                                svg={refresh}
                                color={theme.icon.brand.default}
                                hoverColor={theme.icon.brand.default}
                                pointer
                                size={12}
                              />
                              <AppText fontSize={10} fontWeight={600} lineHeight={14} color={theme.text.brand.primary}>
                                Sellfire Suggested Ranking
                              </AppText>
                            </FlexDiv>
                          )}
                        </FlexDiv>

                        <RankSelectedRepsTable
                          data={
                            values.rep_ids.map((item: any) =>
                              returnIndividualDataFromArrayBasedOnID({
                                id: item,
                                array: dataOrgInfo?.fetchOrganization?.Reps || ([] as any),
                              }),
                            ) ?? []
                          }
                          selectedLabels={values.labels ?? []}
                          isLoading={loadingOrgInfo}
                          isError={errorOrgInfo}
                          onDragEnd={onDragEndReps}
                          onDelete={(itemID: any) =>
                            setFieldValue(
                              "rep_ids",
                              values.rep_ids.filter((id: string) => id !== itemID),
                            )
                          }
                        />
                      </RankSelectedRepsContainer>
                    </RepSelectionContainer>

                    <AppText
                      color={theme.PRIMARY500}
                      fontSize={10}
                      fontWeight={600}
                      style={{ margin: "16px 0" }}
                      uppercase
                    >
                      Priority Order Of Reps
                    </AppText>
                    <InputLabel>
                      How would you like to determine the order in which the above reps are prioritized?
                    </InputLabel>
                    <RadioLabel style={{ marginBottom: "16px" }}>
                      <PhoenixRadio
                        size={16}
                        selected={values.rank_sort_type === "Manually"}
                        onClick={async () => {
                          setFieldValue("rank_sort_type", "Manually");
                        }}
                      />
                      <AppText>Manually (by priority number)</AppText>
                    </RadioLabel>
                    <RadioLabel style={{ marginBottom: "16px" }}>
                      <PhoenixRadio
                        size={16}
                        selected={values.rank_sort_type === "Dynamically"}
                        onClick={async () => {
                          setFieldValue("rank_sort_type", "Dynamically");
                        }}
                      />
                      <AppText>Dynamically</AppText>
                    </RadioLabel>

                    {values.rank_sort_type === "Dynamically" && (
                      <FlexDiv direction="column" gap={16} style={{ width: "396px" }}>
                        <PhoenixMultiSelect
                          name="metric-select"
                          titleText="Metric"
                          isMulti={false}
                          isClearable={false}
                          marginBottom={false}
                          options={METRIC_TYPES_WITHOUT_SPACE}
                          value={METRIC_TYPES_WITHOUT_SPACE?.find((item: OptionItem) => item.value === values.metric)}
                          onChange={(option: any) => {
                            setFieldValue(`metric`, option?.value);
                          }}
                        />

                        <PhoenixMultiSelect
                          name="date-range-select"
                          titleText="Date Range"
                          isMulti={false}
                          isClearable={false}
                          options={DATE_RANGES_WITHOUT_SPACE}
                          value={DATE_RANGES_WITHOUT_SPACE.find((item: OptionItem) => item.value === values.date_range)}
                          onChange={(option: any) => {
                            handleDateRangeChange({
                              dateRange: option?.value,
                              setFieldValue: setFieldValue,
                            });
                          }}
                        />
                      </FlexDiv>
                    )}
                  </ConditionContainer>
                </>
              );
            }}
          </Formik>
        </Body>
      </Container>
    </Page>
  );
};

export default AddOrEditTransferRule;

const Page = styled.div`
  width: 100%;
  height: 100vh;
  padding: 24px;
`;

const Container = styled.div<{ editState: boolean }>`
  width: 100%;
  height: 100%;

  min-width: 1200px;

  background-color: ${({ editState }) => (editState ? theme.surface.brand.secondary : theme.fill.neutral.primary)};
  border-radius: 16px;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 24px 40px;

  background-color: ${theme.fill.neutral.primary};
  border-radius: 16px 16px 0 0;
  border-bottom: 1px solid ${theme.border.neutral.secondary};
`;

const Body = styled.div`
  padding: 40px;
  max-height: 82vh;
  overflow-y: auto;
`;

const ValueDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 13px;
`;

const CriteriaDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 16px;
`;

const RadioDiv = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const SpacerDiv = styled.div`
  height: 16px;
  min-height: 16px;
`;

const RadioSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 14px;
`;

const ContainerBoldText = styled(AppText)`
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 16px;
`;

const DateTimePickerWrapper = styled.div`
  .react-datetime-picker {
    height: 40px;
    * {
      /* color: ${theme.PRIMARY600} !important; */
    }
    border: none;
  }
`;

const InputLabel = styled(AppText)`
  margin-bottom: 8px;
  margin-top: 8px;
  font-weight: 500;
`;

const RadioLabel = styled.label`
  display: flex;
  align-items: center;
  gap: 8px;

  input {
    accent-color: ${theme.PRIMARY500};
  }
`;

const RankSelectedRepsContainer = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
  padding: 16px 24px;

  border-left: 1px solid ${theme.border.neutral.secondary};
`;

const RepSelectionContainer = styled.div`
  display: flex;
  /* flex-direction: column; */

  width: 100%;

  border: 1px solid ${theme.border.neutral.secondary};
  border-radius: 8px;
`;

const ConditionContainer = styled.div`
  min-width: 1075px;
  padding: 24px;
  margin-top: 24px;

  border: 1px solid ${theme.border.neutral.primary};
  border-radius: 8px;
  background-color: ${theme.fill.neutral.primary};
`;

const ConditionBlock = styled.div`
  position: relative;

  display: flex;
  gap: 64px;
  position: relative;

  width: 100%;
  min-width: 1025px;
  padding: 16px;
  margin-bottom: 16px;

  border: 1px solid ${theme.border.neutral.secondary};
  border-radius: 8px;
`;

const ConditionBody = styled.div`
  display: grid;
  gap: 16px;
  grid-template-columns: repeat(3, 1fr);
  width: 100%;
`;

export { AddOrEditTransferRule };
