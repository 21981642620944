import React, { useState, useContext, useEffect, useMemo, useRef } from "react";
import * as Sentry from "@sentry/react";
import { useHistory, useParams } from "react-router-dom";
import styled from "styled-components";
import { gql, useMutation, useQuery } from "@apollo/client";
import { theme } from "../../utils/theme";
import moment from "moment";
import { AppText, Loading, SkeletonBlock } from "../UI";
import { FlexDiv } from "../UI/FlexDiv";
import { PhoenixAppButton, PhoenixExpandButton, PhoenixIcon } from "../UI/Phoenix";
import {
  arrow_right,
  chat,
  chevron_left,
  edit,
  info,
  log_in,
  mail,
  pause,
  phone_call,
  play,
  sequences,
  settings,
  steps,
  users,
} from "../../images/NewDesign";
import { ManageLeadsMenu } from "../UI/Sequences/ManageLeadsMenu";
import { ModalContext } from "../../context";
import { RenameSequenceModal, SequenceBranchModal, SequenceCriteriaModal } from "../modal";
import { SequenceCanvas } from "../UI/Sequences/SequenceCanvas";
import { StepActionButton } from "../UI/Sequences/StepActionButton";
import { SequenceStepModal } from "../modal/Sequences/SequenceStepModal";
import { appToast, errorToast } from "../../utils/toast";
import { SelectAnActionModal } from "../modal/Sequences/SelectAnActionModal";
import { ConfirmDeleteStepModal } from "../modal/Sequences/ConfirmDeleteStepModal";
import { PhoenixStyledTooltip } from "../Dumb/PhoenixStyledTooltip";
import { SequenceNav } from "../UI/Sequences/SequenceNav";
import { EditEndSequenceModal } from "../modal/Sequences/EditEndSequenceModal";
import { LeadListWindow } from "../UI/Sequences/LeadListWindow";
import { useFlags } from "launchdarkly-react-client-sdk";
import { MixpanelActions } from "../../services/mixpanel";
import { cloneDeep, isEqual } from "lodash";
import { loggedInUser } from "src/apollo/cache";
import { findEntryCriteriaMatchingLabel, getInclusionCriteriaFilters } from "src/utils/sequences";

export const FETCH_SEQUENCE = gql`
  query fetchSequence($sequencesFilter: SequencesFilter!) {
    fetchSequences(SequencesFilter: $sequencesFilter) {
      id
      created_at
      created_by {
        id
        full_name
      }
      name
      priority
      is_paused
      email_action_count
      phone_action_count
      sms_action_count
      manager_feedback_action_count
      custom_task_action_count
      leads_count
      completed_count
      not_interested_count
      conversion_count
      all_leads_count
      exit_count
      steps_count
      sequence_entry_criteria_id
      sequence_entry_criteria {
        id
        current_phase
        person_spoke_to
        types
        channels
        sequence_criteria_condition {
          id
          condition_type
          custom_field_type
          field
          value
          operator
        }
      }
      active_steps {
        id
        origin_id
        is_last_step
        is_host_step
        is_no_branch
        lead_count
        conditions {
          id
          dispositionType
        }
        actions {
          id
          template_id
          task
          delay_amount
          custom_task_note
          scheduled_event_behavior
          delivered_perc
          opened_perc
          replied_perc
          clicked_perc
          unsubscribed_perc
        }
        post_step_action
        transfer_to_sequence_id
      }
    }
  }
`;

const FETCH_STEP_COUNT = gql`
  query fetchStepCount($sequencesFilter: SequencesFilter!) {
    fetchSequences(SequencesFilter: $sequencesFilter) {
      id
      steps_count
    }
  }
`;

const START_PAUSE_SEQUENCE = gql`
  mutation createOrUpdateSequence($sequenceInput: SequenceInput!) {
    createOrUpdateSequence(SequenceInput: $sequenceInput) {
      id
      is_paused
    }
  }
`;

interface ParamTypes {
  sequence_id: string;
}

export const SequenceDetail: React.FC = () => {
  const history = useHistory();
  const { sequence_id } = useParams<ParamTypes>();

  const {
    showSequenceCriteriaModal,
    setShowSequenceCriteriaModal,
    showSequenceStepModal,
    setShowSequenceStepModal,
    showRenameSequenceModal,
    setShowRenameSequenceModal,
    setRenameSequenceData,
    showSelectAnActionModal,
    showSequenceBranchModal,
    selectedSequenceStepId,
    showConfirmDeleteSequenceStep,
    showEditEndSequenceModal,
    sequenceNavExpanded,
    setSequenceNavExpanded,
  } = useContext(ModalContext);

  const [startStepAction, setStartStepAction] = useState<string>("");
  const [forceSkipEntry, setForceSkipEntry] = useState<boolean>(false);
  const [skipLoadingCycle, setSkipLoadingCycle] = useState<boolean>(false);
  const [showLeadListWindow, setShowLeadListWindow] = useState<boolean>(false);
  const initialSequence = useRef<any>(null);
  const currentSequence = useRef<any>(null);
  const hasSequenceBeenLoaded = useRef<boolean>(false);

  const { data: dataSequence, loading: loadingSequence, error: errorSequence, refetch: refetchSequence } = useQuery(
    FETCH_SEQUENCE,
    {
      fetchPolicy: "network-only",
      notifyOnNetworkStatusChange: true,
      skip: !sequence_id,
      variables: {
        sequencesFilter: { id: sequence_id },
      },
      onCompleted({ fetchSequences }) {
        console.log("fetchSequence:", fetchSequences);

        if (!fetchSequences.length) {
          appToast("Sequence not found");
          appToast("Redirecting...");
          setTimeout(() => {
            history.push(`/sequences/`);
          }, 3000);
        }

        if (!hasSequenceBeenLoaded.current) {
          initialSequence.current = fetchSequences[0];
          hasSequenceBeenLoaded.current = true;
        }

        currentSequence.current = fetchSequences[0];

        skipLoadingCycle && setSkipLoadingCycle(false);
      },
      onError({ message, name }) {
        console.log(`Error in ${name}: `, message);
      },
    },
  );
  const { data: dataStepCount } = useQuery(FETCH_STEP_COUNT, {
    fetchPolicy: "network-only",
    skip: !sequence_id,
    variables: {
      sequencesFilter: { id: sequence_id },
    },
    onCompleted({ fetchSequences }) {
      console.log("fetchStepCount:", fetchSequences);
    },
    onError({ message, name }) {
      console.log(`Error in ${name}: `, message);
    },
  });

  const [startOrPauseSequence, { loading: loadingStartOrPauseSequence }] = useMutation(START_PAUSE_SEQUENCE, {
    variables: {
      sequenceInput: {
        sequence_id: dataSequence?.fetchSequences?.[0]?.id,
        name: dataSequence?.fetchSequences?.[0]?.name,
        priority: dataSequence?.fetchSequences?.[0]?.priority,
        is_paused: !dataSequence?.fetchSequences?.[0]?.is_paused,
      },
    },
    async onCompleted({ createOrUpdateSequence }) {
      console.log("startOrPauseSequence:", createOrUpdateSequence);
      refetchSequence();
    },
    onError({ message }) {
      errorToast(`${message}`);
      Sentry.captureEvent({
        message: `createSequence GraphQL Error: ${message}`,
      });
      console.log(`createSequence GraphQL Error: ${message}`);
    },
  });

  const handleShowSequenceStepModal = (action: string) => {
    setStartStepAction(action);
    setShowSequenceStepModal(true);
  };

  const sequence = useMemo(() => {
    const data = cloneDeep(dataSequence?.fetchSequences?.[0]);
    const step = data?.active_steps?.find((as: any) => as.origin_id === null);

    // hide first step delay for FieldPulse, FieldPulse sandbox, and our test org.
    // ^ product team request.
    if (
      step &&
      !!step.actions?.length &&
      [
        "91f1b9c2-009b-48f9-93e5-b044788c1aa5",
        "ece14be5-953f-4213-b102-9c409ccbba14",
        "35372a5d-8395-4f2e-974f-4ac1d0216a3e",
      ].includes(loggedInUser().organization_id as string)
    ) {
      step.actions[0].delay_amount = 0;
    }

    return data;
  }, [dataSequence]);

  useEffect(() => {
    return () => {
      const iSeq = initialSequence.current;
      const cSeq = currentSequence.current;

      if (iSeq && cSeq) {
        const stepsDelta = (cSeq?.steps_count || 0) - (iSeq?.steps_count || 0);

        const cSeqBranches = cSeq?.active_steps?.filter((ele: any) => !!ele?.conditions?.length).length;
        const iSeqBranches = iSeq?.active_steps?.filter((ele: any) => !!ele?.conditions?.length).length;

        MixpanelActions.track("Sequence Editing Ended", {
          steps: cSeq?.steps_count,
          sms_steps: cSeq?.sms_action_count,
          email_steps: cSeq?.email_action_count,
          dial_steps: cSeq?.phone_action_count,
          feedback_steps: cSeq?.manager_feedback_count,
          custom_steps: cSeq?.custom_task_action_count,
          is_active: !cSeq?.is_paused,
          branches: cSeq?.active_steps?.filter((ele: any) => !!ele?.conditions?.length).length,
          entry_criteria: findEntryCriteriaMatchingLabel(cSeq?.cSeq_entry_criteria?.current_phase),
          inclusion_criteria_filters: getInclusionCriteriaFilters(cSeq?.sequence_entry_criteria),
          is_changed: !isEqual(iSeq, cSeq),
          steps_delta: stepsDelta,
          steps_are_changed: !isEqual(iSeq.active_steps, cSeq.active_steps),
          branches_are_changed: !isEqual(iSeqBranches, cSeqBranches),
          end_sequence_is_changed: !isEqual(
            iSeq.active_steps[iSeq.active_steps.length - 1],
            cSeq.active_steps[cSeq.active_steps.length - 1],
          ),
          entry_inclusion_is_changed: !isEqual(iSeq?.sequence_entry_criteria, cSeq?.sequence_entry_criteria),
        });
      }
    };
  }, []);

  return (
    <Main>
      {showSequenceCriteriaModal && (
        <SequenceCriteriaModal
          setForceSkipEntry={setForceSkipEntry}
          sequenceId={sequence_id}
          sequenceData={sequence}
          isPaused={!sequence?.is_paused}
        />
      )}
      {showRenameSequenceModal && <RenameSequenceModal />}
      {showSequenceStepModal && (
        <SequenceStepModal sequenceId={sequence_id} stepAction={startStepAction} sequenceData={sequence} />
      )}
      {showSelectAnActionModal && (
        <SelectAnActionModal setStartStepAction={setStartStepAction} sequenceData={sequence} />
      )}
      {showSequenceBranchModal && (
        <SequenceBranchModal
          sequenceId={sequence_id}
          refetchSequence={refetchSequence}
          branchCount={sequence?.active_steps?.filter((ele: any) => !!ele?.conditions?.length).length}
          sequenceData={sequence}
        />
      )}
      {showConfirmDeleteSequenceStep && (
        <ConfirmDeleteStepModal stepId={selectedSequenceStepId} sequenceData={sequence} />
      )}
      {showEditEndSequenceModal && <EditEndSequenceModal sequenceData={sequence} />}

      <PhoenixExpandButton
        expandedState={sequenceNavExpanded}
        moveDirection="right"
        moveDistance={224}
        top={24}
        left={72}
        onClick={() => setSequenceNavExpanded(!sequenceNavExpanded)}
      />
      <SequenceNav expanded={sequenceNavExpanded} />
      <Nav>
        {!sequence?.is_paused && <PhoenixStyledTooltip place="left" id={"sequence-detail"} fontSize={10} padding={8} />}
        <TopOptions>
          <LeftOptions>
            <FlexDiv
              align="center"
              gap={4}
              onClick={() => history.push("/sequences")}
              style={{ cursor: "pointer", marginBottom: "4px" }}
            >
              <PhoenixIcon svg={chevron_left} size={16} hoverColor={theme.PRIMARY500} pointer />
              <AppText variant="primary" fontSize={10} fontWeight={600} style={{ letterSpacing: "1px" }}>
                ALL SEQUENCES
              </AppText>
            </FlexDiv>
            {!loadingSequence || (loadingSequence && skipLoadingCycle) ? (
              <>
                <FlexDiv align="center" gap={8}>
                  <FadeInText fontSize={20} fontWeight={600}>
                    {sequence?.name}
                  </FadeInText>
                  <PhoenixIcon
                    svg={edit}
                    size={16}
                    pointer
                    onClick={() => {
                      setShowRenameSequenceModal(true);
                      setRenameSequenceData({ sequence_id, name: sequence?.name, priority: sequence?.priority });
                    }}
                  />
                </FlexDiv>
                <FadeInText fontSize={12} color={theme.NEUTRAL400}>
                  {`Created by ${sequence?.created_by?.full_name} on ${moment(sequence?.created_at).format(
                    "MM/DD/YYYY",
                  )}`}
                </FadeInText>
              </>
            ) : (
              <FlexDiv direction="column" gap={8}>
                <SkeletonBlock width={180} height={22} borderRadius={4} style={{ marginTop: "4px" }} />
                <SkeletonBlock width={200} height={18} borderRadius={4} />
              </FlexDiv>
            )}
          </LeftOptions>

          <FlexDiv direction="column" align="flex-end" gap={16}>
            {!loadingSequence || (loadingSequence && skipLoadingCycle) ? (
              <>
                <PausedInfo gap={8} align="center">
                  <AppText fontSize={10} fontWeight={400} color={theme.NEUTRAL300}>
                    In order to make changes to a sequence, it must be paused.
                  </AppText>
                  <PhoenixIcon svg={info} size={16} hoverColor={theme.PRIMARY500} color={theme.PRIMARY500} />
                </PausedInfo>
                <StartPauseButton
                  buttonType="secondary"
                  variant={sequence?.is_paused ? "brand" : "brand-outline"}
                  onClick={() => {
                    startOrPauseSequence();
                    setSkipLoadingCycle(true);

                    if (sequence?.is_paused) {
                      MixpanelActions.track("Sequence Started", {
                        steps: sequence?.steps_count,
                        sms_steps: sequence?.sms_action_count,
                        email_steps: sequence?.email_action_count,
                        dial_steps: sequence?.phone_action_count,
                        feedback_steps: sequence?.manager_feedback_count,
                        custom_steps: sequence?.custom_task_action_count,
                        branches: sequence?.active_steps?.filter((ele: any) => !!ele?.conditions?.length).length,
                      });
                    } else {
                      MixpanelActions.track("Sequence Paused");
                    }
                  }}
                  data-for="sequence-detail"
                  data-tip="Pause the entry of leads"
                  disabled={
                    !sequence ||
                    sequence?.active_steps?.length < 1 ||
                    !sequence?.sequence_entry_criteria_id ||
                    loadingStartOrPauseSequence ||
                    loadingSequence
                  }
                >
                  <FlexDiv justify="center" align="center" gap={16}>
                    <PhoenixIcon
                      svg={sequence?.is_paused ? play : pause}
                      size={16}
                      color={sequence?.is_paused ? theme.WHITE_COLOR : theme.PRIMARY500}
                      hoverColor={sequence?.is_paused ? theme.WHITE_COLOR : theme.PRIMARY500}
                      pointer
                    />
                    <AppText
                      fontSize={10}
                      fontWeight={600}
                      color={sequence?.is_paused ? theme.WHITE_COLOR : theme.PRIMARY500}
                      style={{ textTransform: "uppercase", letterSpacing: "1px", lineHeight: "16px" }}
                    >
                      {sequence?.is_paused ? "Start" : "Pause"} Sequence
                    </AppText>
                  </FlexDiv>
                </StartPauseButton>
              </>
            ) : (
              <>
                <SkeletonBlock width={318} height={18} borderRadius={4} />
                <SkeletonBlock width={183} height={38} borderRadius={4} />
              </>
            )}
          </FlexDiv>
        </TopOptions>
        <MetricsContainer>
          <MetricsLeft>
            <StepsContainer>
              <PhoenixIcon svg={steps} size="small" hoverColor={theme.PRIMARY500} />
              <AppText style={{ whiteSpace: "nowrap", lineHeight: "18px" }}>
                <span style={{ fontWeight: 600 }}>{dataStepCount?.fetchSequences?.[0]?.steps_count || 0}</span> Steps
              </AppText>
            </StepsContainer>

            <MetricsContainerLeft>
              <MetricText>
                <PhoenixIcon svg={mail} size="small" variant="neutral" /> {sequence?.email_action_count}
              </MetricText>
              <MetricText>
                <PhoenixIcon svg={chat} size="small" variant="neutral" /> {sequence?.sms_action_count}
              </MetricText>
              <MetricText>
                <PhoenixIcon svg={phone_call} size="small" variant="neutral" /> {sequence?.phone_action_count}
              </MetricText>
              <MetricText>
                <PhoenixIcon svg={info} size="small" variant="neutral" /> {sequence?.manager_feedback_action_count}
              </MetricText>
              <MetricText>
                <PhoenixIcon svg={settings} size="small" variant="neutral" /> {sequence?.custom_task_action_count}
              </MetricText>
              <MetricText>
                <PhoenixIcon svg={log_in} size="small" variant="alert" /> {sequence?.exit_count}
              </MetricText>
            </MetricsContainerLeft>
          </MetricsLeft>

          <MetricsRight>
            <StepsContainer>
              <PhoenixIcon svg={users} size="small" hoverColor={theme.PRIMARY500} />
              <AppText style={{ lineHeight: "18px" }}>Leads</AppText>
            </StepsContainer>

            <MetricsContainerRight>
              <FlexDiv gap={16}>
                <FlexDiv align="center" gap={4}>
                  <Dot color={theme.SUCCESS500} />
                  <AppText style={{ lineHeight: "18px" }}>
                    <span style={{ fontWeight: 600 }}>{sequence?.leads_count}</span> Active
                  </AppText>
                </FlexDiv>
                <FlexDiv align="center" gap={4}>
                  <Dot color={theme.PRIMARY500} />
                  <AppText style={{ lineHeight: "18px" }}>
                    <span style={{ fontWeight: 600 }}>{sequence?.completed_count}</span> Completed
                  </AppText>
                </FlexDiv>
                <FlexDiv align="center" gap={4}>
                  <Dot color={theme.DANGER500} />
                  <AppText style={{ lineHeight: "18px" }}>
                    <span style={{ fontWeight: 600 }}>{sequence?.not_interested_count}</span> Not interested
                  </AppText>
                </FlexDiv>
                <FlexDiv align="center" gap={4}>
                  <Dot color={theme.DATAVIZ_1_400} />
                  <AppText style={{ lineHeight: "18px" }}>
                    <span style={{ fontWeight: 600 }}>{sequence?.conversion_count}</span> Conversion
                  </AppText>
                </FlexDiv>

                <PhoenixAppButton
                  buttonType="secondary"
                  variant="brand-outline"
                  width={120}
                  height={32}
                  centered
                  borderRadius={4}
                  padding="0px"
                  onClick={() => setShowLeadListWindow(true)}
                  disabled={!!loadingSequence || !sequence?.sequence_entry_criteria_id}
                >
                  <AppText
                    fontSize={10}
                    fontWeight={600}
                    color={theme.PRIMARY500}
                    lineHeight={16}
                    letterSpacing={1}
                    uppercase
                  >
                    All Leads
                  </AppText>
                </PhoenixAppButton>
              </FlexDiv>
            </MetricsContainerRight>
          </MetricsRight>
        </MetricsContainer>
      </Nav>

      <Body>
        <>
          {((!loadingSequence && (sequence?.sequence_entry_criteria_id || forceSkipEntry)) ||
            ((sequence?.sequence_entry_criteria_id || forceSkipEntry) && skipLoadingCycle)) && (
            <BlurBanner>
              <ViewText>
                <PhoenixIcon size="small" svg={sequences} hoverColor={theme.PRIMARY500} />
                <AppText variant="primary" fontSize={12} fontWeight={500} style={{ userSelect: "none" }}>
                  Workflow
                </AppText>
              </ViewText>
              {forceSkipEntry && !sequence?.sequence_entry_criteria_id ? (
                <FlexDiv
                  direction="column"
                  align="center"
                  gap={8}
                  style={{ margin: `${!sequence?.active_steps?.length ? "68px" : "24px"} 72px 0px 0px` }}
                >
                  {/* TODO: remove marginRight once individual sequence reporting page is added*/}
                  <FlexDiv align="center" gap={8}>
                    <PhoenixIcon svg={info} variant="danger" size="small" hoverColor={theme.DANGER600} />
                    <FadeInText color={theme.DANGER600}>Missing Criteria</FadeInText>
                  </FlexDiv>
                  <FadeInText color={theme.NEUTRAL300} fontSize={12}>
                    Establish entry and inclusion criteria in order to activate this sequence.
                  </FadeInText>
                  <PhoenixAppButton
                    buttonType="secondary"
                    variant="brand"
                    buttonTextFontSize={10}
                    onClick={() => setShowSequenceCriteriaModal(true)}
                    style={{ letterSpacing: "1px" }}
                  >
                    SET UP CRITERIA
                  </PhoenixAppButton>
                </FlexDiv>
              ) : (
                <EntryInclusionCriteriaButton
                  buttonType="secondary"
                  variant="brand"
                  onClick={() => setShowSequenceCriteriaModal(true)}
                  style={{ marginRight: "72px" }} // TODO: remove marginRight once individual sequence reporting page is added
                  isPaused={sequence?.is_paused}
                >
                  <EditWord
                    fontSize={10}
                    fontWeight={600}
                    variant="white"
                    uppercase
                    lineHeight={16}
                    letterSpacing={1}
                    isPaused={sequence?.is_paused}
                  >
                    Edit
                  </EditWord>
                  <EntryInclusionCriteriaText
                    fontSize={10}
                    fontWeight={600}
                    variant="white"
                    uppercase
                    lineHeight={16}
                    letterSpacing={1}
                    style={{ paddingLeft: sequence?.is_paused && "30px" }}
                    isPaused={sequence?.is_paused}
                  >
                    Entry & Inclusion Criteria
                  </EntryInclusionCriteriaText>
                </EntryInclusionCriteriaButton>
              )}
              <div /> {/* TODO: add metrics button here once individual sequence reporting page is added */}
            </BlurBanner>
          )}
          {!loadingSequence && !sequence?.sequence_entry_criteria_id && !forceSkipEntry ? (
            <SetupContainer>
              <FlexDiv direction="column" gap={8} align="center">
                <AppText color={theme.NEUTRAL300} fontSize={12} fontWeight={500}>
                  Let's get started.
                </AppText>
                <AppText fontSize={16}>Establish entry and inclusion criteria for this sequence.</AppText>
              </FlexDiv>

              <PhoenixAppButton
                buttonType="secondary"
                variant="brand"
                buttonTextFontSize={10}
                onClick={() => setShowSequenceCriteriaModal(true)}
                style={{ letterSpacing: "1px" }}
              >
                SET UP CRITERIA
              </PhoenixAppButton>

              <FlexDiv direction="column" gap={8} align="center">
                <FlexDiv gap={8} align="center" style={{ cursor: "pointer" }} onClick={() => setForceSkipEntry(true)}>
                  <AppText variant="primary" fontSize={12} fontWeight={500} style={{ borderBottom: "none" }}>
                    Skip for Now
                  </AppText>
                  <PhoenixIcon svg={arrow_right} hoverColor={theme.PRIMARY500} size={16} pointer />
                </FlexDiv>
                <AppText
                  fontSize={12}
                  fontWeight={500}
                  color={theme.NEUTRAL300}
                  style={{ width: "375px", textAlign: "center" }}
                >
                  You can set up entry and inclusion criteria later, but it needs to be established before a sequence
                  can be activated.
                </AppText>
              </FlexDiv>
            </SetupContainer>
          ) : !loadingSequence && !sequence?.active_steps?.length ? (
            <FlexDiv
              direction="column"
              align="center"
              style={{ marginTop: forceSkipEntry ? "200px" : "132px", width: "100%" }}
            >
              <FadeInText color={theme.NEUTRAL300} fontSize={12}>
                There are no steps in this sequence.
              </FadeInText>
              <FadeInText fontSize={16} style={{ marginBottom: "40px" }}>
                Select one of the following actions to get started.
              </FadeInText>
              <StepActionsContainer>
                <StepActionButton
                  text="Automated Email"
                  icon={mail}
                  onClick={() => handleShowSequenceStepModal("automatedEmail")}
                />
                <StepActionButton
                  text="Manual Email"
                  icon={mail}
                  onClick={() => handleShowSequenceStepModal("manualEmail")}
                />
                <StepActionButton
                  text="Automated SMS"
                  icon={chat}
                  onClick={() => handleShowSequenceStepModal("automatedSms")}
                />
                <StepActionButton
                  text="Manual SMS"
                  icon={chat}
                  onClick={() => handleShowSequenceStepModal("manualSms")}
                />
                <StepActionButton
                  text="Phone Call"
                  icon={phone_call}
                  onClick={() => handleShowSequenceStepModal("manualCall")}
                />
                <StepActionButton
                  text="Custom Task"
                  icon={settings}
                  onClick={() => handleShowSequenceStepModal("customTask")}
                />
              </StepActionsContainer>
            </FlexDiv>
          ) : !loadingSequence ? (
            <>
              <SequenceCanvas
                initialSteps={sequence?.active_steps}
                active={!sequence?.is_paused}
                sequenceData={sequence}
              />
              {showLeadListWindow && (
                <LeadListWindow
                  onClose={() => setShowLeadListWindow(false)}
                  draggable={true}
                  sequenceId={sequence?.id || ""}
                  metrics={{
                    active_count: sequence?.leads_count || 0,
                    completed_count: sequence?.completed_count || 0,
                    not_interested_count: sequence?.not_interested_count || 0,
                    conversion_count: sequence?.conversion_count || 0,
                    all_leads_count: sequence?.all_leads_count || 0,
                    system_exit_count: sequence?.exit_count || 0,
                  }}
                />
              )}
            </>
          ) : (
            <FlexDiv align="center" style={{ marginTop: "96px", width: "100%" }}>
              <Loading />
            </FlexDiv>
          )}
        </>
      </Body>
    </Main>
  );
};

const Main = styled.div`
  border: 1px solid ${theme.NEUTRAL100};
  background: ${theme.WHITE_COLOR};
  margin: 24px;
  border-radius: 8px;
  overflow: hidden;
`;

const TopOptions = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  height: fit-content;
  padding: 24px 24px 16px 48px;
`;

const LeftOptions = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 4px;
`;

const Body = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;

  width: 100%;
  height: fit-content;
  max-height: 75vh;
  min-height: 75vh;

  margin: 0px;

  background-color: ${theme.PRIMARY50};
  border-top: 1px solid ${theme.NEUTRAL200};
  overflow-y: auto;

  & > * {
    animation: ${theme.fadeIn} 0.15s ease;
  }
`;

const Nav = styled.div`
  display: flex;
  flex-direction: column;
`;

const MetricsContainer = styled.div`
  display: flex;
  border-top: 1px solid ${theme.NEUTRAL200};
  width: 100%;
  height: 56px;
  background-color: ${theme.WHITE_COLOR};
`;

const MetricsLeft = styled.div`
  display: flex;
  align-items: center;

  width: fit-content;

  padding: 8px 0px 8px 16px;
  border-right: 1px solid ${theme.NEUTRAL200};
`;

const MetricsRight = styled.div`
  display: flex;
  align-items: center;

  width: 100%;

  padding: 8px 0px 8px 16px;
`;

const StepsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  height: 40px;
  padding-right: 24px;

  border-right: 1px solid ${theme.NEUTRAL200};
`;

const MetricsContainerLeft = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;

  padding: 0px 24px;
`;
const MetricsContainerRight = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100%;

  padding: 0px 24px;
`;

const MetricText = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;

  min-width: 29px;

  font-weight: 600;
  line-height: 18px;
  font-size: 12px;
`;

interface DotProps {
  color: string;
}
export const Dot = styled.div<DotProps>`
  min-width: 8px;
  min-height: 8px;
  border-radius: 8px;
  background-color: ${(props) => props.color};
`;

const ViewText = styled.div`
  display: flex;
  gap: 4px;
`;

const SetupContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  width: 100%;
  height: 214px;
  margin-top: 80px;
`;

const BlurBanner = styled.div`
  position: absolute;
  z-index: 10;

  display: flex;
  justify-content: space-between;

  width: 100%;
  padding: 24px 24px 16px;

  backdrop-filter: blur(3px);
`;

const FadeInText = styled(AppText)`
  animation: ${theme.textFadeIn} 0.25s ease forwards;
`;

const StepActionsContainer = styled.div`
  display: grid;
  grid-template-rows: repeat(3, 1fr);
  grid-auto-flow: column;
  column-gap: 8px;
  row-gap: 8px;
`;

const PausedInfo = styled(FlexDiv)`
  animation: ${theme.fadeIn} 0.25s ease forwards;
`;

const StartPauseButton = styled(PhoenixAppButton)`
  animation: ${theme.fadeIn} 0.25s ease forwards;
`;

interface EIButtonProps {
  isPaused: boolean;
}

const EditWord = styled(AppText)<EIButtonProps>`
  position: absolute;
  left: 23px;
  top: ${(props) => (!props.isPaused ? "0px" : "28.5%")};

  opacity: ${(props) => (props.isPaused ? 1 : 0)};

  transition: opacity 0.3s ease-in-out, top 0.3s ease-in-out;
`;

const EntryInclusionCriteriaText = styled(AppText)<EIButtonProps>`
  transition: padding-left 0.2s ease-in-out;
  transition-delay: ${(props) => !props.isPaused && "0.3s"};
`;

const EntryInclusionCriteriaButton = styled(PhoenixAppButton)<EIButtonProps>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  width: ${(props) => (props.isPaused ? "255px" : "225px")};
  padding: 0;

  transition: width 0.2s ease-in-out;
  transition-delay: ${(props) => !props.isPaused && "0.3s"};
`;
