import * as React from "react";
import { useContext, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { theme } from "../../../utils/theme";
import { AppErrorText, AppText, FlexDiv, Loading, NewAppButton, SkeletonBlock } from "../../UI";

import {
  AlternateContactComponent,
  CallDispositionsComponentV2,
  CreateScheduledEventComponentV3,
  CustomMakeSale,
  DispositionOption,
  OTFCallMeetingButton,
  TransferComponentV3,
} from "./";

import { gql, useQuery } from "@apollo/client";
import { useFlags } from "launchdarkly-react-client-sdk";
import { currentCallState, loggedInUser } from "../../../apollo/cache";
import { SHOW_NOCONTACT_BUTTON_SHORTCUT } from "../../../apollo/query";
import { CallContext, ModalContext } from "../../../context";
import { alert_circle, flag, phone_forwarded, xIcon } from "../../../images/NewDesign";
import { MixpanelActions } from "../../../services/mixpanel";
import { IAvailableAction, IDispositionOption } from "../../../types";
import { formatSpokeWith } from "../../../utils/format";
import { appToast } from "../../../utils/toast";
import { GenericError } from "../../Smart";
import { PhoenixAppButton, PhoenixIcon, PhoenixInput, PhoenixRadio } from "../../UI/Phoenix";
import { CHECK_INTEGRATION_STATUS } from "../Onboarding/StepIntegrationV2";
import { ClosingScript, ConfirmLeadDetails, MakeSaleComponentV2, NoContactNoMessageComponent } from "./";
import { ConfirmDemoRequiredFields } from "./ConfirmDemoRequiredFields";
import { ConfirmLeadDetailsCustom } from "./ConfirmLeadDetailsCustom";
import { CreatePandaDocComponent } from "./CreatePandaDoc";
import { ExternalTransferStateComponent } from "./ExternalTransfer";
import SuggestedDispositions from "./SuggestedDispositions";
import { getLocalStorage } from "src/utils/misc";

export interface CreateInstantMeetingResponse {
  createInstantMeeting: {
    google_space_sellfire_id: string;
    meeting_url: string;
    meeting_code?: string;
  };
}

interface FetchAvailableActionsExpectedResponse {
  fetchAvailableActions: IAvailableAction[];
}

const FETCH_AVAILABLE_ACTIONS = gql`
  query FetchAvailableActions($lead_id: String!, $person_spoke_to: CALLRESULT) {
    fetchAvailableActions(lead_id: $lead_id, person_spoke_to: $person_spoke_to) {
      id
      label
      event_type
      description
    }
  }
`;

interface IFetchDispositionOptionsExpectedResponse {
  fetchDispositionOptions: IDispositionOption[];
}
const FETCH_DISPOSITION_OPTIONS = gql`
  query fetchDispositionOptions($person_spoke_to: CALLRESULT, $lead_id: String!, $associated_action: String) {
    fetchDispositionOptions(
      person_spoke_to: $person_spoke_to
      lead_id: $lead_id
      associated_action: $associated_action
    ) {
      id
      label
      person_spoke_to
      phase
      next_phase
      next_phase_label
      resting_day
      description
      disposition_reasons {
        id
        reason
        is_other
      }
    }
  }
`;

const FETCH_ORG_LIVE_TRANSFER_SETTINGS = gql`
  query FetchOrgLiveTransferSetting {
    fetchOrgLiveTransferSetting {
      ae_transfer_to_ae
      ae_transfer_to_sdr
    }
  }
`;

interface FetchPersonSpokeToOptionsExpectedResponse {
  fetchPersonSpokeToOptions: string[];
}
const FETCH_PERSON_SPOKE_TO = gql`
  query fetchPersonSpokeToOptions($lead_id: String!, $associated_action: String) {
    fetchPersonSpokeToOptions(lead_id: $lead_id, associated_action: $associated_action)
  }
`;

interface CallResultProps {
  lead_primary_email?: string;
  lead_full_name: string;
  isInHeldPhase: boolean;
  scheduled_event_behavior?: string | undefined | null;
  logNonCallTitle?: string;
}

const CallResultV2: React.FC<CallResultProps> = ({
  lead_primary_email,
  lead_full_name,
  isInHeldPhase = false,
  scheduled_event_behavior,
  logNonCallTitle = "",
}) => {
  const [isPandaDocEnabled, setIsPandaDocEnabled] = useState<boolean>(false);
  /**
   * Used for SDR's scheduling demo for someone else
   */
  const [destRepId, setDestRepId] = useState<string>("");
  const [transferDemoSubOptions, setTransferDemoSubOptions] = useState<string>("");
  const [dispositionType, setDispositionType] = useState<string>("");

  /**
   * Used to store the loading state of NoContactNoMessage component where disposition is logged
   */
  const [isLoadingDisposition, setIsLoadingDisposition] = useState<boolean>(false);

  const [transferSessionsCount, setTransferSessionsCount] = useState<number>(0);
  const {
    intentId,
    intentReplacementId,
    setRecordingButtonDisabled,
    personSpokeTo,
    setPersonSpokeTo,

    callOptionStackPeek,
    callOptionStackPop,
    callOptionStackPush,
    resetCallOptionStack,
    associatedAction,
    setAssociatedAction,
    callLeadId,
    transferState,
    setTransferState,
    callCameFromTransfer,
    cancelTransferGlobal,
    callOptionStack,
    persistCallNotes,
    timeOnCall,
    isNonCallActivity,
    isZoomCall,
    isGoogleMeetCall,
    callState,
    revertCallState,
    goToCall,
    externalRepCallSid,
    kickExternalTransferNumber,
    isEnteringRequiredFields,
  } = useContext(CallContext);

  const { logDispositionReason, zoomIntegration, googleMeetIntegration, suggestedDispositions } = useFlags();

  const { setShowCallFeedbackModal, setShowTwilioFeedbackModal } = useContext(ModalContext);

  const [selectedDisposition, setSelectedDisposition] = useState<string>("");
  const [selectedDispositionReason, setSelectedDispositionReason] = useState<string>("");

  useEffect(() => {
    return () => {
      const persistPreviousCallDispositionFlow = getLocalStorage("persistPreviousCallDispositionFlow", false);
      if (!persistPreviousCallDispositionFlow) {
        setAssociatedAction(undefined);
        resetCallOptionStack();
        setTransferState("Idle");
      }
    };
  }, []);

  const zoomIsEnabledOnUser = loggedInUser().checkUserCalendarStatus?.zoom_status === "active" ?? false;

  const googleMeetIsEnabledOnUser = loggedInUser().checkUserCalendarStatus?.google_status === "active" ?? false;

  const {
    data: availableActionsData,
    loading: loadingActions,
    error: errorActions,
    refetch: actionsRefetch,
  } = useQuery<FetchAvailableActionsExpectedResponse>(FETCH_AVAILABLE_ACTIONS, {
    skip: !personSpokeTo,
    variables: {
      lead_id: callLeadId,
      person_spoke_to: personSpokeTo,
    },
    fetchPolicy: "no-cache",
    onError({ message, name }) {
      console.log(`Error in ${name}: `, message);
    },
  });

  const { data: dataStatus, loading: loadingPDStatus, error: errorPDStatus } = useQuery(CHECK_INTEGRATION_STATUS, {
    fetchPolicy: "network-only",
    onCompleted() {
      if (dataStatus?.checkIntegrationStatus?.PandaDoc && loggedInUser().panda_doc_access) {
        setIsPandaDocEnabled(true);
      }
    },
  });

  const {
    data: dataOrgLiveTransferSettings,
    loading: loadingDataOrgLiveTransferSettings,
    error: errorOrgLiveTransferSetting,
  } = useQuery(FETCH_ORG_LIVE_TRANSFER_SETTINGS, {
    fetchPolicy: "network-only",
    onError({ message, name }) {
      console.log(`Error in ${name}: `, message);
      appToast("Error fetching live transfer settings");
    },
  });

  const {
    data: dataCallResult,
    loading: loadingCallResult,
    error: errorCallResult,
  } = useQuery<FetchPersonSpokeToOptionsExpectedResponse>(FETCH_PERSON_SPOKE_TO, {
    variables: {
      lead_id: callLeadId,
    },
    fetchPolicy: "no-cache",
  });

  const { data: showNoContactButtonShortcut } = useQuery(SHOW_NOCONTACT_BUTTON_SHORTCUT);

  const {
    data: dataDispositions,
    loading: loadingDispositions,
    error: errorDispositions,
    refetch: refetchDispositions,
  } = useQuery<IFetchDispositionOptionsExpectedResponse>(FETCH_DISPOSITION_OPTIONS, {
    fetchPolicy: "no-cache",
    skip: !personSpokeTo,
    variables: {
      person_spoke_to: !!personSpokeTo ? personSpokeTo : null,
      lead_id: callLeadId,
      associated_action: associatedAction,
    },
    onError({ message, name }) {
      console.log(`Error in ${name}: `, message);
    },
  });

  const dispositionRequiresReason = useMemo(() => {
    const currentDisposition = dataDispositions?.fetchDispositionOptions?.find(
      (option: IDispositionOption) => option.id === selectedDisposition,
    );
    return !!currentDisposition?.disposition_reasons?.length;
  }, [dataDispositions, selectedDisposition]);

  const persistPreviousCallDispositionFlow = localStorage.getItem("persistPreviousCallDispositionFlow") === "true";

  if (
    (loadingActions ||
      loadingPDStatus ||
      loadingDataOrgLiveTransferSettings ||
      loadingCallResult ||
      loadingDispositions) &&
    !persistPreviousCallDispositionFlow
  )
    return <SkeletonBlock height={"100%"} width={"100%"} borderRadius={6} />;
  if (
    errorActions ||
    errorPDStatus ||
    errorOrgLiveTransferSetting ||
    errorCallResult ||
    errorDispositions
    // errorReview
  )
    return <AppErrorText>Error loading call result options.</AppErrorText>;

  const callSpokeWith = () => {
    return (
      <>
        <HeaderContainer>
          {!!logNonCallTitle && (
            <>
              <AppText fontSize={16} fontWeight={600} style={{ margin: "0px auto 0px auto" }}>
                Log Non-Call Activity for:
              </AppText>
              <AppText fontSize={16} fontWeight={600} style={{ margin: "4px auto 0px auto" }}>
                {logNonCallTitle}
              </AppText>
            </>
          )}
          <AppText fontSize={16} style={{ margin: "27px auto 16px auto" }}>
            Who did you {isNonCallActivity ? "communicate with" : "speak with"}?
          </AppText>
        </HeaderContainer>
        <ButtonsDiv id="call-flow-spoke-to-list">
          <>
            {dataCallResult?.fetchPersonSpokeToOptions?.map((item: string) => (
              <NewAppButton
                size={"lg"}
                variant={"secondary"}
                key={item}
                onClick={() => {
                  setPersonSpokeTo(item);
                  callOptionStackPush("call-completed");
                }}
              >
                {formatSpokeWith(item)}
              </NewAppButton>
            ))}
            {suggestedDispositions && (
              <SuggestedDispositions
                setTransferDemoSubOptions={setTransferDemoSubOptions}
                isNonCallActivity={isNonCallActivity ?? false}
              />
            )}
            {!suggestedDispositions &&
              !isNonCallActivity &&
              !currentCallState().onCall &&
              showNoContactButtonShortcut?.showNoContactButtonShortcut &&
              (!!timeOnCall || timeOnCall === 0) &&
              timeOnCall <= 25 && (
                <NoContactNoMessageComponent
                  setTransferDemoSubOptions={setTransferDemoSubOptions}
                  isLoadingDisposition={isLoadingDisposition}
                  setIsLoadingDisposition={setIsLoadingDisposition}
                />
              )}
          </>
        </ButtonsDiv>
      </>
    );
  };

  const chooseAction = () => {
    const AEsCanTransferDemos =
      dataOrgLiveTransferSettings?.fetchOrgLiveTransferSetting?.ae_transfer_to_ae ||
      dataOrgLiveTransferSettings?.fetchOrgLiveTransferSetting?.ae_transfer_to_sdr;

    return (
      <>
        <HeaderContainer>
          <AppText style={{ fontSize: "16px", margin: "27px auto 16px auto" }}>
            What do you want to do next with this lead?
          </AppText>
        </HeaderContainer>
        <ButtonsDiv id="call-flow-option-list">
          {!isNonCallActivity &&
            !isZoomCall &&
            !isGoogleMeetCall &&
            loggedInUser().allow_external_transfer &&
            loggedInUser().organization?.allow_external_transfer && (
              <NewAppButton
                size={"lg"}
                variant={"secondary"}
                onClick={() => {
                  persistCallNotes();
                  callOptionStackPush("external-transfer");
                  setAssociatedAction(undefined);
                  setTransferDemoSubOptions("");
                }}
                disabled={
                  !callLeadId || personSpokeTo === "NoContact" || !currentCallState().onCall || !!callCameFromTransfer
                }
              >
                Transfer To External Number
              </NewAppButton>
            )}
          {!isNonCallActivity &&
            !isZoomCall &&
            !isGoogleMeetCall &&
            (AEsCanTransferDemos || loggedInUser().role === "SDR") && (
              <NewAppButton
                size={"lg"}
                variant={"secondary"}
                onClick={() => {
                  persistCallNotes();
                  setTransferSessionsCount(transferSessionsCount + 1);
                  callOptionStackPush("transfer demo");

                  setAssociatedAction("transfer demo");
                  setTransferDemoSubOptions("");
                }}
                disabled={
                  !callLeadId || personSpokeTo === "NoContact" || !currentCallState().onCall || !!callCameFromTransfer
                }
              >
                Transfer Demo
              </NewAppButton>
            )}
          {loggedInUser().role === "AE" && (
            <NewAppButton
              size={"lg"}
              variant={"secondary"}
              onClick={() => {
                callOptionStackPush("custom make sale");
                setTransferDemoSubOptions("");
                setRecordingButtonDisabled(false);
              }}
              disabled={!callLeadId || personSpokeTo === "NDM" || personSpokeTo === "NoContact"}
            >
              Make Sale
            </NewAppButton>
          )}

          <NewAppButton
            size={"lg"}
            variant={"secondary"}
            onClick={() => {
              callOptionStackPush("schedule-event");
              setTransferDemoSubOptions("");
              setAssociatedAction(undefined);
              setRecordingButtonDisabled(false);
            }}
            disabled={!callLeadId}
          >
            Schedule Next Event
          </NewAppButton>

          <NewAppButton
            size={"lg"}
            variant={"secondary"}
            onClick={() => {
              callOptionStackPush("log-other");

              setTransferDemoSubOptions("");
              setAssociatedAction(undefined);
              setRecordingButtonDisabled(false);
            }}
            // disableButton={!lead_id}
            disabled={!callLeadId}
          >
            Log Other {isNonCallActivity ? "Activity" : " Call Result"}
          </NewAppButton>
        </ButtonsDiv>
      </>
    );
  };
  const scheduleNextEvent = () => {
    return (
      <>
        {!loadingActions && !!errorActions ? (
          <GenericError error="Error Loading Available Actions" refetch={() => actionsRefetch()} />
        ) : (
          <>
            <HeaderContainer>
              <AppText style={{ fontSize: "16px", margin: "27px auto 16px auto" }}>Schedule Event</AppText>
            </HeaderContainer>
            <ButtonsDiv id="schedule-event-option-container">
              {availableActionsData?.fetchAvailableActions?.map((action: IAvailableAction) => (
                // action !== "Sale" &&
                <NewAppButton
                  key={action?.id}
                  variant={"secondary"}
                  height={90}
                  onClick={() => {
                    // create scheduled event flow (auto vs manual is handled in the steps for that flow)
                    callOptionStackPush("log-result");

                    setTransferDemoSubOptions("");
                    setDispositionType(action.label);
                    setAssociatedAction(action.label);
                  }}
                  disabled={!callLeadId}
                >
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <div>
                      <AppText>{action.label}</AppText>
                    </div>
                    <div>
                      <AppText style={{ color: `${theme.BLACK_COLOR}`, textAlign: "left", fontSize: 10 }}>
                        {action?.description}
                      </AppText>
                    </div>
                  </div>
                </NewAppButton>
              ))}
            </ButtonsDiv>
          </>
        )}
      </>
    );
  };

  const confirmCallResult = () => {
    return (
      <FlexDiv direction="column" align="center" justify="space-between" width="100%" height="100%">
        <div style={{ width: "100%" }}>
          <HeaderContainer>
            <AppText fontSize={16}>Confirm {isNonCallActivity ? "" : "Call"} Result</AppText>
            <AppText fontSize={14}>What was the result of your {isNonCallActivity ? "activity?" : "call?"}</AppText>
          </HeaderContainer>
          <ButtonsDiv style={{ position: "relative" }} id="confirm-call-result-container">
            {loadingDispositions ? (
              <Loading />
            ) : !!dataDispositions && dataDispositions.fetchDispositionOptions.length > 0 ? (
              dataDispositions.fetchDispositionOptions?.map((item: IDispositionOption) => {
                return (
                  <FlexDiv direction="column" gap={8} width={"100%"}>
                    <DispositionOption
                      item={item}
                      selected={selectedDisposition === item.id}
                      onClick={() => {
                        setSelectedDisposition(item.id);
                        setSelectedDispositionReason("");
                      }}
                    />

                    {logDispositionReason && selectedDisposition === item.id && dispositionRequiresReason && (
                      <LeadDispositionReason
                        disposition={item}
                        selectedDispositionReason={selectedDispositionReason}
                        setSelectedDispositionReason={setSelectedDispositionReason}
                      />
                    )}
                  </FlexDiv>
                );
              })
            ) : (
              <AppText>No Results with this combination of options found</AppText>
            )}
          </ButtonsDiv>
        </div>
        <ContinueButtonDiv>
          <PhoenixAppButton
            disabled={
              !selectedDisposition || (logDispositionReason && dispositionRequiresReason && !selectedDispositionReason)
            }
            buttonType="secondary"
            onClick={() => callOptionStackPush(dispositionType)}
          >
            Continue
          </PhoenixAppButton>
        </ContinueButtonDiv>
      </FlexDiv>
    );
  };

  console.log("call dispo route: ", callOptionStackPeek());

  const scheduledEventWarningMessage = (scheduled_event_behavior: string | undefined | null) => {
    switch (scheduled_event_behavior) {
      case "Neutral":
        return "A suggested action from a sequence exists on the lead and may be presented to you prior to this event.";
      case "Replacement":
        return "Scheduling this event will replace the next suggested sequence action.";
      case "Exit":
        return "Scheduling this event will remove this lead from the active sequence.";
      default:
        return undefined;
    }
  };

  return (
    <CallSideBar>
      {!["custom make sale", "confirm-demo-required-fields"].includes(callOptionStackPeek()) && (
        <BackButtonDiv>
          {!["spoke-with", "alternate-contact"].includes(callOptionStackPeek()) &&
          !["Success", "Fail", "Rejected", "Cancelled"].includes(transferState) ? (
            <PhoenixAppButton
              variant="brand-outline"
              buttonType="ghost-large"
              onClick={() => {
                // cancel any pending transfers
                if (transferState === "InProgress") {
                  appToast("canceling transfer...");
                  cancelTransferGlobal();
                }

                // if the 2nd to last was transfer demo, then we need to pop twice
                if (callOptionStack[callOptionStack.length - 2] === "transfer demo") {
                  callOptionStackPop(2);
                } else {
                  callOptionStackPop();
                }

                if (callOptionStackPeek() === "spoke-with") {
                  setPersonSpokeTo("");
                }
                if (callOptionStackPeek() === "call-completed") {
                  setAssociatedAction(undefined);
                }
                if (callOptionStackPeek() === "external-transfer") {
                  kickExternalTransferNumber();
                  cancelTransferGlobal(externalRepCallSid);
                  callOptionStackPop();
                }
              }}
              disabled={callOptionStackPeek() === "transfer demo" && transferState !== "Idle"}
              style={callOptionStackPeek() === "transfer demo" && transferState !== "Idle" ? { opacity: 0.3 } : {}}
            >
              <AppText>Back</AppText>
            </PhoenixAppButton>
          ) : (
            <div />
          )}
          {isNonCallActivity && <PhoenixIcon onClick={revertCallState} svg={xIcon} variant="brand" size={24} />}
        </BackButtonDiv>
      )}
      <ScrollableDiv>
        {!!callLeadId && (
          <>
            {callOptionStackPeek() === "spoke-with" && callSpokeWith()}
            {callOptionStackPeek() === "call-completed" && chooseAction()}
            {callOptionStackPeek() === "custom make sale" && (
              <CustomMakeSale leadID={callLeadId} isPandaDocEnabled={isPandaDocEnabled} />
            )}
            {callOptionStackPeek() === "confirm-details" && (
              <ConfirmLeadDetails setCallOptions={callOptionStackPush} leadID={callLeadId} />
            )}
            {callOptionStackPeek() === "confirm-details-custom" && (
              <ConfirmLeadDetailsCustom setCallOptions={callOptionStackPush} leadID={callLeadId} />
            )}
            {callOptionStackPeek() === "make sale" && (
              <MakeSaleComponentV2
                intent_id={intentId}
                setCallOptions={callOptionStackPush}
                callOptions={callOptionStackPeek}
                lead_id={callLeadId}
                isPandaDocEnabled={isPandaDocEnabled}
              />
            )}
            {callOptionStackPeek() === "closing-script" && (
              <ClosingScript setCallOptions={callOptionStackPush} isPandaDocEnabled={isPandaDocEnabled} />
            )}
            {callOptionStackPeek() === "create-pandadoc" && (
              <CreatePandaDocComponent setCallOptions={callOptionStackPush} />
            )}
            {callOptionStackPeek() === "transfer demo" && (
              <>
                <TransferComponentV3
                  chooseRep={(rep_id: string) => {
                    setDestRepId(rep_id);
                    callOptionStackPush(dispositionType);
                  }}
                  transferSessionsCount={transferSessionsCount}
                />
              </>
            )}
            {callOptionStackPeek() === "external-transfer" && <ExternalTransferStateComponent />}
            {callOptionStackPeek() === "schedule-event" && scheduleNextEvent()}
            {callOptionStackPeek() === "log-result" && confirmCallResult()}
            {/* scheduling an event */}
            {availableActionsData?.fetchAvailableActions?.map((a: any) => a.label).includes(callOptionStackPeek()) && (
              <>
                <CreateScheduledEventComponentV3
                  user_id={destRepId}
                  eventType={callOptionStackPeek()}
                  lead_id={callLeadId ?? ""}
                  intent_id={intentId}
                  dispositionType={dispositionType}
                  lead_primary_email={lead_primary_email}
                  backToActionButtons={() => {
                    callOptionStackPop();
                    setDispositionType("");
                  }}
                  lead_full_name={lead_full_name}
                  disableNoContectForDemo={!callOptionStackPeek().toLowerCase().includes("callback") || !isInHeldPhase}
                  selectedDisposition={selectedDisposition}
                  selectedDispositionReason={selectedDispositionReason}
                  fromNonCallActivity={isNonCallActivity}
                  dispositionRequiresReason={dispositionRequiresReason}
                  startDemoRequiredFields={() => {
                    callOptionStackPush("confirm-demo-required-fields");
                  }}
                  revertDemoRequiredFields={() => {
                    callOptionStackPop();
                  }}
                />
              </>
            )}
            {callOptionStackPeek() === "confirm-demo-required-fields" && (
              <ConfirmDemoRequiredFields leadID={callLeadId} />
            )}
            {callOptionStackPeek() === "schedule-event" &&
              !!scheduled_event_behavior &&
              !(scheduled_event_behavior === "Replacement" && intentReplacementId) && (
                <SequenceSuggestedActionWarning>
                  <PhoenixIcon svg={alert_circle} color={theme.WARNING500} hoverColor={theme.WARNING500} size={16} />
                  {scheduledEventWarningMessage(scheduled_event_behavior)}
                </SequenceSuggestedActionWarning>
              )}
            {callOptionStackPeek() === "log-other" && (
              <CallDispositionsComponentV2
                lead_id={callLeadId}
                intent_id={intentId}
                transferDemoOptions={personSpokeTo}
                transferDemoSubOptions={transferDemoSubOptions}
                setTransferDemoSubOptions={setTransferDemoSubOptions}
                fromNonCallActivity={isNonCallActivity}
              />
            )}
            {callOptionStackPeek() === "alternate-contact" && (
              <AlternateContactComponent
                lead_id={callLeadId}
                intent_id={intentId}
                transferDemoOptions={personSpokeTo}
                transferDemoSubOptions={transferDemoSubOptions}
                setTransferDemoSubOptions={setTransferDemoSubOptions}
              />
            )}
          </>
        )}
      </ScrollableDiv>

      {!isNonCallActivity && (
        <FeedbackDiv>
          {((zoomIntegration && zoomIsEnabledOnUser) || (googleMeetIntegration && googleMeetIsEnabledOnUser)) && (
            <OTFCallMeetingButton leadId={callLeadId ?? ""} disabled={!currentCallState()?.onCall} />
          )}
          <FeedbackButton
            onClick={() => {
              setShowCallFeedbackModal(true);
              MixpanelActions.track("Call review requested");
            }}
          >
            <PhoenixIcon
              pointer
              svg={phone_forwarded}
              color={theme.PRIMARY500}
              size={24}
              hoverColor={theme.PRIMARY500}
            />
          </FeedbackButton>
          <FeedbackButton
            onClick={() => {
              setShowTwilioFeedbackModal(true);
              MixpanelActions.track("Twilio feedback requested");
            }}
          >
            <PhoenixIcon pointer svg={flag} color={theme.PRIMARY500} size={24} hoverColor={theme.PRIMARY500} />
          </FeedbackButton>
        </FeedbackDiv>
      )}
    </CallSideBar>
  );
};

export const LeadDispositionReason = ({
  disposition,
  selectedDispositionReason,
  setSelectedDispositionReason,
}: {
  disposition: IDispositionOption;
  selectedDispositionReason: string;
  setSelectedDispositionReason: (reason: string) => void;
}) => {
  const SORTED_REASONS = disposition.disposition_reasons?.sort((a, b) => Number(a.is_other) - Number(b.is_other));

  const [customDispositionReason, setCustomDispositionReason] = useState(false);

  const formatLabel = (disposition: string) => {
    const formatedLabel = disposition.toLowerCase();
    if (formatedLabel.includes("interested")) {
      return "not interested";
    }
    if (formatedLabel.includes("qualified")) {
      return "not qualified";
    }
    return "not interested";
  };

  const label = formatLabel(disposition.label);

  return (
    <FlexDiv
      gap={0}
      direction="column"
      padding="16px"
      width={"100%"}
      style={{
        borderRadius: 8,
        background: theme.fill.brand.secondary,
      }}
    >
      <FlexDiv gap={4} direction="column">
        <AppText fontSize={12} fontWeight={500}>
          Why was the lead {label}?
        </AppText>
        <AppText fontSize={10} fontWeight={400} color={theme.text.neutral.tertiary}>
          Select the one reason that best describes it.
        </AppText>
        <FlexDiv height={24} />
        <FlexDiv
          direction="column"
          gap={16}
          style={{
            marginBottom: 8,
          }}
        >
          {SORTED_REASONS?.map((reason) => {
            const currentSelectedReasonIsOther = reason.is_other && customDispositionReason;
            const currentReasonIsSelected = selectedDispositionReason === reason.reason;

            return (
              <>
                <FlexDiv
                  key={reason.reason}
                  direction="row"
                  gap={8}
                  style={{
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    const isOtherReason = reason.is_other;
                    setCustomDispositionReason(isOtherReason);
                    setSelectedDispositionReason(isOtherReason ? "" : reason.reason);
                  }}
                >
                  <PhoenixRadio selected={currentSelectedReasonIsOther || currentReasonIsSelected} />
                  <AppText fontSize={12} fontWeight={500}>
                    {reason.reason}
                  </AppText>
                </FlexDiv>

                {currentSelectedReasonIsOther ? (
                  <PhoenixInput
                    disabled={!customDispositionReason}
                    value={!customDispositionReason ? "" : selectedDispositionReason}
                    onChange={(e: any) => {
                      setSelectedDispositionReason(e.target.value);
                    }}
                    placeholder="Description"
                    displayNoContextText
                  />
                ) : null}
              </>
            );
          })}
        </FlexDiv>
      </FlexDiv>
    </FlexDiv>
  );
};

const ContinueButtonDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px;
  width: 100%;
`;

const ButtonsDiv = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 16px;

  /* height: 100%; */
`;

const BackButtonDiv = styled.div`
  min-height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ScrollableDiv = styled.div`
  flex-grow: 2;
  position: relative;
  overflow-y: auto;
`;

const FeedbackDiv = styled.div`
  width: 100%;
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-self: flex-end;
  gap: 16px;
`;

const CallSideBar = styled.div`
  width: 100%;

  height: 100%;
  padding: 0px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  gap: 16px;
`;

const FeedbackButton = styled.div`
  border: 1px solid ${theme.PRIMARY500};
  border-radius: 8px;
  display: "flex";
  justify-content: "center";
  padding: 8px;
  cursor: pointer;

  &:hover {
    background-color: ${theme.PRIMARY100};
  }

  transition: background-color 0.15s ease-in-out;
`;

const HeaderContainer = styled.div`
  width: 100%;
  max-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: auto;
  margin-bottom: 15px;
  margin-top: 40px;
`;

const SequenceSuggestedActionWarning = styled.div`
  display: flex;
  gap: 8px;

  margin-top: 16px;
  padding: 16px;

  background-color: ${theme.WARNING100};
  border: 1px solid ${theme.WARNING500};
  border-radius: 8px;

  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
`;

export { CallResultV2 };
