import React, { useEffect } from "react";
import { Switch, Route, BrowserRouter as Router, Redirect } from "react-router-dom";
import PublicRoute from "./PublicRoute";
import PrivateRoute from "./PrivateRoute";
import { Login, ForgotPassword, ResetPassword } from "../Components/Segments/";
import {
  Onboarding,
  Settings,
  SystemConfiguration,
  SystemView,
  Dashboard,
  NotificationsPage,
  EventManager,
  ScheduleManager,
  CallReport,
  ManagerGridPage,
  CallLibraryPage,
  LeadDetailV3,
  PhoneNumbers,
  SequencesPage,
  OPSIQFormsPage,
  SequenceDetail,
  SequenceSettings,
  SequenceReporting,
  MapSalesPhases,
  OPSIQFormDetail,
  CallLibraryPageV2,
  DashboardAI,
} from "../Components/Pages/";
import withRouter from "./RouterWrapper";
import {
  CallProvider,
  CartProvider,
  ModalProvider,
  LeadCardProvider,
  SelfSourceLeadProvider,
  LeadFilterProvider,
  FilterContext,
  ScheduleProvider,
  FavoritedLeadFilterProvider,
  UserStatusProvider,
} from "../context";
import { ShareViewContainer } from "../Components/Segments/DashboardSegments/ShareViewContainer";
import NoAuthRoute from "./NoAuthRoute";
import { useFlags } from "launchdarkly-react-client-sdk";
import { DataWrapper } from "../Components/Smart";
import NotFoundPage from "../Components/Pages/NotFoundPage";
import { checkForDangerousURL } from "../utils/misc";
import { CustomObjectListView } from "../Components/Pages/LeadDetail";
import CustomObjectDetailView from "../Components/Pages/LeadDetail/CustomObjectDetailView";
import { CallReportV2 } from "../Components/Pages/CallReportsV2";
import { HubSpotLeadRedirect } from "src/Components/Pages/LeadDetail/HubspotLeadRedirect";
import NewDeployVersionBanner from "src/Components/Smart/NewDeployVersionBanner";

const AppRouter = () => {
  const { aiPhase1, callLibraryV2 } = useFlags();

  useEffect(() => {
    if (checkForDangerousURL(window.location.href)) {
      console.log("possibly dangerous url detected, redirecting to 404 page");
      window.location.href = "/404";
    }
  }, [window.location.href]);

  return (
    <Router>
      <DataWrapper>
        <UserStatusProvider>
          <ModalProvider>
            <ScheduleProvider>
              <SelfSourceLeadProvider>
                <CallProvider>
                  <CartProvider>
                    <LeadFilterProvider>
                      <LeadCardProvider>
                        <FavoritedLeadFilterProvider>
                          <FilterContext>
                            <>
                              <NewDeployVersionBanner />
                              <Switch>
                                <Route path="/404" exact component={NotFoundPage} status={404} />
                                <NoAuthRoute path="/share-view/:org_id/:view_id" exact component={ShareViewContainer} />
                                <PublicRoute path="/new-password/" exact component={withRouter(ResetPassword)} />
                                <PrivateRoute
                                  path="/private-new-password/"
                                  exact
                                  component={withRouter(ResetPassword)}
                                />
                                <PublicRoute path="/" exact component={withRouter(Login)} />
                                <PublicRoute path="/login/" exact component={Login} />
                                <PublicRoute path="/forgot-password/" exact component={ForgotPassword} />
                                <PrivateRoute path="/dash/" exact component={withRouter(Dashboard)} />
                                <PrivateRoute path="/onboarding/" exact component={withRouter(Onboarding)} />
                                <PrivateRoute
                                  path="/onboarding/map-sale-phases"
                                  exact
                                  component={withRouter(MapSalesPhases)}
                                />
                                <PrivateRoute path="/settings/:sync_status?" exact component={withRouter(Settings)} />
                                <PrivateRoute path="/grid/" exact component={withRouter(ManagerGridPage)} />

                                <PrivateRoute
                                  path="/system-config/:tab_name?/:tab_type_id?"
                                  exact
                                  component={withRouter(SystemConfiguration)}
                                />
                                <PrivateRoute
                                  path="/system-config/:tab_name?/:tab_type_id?/log"
                                  exact
                                  component={withRouter(SystemConfiguration)}
                                />
                                <PrivateRoute
                                  path="/system-view/:tab_name?/:tab_type_id?"
                                  exact
                                  component={withRouter(SystemView)}
                                />
                                <PrivateRoute
                                  path="/event-manager/:event_id?"
                                  exact
                                  component={withRouter(EventManager)}
                                />
                                <PrivateRoute
                                  path="/manage-schedule/:event_id?"
                                  exact
                                  component={withRouter(ScheduleManager)}
                                />
                                <PrivateRoute path="/notifications/" exact component={withRouter(NotificationsPage)} />
                                {!!aiPhase1 || !!callLibraryV2 ? (
                                  <PrivateRoute path="/call-library/" exact component={withRouter(CallLibraryPageV2)} />
                                ) : (
                                  <PrivateRoute path="/call-library/" exact component={withRouter(CallLibraryPage)} />
                                )}
                                <PrivateRoute path="/phone-numbers/" exact component={withRouter(PhoneNumbers)} />
                                {!!aiPhase1 || aiPhase1 === undefined ? (
                                  <PrivateRoute
                                    path="/call-report/:conference_id/:from_system_view?"
                                    exact
                                    component={withRouter(CallReportV2)}
                                  />
                                ) : (
                                  <PrivateRoute
                                    path="/call-report/:conference_id/:from_system_view?"
                                    exact
                                    component={withRouter(CallReport)}
                                  />
                                )}
                                <PrivateRoute
                                  path="/lead-detail/hubspot/:type?/:id?"
                                  exact
                                  component={withRouter(HubSpotLeadRedirect)}
                                />
                                <PrivateRoute
                                  path="/lead-detail/:lead_id?"
                                  exact
                                  component={withRouter(LeadDetailV3)}
                                />
                                <PrivateRoute
                                  path="/lead-detail/:lead_id?/custom-object/:custom_object_id?"
                                  exact
                                  component={withRouter(CustomObjectListView)}
                                />
                                <PrivateRoute
                                  path="/lead-detail/:lead_id?/custom-object/:custom_object_id?/custom-object-row/:custom_object_row_id?"
                                  exact
                                  component={withRouter(LeadDetailV3)}
                                />
                                <PrivateRoute
                                  path="/record-detail/:row_id?/custom-object/:custom_object_id?"
                                  exact
                                  component={withRouter(CustomObjectDetailView)}
                                />
                                <PrivateRoute path="/sequences" exact component={withRouter(SequencesPage)} />
                                <PrivateRoute
                                  path="/sequences/settings"
                                  exact
                                  component={withRouter(SequenceSettings)}
                                />
                                <PrivateRoute
                                  path="/sequences/reporting"
                                  exact
                                  component={withRouter(SequenceReporting)}
                                />
                                <PrivateRoute
                                  path="/sequences/:sequence_id?"
                                  exact
                                  component={withRouter(SequenceDetail)}
                                />
                                <PrivateRoute path="/test/ai" exact component={withRouter(DashboardAI)} />
                                <Route path="*" status={404}>
                                  <Redirect to="/404" />
                                </Route>
                              </Switch>
                            </>
                          </FilterContext>
                        </FavoritedLeadFilterProvider>
                      </LeadCardProvider>
                    </LeadFilterProvider>
                  </CartProvider>
                </CallProvider>
              </SelfSourceLeadProvider>
            </ScheduleProvider>
          </ModalProvider>
        </UserStatusProvider>
      </DataWrapper>
    </Router>
  );
};

export default AppRouter;
