import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { ApolloError, gql, useMutation, useQuery } from "@apollo/client";
import * as Sentry from "@sentry/react";
import "ag-grid-community/styles/ag-grid.css";
import { AgGridReact } from "ag-grid-react";
import styled from "styled-components";
import useFetchFilterLeads from "src/query-hooks/useFetchFilterLeads";
import { useModalContext, useSub } from "../../../utils/hooks";
import { loggedInUser } from "../../../apollo/cache";
import { IS_USER_REP, MRR_LABEL, SHOW_LEAD_SOURCE_TO_USER } from "../../../apollo/query";
import { LeadFilterContext, ModalContext } from "../../../context";
import { arrow_left, arrow_right, caret_down, filter, refresh, search, share, xIcon } from "../../../images/NewDesign";
import { formatBusinessName, formatDateTime, getTitle } from "../../../utils/format";
import { theme } from "../../../utils/theme";
import { appToast } from "../../../utils/toast";
import { APP_ENABLEMENT_ROLES, APP_REP_ROLES, APP_ROLES, SEARCH_DEBOUNCE_INTERVAL } from "../../../utils/variables";
import { PhoenixStyledTooltip } from "../../Dumb/PhoenixStyledTooltip";
import { AppErrorText, AppText, FlexDiv, Loading, SkeletonBlock } from "../../UI";
import { PhoenixAppButton, PhoenixCheckbox, PhoenixIcon, PhoenixInput, PhoenixMenuSelect } from "../../UI/Phoenix";
import {
  BulkMergeLeadsModal,
  BulkReassignLeadsModalV2,
  BulkStatusChangeModal,
  LeadSearchManageColumnsModal,
} from "../../modal";
import "./LeadSearchPage.css";
import { useDebounce, useFirstRender, useTabFocus } from "../../../utils/hooks";
import LeadsFilterV2 from "../LeadsFilterV2";
import { formatLeadFiltersPayload } from "../LeadsFilterV2/shared";
import { useFlags } from "launchdarkly-react-client-sdk";
import { findCountryWithCode } from "src/static/countries";

// types
interface DisappearingDivProps {
  isOnboarding?: boolean;
  my_leads?: boolean;
  display_bulk_unassign: boolean;
}

// fetches

export const FETCH_FILTER_LEADS = gql`
  query filterLeads(
    $search_text: String
    $lead_filter: LeadFilter
    $skip: Int!
    $take: Int!
    $order_by: String
    $ascending: Boolean!
    $my_leads: Boolean
    $lead_system_operators: LeadSystemOperators
  ) {
    filterLeads(
      search_text: $search_text
      lead_filter: $lead_filter
      skip: $skip
      take: $take
      order_by: $order_by
      ascending: $ascending
      my_leads: $my_leads
      lead_system_operators: $lead_system_operators
    )
  }
`;

export const EXPORT_FILTERED_LEAD = gql`
  mutation exportFilteredLead(
    $lead_filter: LeadFilter
    $search_text: String
    $my_leads: Boolean
    $lead_system_operators: LeadSystemOperators
  ) {
    exportFilteredLead(
      lead_filter: $lead_filter
      search_text: $search_text
      my_leads: $my_leads
      lead_system_operators: $lead_system_operators
    )
  }
`;

const FETCH_FILTERED_LEAD_COUNT = gql`
  query fetchFilteredLeadCount(
    $lead_filter: LeadFilter
    $my_leads: Boolean
    $search_text: String
    $lead_system_operators: LeadSystemOperators
  ) {
    fetchFilteredLeadCount(
      lead_filter: $lead_filter
      my_leads: $my_leads
      search_text: $search_text
      lead_system_operators: $lead_system_operators
    )
  }
`;

// constants

const nonSortableColumns: string[] = [];

const INACTIONABLE_STATUSES = ["Customer", "Retired", "Resting"];

const LIMIT_ON_BULK_ASSOCIATION = 10;

const LIMIT_ON_BULK_STATUS_CHANGE = 100;

// helper functions

export const copyToClipboard = async (text: string) => {
  try {
    await navigator.clipboard.writeText(text);

    appToast("Cell value copied to clipboard");
  } catch (err) {
    console.error("Failed to copy: ", err);
  }
};

const highlightText = (text: string, highlight: string, onClick: () => void) => {
  if (!text || !highlight) {
    return null;
  }

  if (!highlight.trim()) {
    return <span onClick={onClick}>{text}</span>;
  }
  const truncateLength = 25;
  const regex = new RegExp(`(${highlight})`, "gi");
  const matchIndex = text?.toLowerCase()?.indexOf(highlight?.toLowerCase());

  if (matchIndex === -1) {
    return <span onClick={onClick}>{text}</span>;
  }

  const start = Math.max(0, matchIndex - truncateLength);
  const end = Math.min(text.length, matchIndex + highlight.length + truncateLength);
  const truncatedText = text.slice(start, end);
  const parts = truncatedText.split(regex);

  return (
    <span onClick={onClick}>
      {start > 0 && "..."}
      {parts.map((part, i) =>
        part?.toLowerCase() === highlight?.toLowerCase() ? (
          <strong key={i} style={{ color: theme.PRIMARY500, fontWeight: "bold" }}>
            {part}
          </strong>
        ) : (
          <span key={i}>{part}</span>
        ),
      )}
      {end < text.length && "..."}
    </span>
  );
};

interface ICell {
  id: string;
  // other properties are just key value pairs
  [key: string]: any;
}

// helper functions

function cleanPhoneNumberInString(input: string) {
  const phoneNumberRegex = /^\+?[0-9()\s.-]+$/;

  // Check if the string is just a phone number
  if (phoneNumberRegex.test(input.trim())) {
    // If it's a phone number, remove spaces and symbols
    return input.replace(/[^\d]/g, "");
  }

  // If it's not just a phone number, return the original string
  return input;
}

const getHeaderLabel = (header: string, mrrLabel: any) => {
  switch (header) {
    case "MRR":
      return mrrLabel?.getMrrLabel;
    default:
      return header;
  }
};

const getHeaderMinWidth = (header: string) => {
  switch (header) {
    case "Dials: Lifetime":
      return 150;
    case "Dials: Current Sales Cycle":
      return 210;
    case "Last Lead Assignment Origin Time":
      return 240;
    case "Business Name":
    case "Rep":
    case "Last Call Result Time":
    case "Lead Creation Date":
    case "Status":
    case "First Name":
    case "Last Name":
    case "City":
    case "State":
    case "Industry":
    case "Sub_industry":
    case "Lead Source":
    case "Last Call Result":
    case "Last Call Result Rep":
    case "Timezone":
    case "Phase":
    case "Lead Creation Source":
    case "Primary Email":
    case "Primary Phone Number":
    case "Zip":
    case "Country":
    default:
      return 180;
  }
};

const LeadSearchPage: React.FC<DisappearingDivProps> = ({ my_leads = false, display_bulk_unassign = false }) => {
  // context vars

  const {
    showFiltersModal,
    setShowFiltersModal,
    reassignLeadModal,
    setReassignLeadModal,
    setSelectedLeadToReassign,
    setIsUnassignOn,
    myLeadsColumnModal,
    setMyLeadsColumnModal,
    showBulkMergeLeadsModal,
    showBulkStatusChangeModal,
    setShowBulkStatusChangeModal,
    setBulkStatusChangeModalData,
    setShowBulkMergeLeadsModal,
    setBulkMergeLeadsModalData,
  } = useModalContext();

  const {
    leadSystemFilter,
    currentFilterCount,
    leadSystemOperators,
    resetLeadSystemFilters,
    leadSort,
    setLeadSort,
    leadSortAscending,
    setLeadSortAscending,
    loadingUserSavedHeaders,
    userSavedHeaders,
    formatedUserSavedHeaders,
  } = useContext(LeadFilterContext);

  // cached values
  const { data: clientData } = useQuery(IS_USER_REP);
  const { data: mrrLabel } = useQuery(MRR_LABEL);
  const { data: showLeadSourceToUser } = useQuery(SHOW_LEAD_SOURCE_TO_USER);

  const { showNewLeadFilters } = useFlags();

  const tableRef = useRef<HTMLDivElement>(null);

  const DATA_TO_NEVER_DISPLAY = ["id", "hidden_status", "hidden_rep_id"];

  if (!showLeadSourceToUser?.showLeadSourceToUser) {
    DATA_TO_NEVER_DISPLAY.push("Lead Source");
  }

  const [searchValue, setSearchValue] = useState("");

  const searchInput = useRef<HTMLInputElement>(null);

  const [selectedLeadIds, setSelectedLeadIds] = useState([] as string[]);

  const [isAllSelected, setIsAllSelected] = useState(false);

  const [canExport, setCanExport] = useState<boolean>(true);

  const [isInitialPageLoad, setIsInitialPageLoad] = useState<boolean>(true);

  // Table states
  const [take, setTake] = useState(25);

  const [skip, setSkip] = useState(0);

  const [localSearchValue, setLocalSearchValue] = useState(searchValue);

  const [showBulkActionsMenu, setShowBulkActionsMenu] = useState<boolean>(false);

  const leadFilterPayload = formatLeadFiltersPayload(leadSystemFilter);

  // queries
  const {
    dataCount,
    dataTable,
    errorCount,
    errorExport,
    errorTable,
    exportFilteredLead,
    loadingCount,
    loadingExport,
    loadingTable,
    refetchTable,
  } = useFetchFilterLeads({
    ascending: leadSortAscending,
    lead_filter: leadFilterPayload,
    lead_system_operators: leadSystemOperators,
    leadSort,
    mrrLabel,
    my_leads,
    search_text: searchValue,
    skip: skip,
    take: take,
    onCompleted: () => {
      setIsInitialPageLoad(false);
    },
  });
  //  computed vars
  const noLeadsSelected = selectedLeadIds?.length === 0;

  const leadsNumberSelected = selectedLeadIds.length;

  const loadingTableAndHeaders = !!loadingTable || !!loadingUserSavedHeaders;

  const isRep = !!loggedInUser().role && APP_REP_ROLES.includes(loggedInUser().role as APP_ROLES);

  useSub("SAVED_VIEW_UPDATED", (data) => {
    if (data) {
      setLeadSort(data.order_by);
      setLeadSortAscending(data.ascending);
      setLocalSearchValue(data.search_text);
      setSearchValue(data.search_text);
      setSkip(data.skip);
      setTake(data.take);
    }
  });

  // useEffects

  // clears selected leads in the checkbox when filters change
  useEffect(() => {
    setSelectedLeadIds([]);
    setIsAllSelected(false);
  }, [leadSystemFilter, leadSort, leadSortAscending]);

  // handles logic for knowing when all leads are selected I believe this is necessary in the UI
  useEffect(() => {
    if (loadingTableAndHeaders) return;

    if (!!dataTable && !!dataTable.filterLeads && !!dataTable.filterLeads.length) {
      const ids = dataTable.filterLeads?.map((item: any) => item.id);
      const result = ids.every((id: string) => selectedLeadIds.includes(id));
      setIsAllSelected(result);
    } else {
      setIsAllSelected(false);
    }
  }, [dataTable, loadingTableAndHeaders]);

  // update the take to match the new skip
  useEffect(() => {
    if (!!dataCount && !!dataCount.fetchFilteredLeadCount && skip * take > Number(dataCount.fetchFilteredLeadCount)) {
      setSkip(Math.floor(Number(dataCount.fetchFilteredLeadCount) / take));
    }
  }, [take]);

  // set skip to 0 on filter change

  useEffect(() => {
    setSkip(0);
  }, [leadSystemFilter]);

  // not sure about this one. It was existing logic but looks like we should refactor
  useEffect(() => {
    let delay: NodeJS.Timeout = setTimeout(() => {}, 0);
    if (!canExport) {
      delay = setTimeout(() => {
        setCanExport(true);
      }, 15000);
    }
    return () => clearTimeout(delay);
  }, [canExport]);

  // helper functions with dependencies
  const handlePageChange = (e: React.FormEvent<HTMLSpanElement>) => {
    const newValue = e.currentTarget.textContent;
    if (!newValue) {
      e.currentTarget.textContent = String(skip + 1);
      return;
    }

    const pageNumber = parseInt(newValue, 10);
    const maxPage = Math.ceil(Number(dataCount?.fetchFilteredLeadCount) / take);

    if (isNaN(pageNumber) || pageNumber < 1 || pageNumber > maxPage) {
      e.currentTarget.textContent = String(skip + 1);
      return;
    }

    setSkip(pageNumber - 1);
  };

  const handlePageEditorKeyDown = (e: React.KeyboardEvent<HTMLSpanElement>) => {
    if (e.key === "Enter") {
      e.preventDefault();
      e.currentTarget.blur();
      return;
    }
  };

  const handleAllSelected = () => {
    let ids =
      loggedInUser().role === "AE" || loggedInUser().role === "SDR"
        ? dataTable.filterLeads.filter(
            (item: any) =>
              !INACTIONABLE_STATUSES.includes(item?.hidden_status) &&
              !(["Owned"].includes(item?.hidden_status) && item?.hidden_rep_id !== loggedInUser().id),
          )
        : dataTable.filterLeads;

    ids = ids?.map((item: ICell) => item.id);

    if (isAllSelected === false) {
      setSelectedLeadIds([...new Set([...selectedLeadIds, ...ids])]);
      setIsAllSelected(true);
    }
    if (isAllSelected === true) {
      setSelectedLeadIds(selectedLeadIds.filter((item: string) => !ids.includes(item)));
      setIsAllSelected(false);
    }
  };

  const isFirstRender = useFirstRender();
  useDebounce(
    () => {
      !isFirstRender && setSearchValue(cleanPhoneNumberInString(localSearchValue));
    },
    [localSearchValue],
    SEARCH_DEBOUNCE_INTERVAL,
  );

  useTabFocus(
    () => {
      refetchTable();
    },
    { buffer: 10000, waitTime: 1500 },
  );

  const generateBulkActionsOptions = () => {
    return [
      // Reassign option
      !my_leads && {
        value: "reassign",
        label: clientData?.isUserRep ? `Claim ` : `Reassign `,
        onOptionClick: () => {
          setSelectedLeadToReassign({
            lead_id: "",
            source_user_id: "",
            lead_id_list: selectedLeadIds,
          });
          setIsUnassignOn("Reassign");
          setReassignLeadModal(!reassignLeadModal);
        },
        disabled: noLeadsSelected,
      },
      // Unassign option
      display_bulk_unassign &&
        (my_leads || !isRep) && {
          value: "unassign",
          label: "Unassign ",
          onOptionClick: () => {
            setSelectedLeadToReassign({
              lead_id: "",
              source_user_id: "",
              lead_id_list: selectedLeadIds,
            });
            setIsUnassignOn("Unassign");
            setReassignLeadModal(!reassignLeadModal);
          },
          disabled: noLeadsSelected,
        },

      // Favorite option (only for user reps and when my_leads is true)
      clientData?.isUserRep &&
        my_leads && {
          value: "favorite",
          label: "Favorite ",
          onOptionClick: () => {
            setShowBulkActionsMenu(false);
            setShowFiltersModal(false);
            setSelectedLeadToReassign({
              lead_id: "",
              source_user_id: "",
              lead_id_list: selectedLeadIds,
            });
            setIsUnassignOn("Favorite");
            setReassignLeadModal(!reassignLeadModal);
          },
          disabled: noLeadsSelected,
        },
      // Add to Custom Queue option (only for user reps and when my_leads is true)
      clientData?.isUserRep &&
        my_leads && {
          value: "customQueue",
          label: "Add to Custom Queue",
          onOptionClick: () => {
            setSelectedLeadToReassign({
              lead_id: "",
              source_user_id: "",
              lead_id_list: selectedLeadIds,
            });
            setShowBulkActionsMenu(false);
            setShowFiltersModal(false);
            setIsUnassignOn("CustomQueue");
            setReassignLeadModal(!reassignLeadModal);
          },
          disabled: noLeadsSelected,
        },

      // status tab section (enablement only)
      APP_ENABLEMENT_ROLES.includes(loggedInUser().role as APP_ROLES) && {
        value: "status",
        label: `Status (Max ${LIMIT_ON_BULK_STATUS_CHANGE})`,
        tabOption: true,
        onOptionClick: () => {},
      },

      // change all to Resting
      APP_ENABLEMENT_ROLES.includes(loggedInUser().role as APP_ROLES) && {
        value: "resting",
        label: "Resting",
        onOptionClick: () => {
          console.log("resting clicked");
          setBulkStatusChangeModalData({
            desired_status: "Resting",
            selected_lead_ids: selectedLeadIds,
          });
          setShowBulkMergeLeadsModal(false);
          setShowBulkActionsMenu(false);
          setShowBulkStatusChangeModal(true);
        },
        disabled: noLeadsSelected || leadsNumberSelected > LIMIT_ON_BULK_STATUS_CHANGE,
      },

      // change all to Retired
      APP_ENABLEMENT_ROLES.includes(loggedInUser().role as APP_ROLES) && {
        value: "retired",
        label: "Retired",
        onOptionClick: () => {
          console.log("retired clicked");
          setBulkStatusChangeModalData({
            desired_status: "Retired",
            selected_lead_ids: selectedLeadIds,
          });
          setShowBulkMergeLeadsModal(false);
          setShowBulkActionsMenu(false);
          setShowBulkStatusChangeModal(true);
        },
        disabled: noLeadsSelected || leadsNumberSelected > LIMIT_ON_BULK_STATUS_CHANGE,
      },

      // change all to Long Resting

      APP_ENABLEMENT_ROLES.includes(loggedInUser().role as APP_ROLES) && {
        value: "longResting",
        label: "Long Resting",
        onOptionClick: () => {
          console.log("longResting clicked");
          setBulkStatusChangeModalData({
            desired_status: "LongResting",
            selected_lead_ids: selectedLeadIds,
          });

          setShowBulkMergeLeadsModal(false);
          setShowBulkActionsMenu(false);
          setShowBulkStatusChangeModal(true);
        },
        disabled: noLeadsSelected || leadsNumberSelected > LIMIT_ON_BULK_STATUS_CHANGE,
      },

      // change all to Customer

      APP_ENABLEMENT_ROLES.includes(loggedInUser().role as APP_ROLES) && {
        value: "customer",
        label: "Customer",
        onOptionClick: () => {
          console.log("customer clicked");
          setBulkStatusChangeModalData({
            desired_status: "Customer",
            selected_lead_ids: selectedLeadIds,
          });
          setShowBulkMergeLeadsModal(false);
          setShowBulkActionsMenu(false);
          setShowBulkStatusChangeModal(true);
        },
        disabled: noLeadsSelected || leadsNumberSelected > LIMIT_ON_BULK_STATUS_CHANGE,
      },

      // Associate dummy tab section (enablement only)
      APP_ENABLEMENT_ROLES.includes(loggedInUser().role as APP_ROLES) && {
        value: "associate",
        label: `Associate (Max ${LIMIT_ON_BULK_ASSOCIATION})`,
        tabOption: true,
        onOptionClick: () => {},
      },

      // designate a primary rep
      APP_ENABLEMENT_ROLES.includes(loggedInUser().role as APP_ROLES) && {
        value: "primaryContact",
        label: "Designate a Primary Contact",
        onOptionClick: () => {
          console.log("primaryRep clicked");
          setShowBulkActionsMenu(false);
          setShowFiltersModal(false);
          setBulkMergeLeadsModalData({
            selected_lead_ids: selectedLeadIds,
          });
          setShowBulkMergeLeadsModal(true);
        },
        disabled: noLeadsSelected || leadsNumberSelected > LIMIT_ON_BULK_ASSOCIATION,
      },
    ].filter(Boolean);
  };

  const globalLoading = loadingCount || loadingTable || loadingExport || loadingUserSavedHeaders;

  const globalError = errorCount || errorTable || errorExport;

  // start of AG grid logic

  const BusinessNameCellRenderer = ({ id, value, onClick }: { id: string; value: string; onClick: () => void }) => (
    <HighlightableSpan>
      <a target="_blank" href={`/lead-detail/${id}`} style={{ color: theme.PRIMARY500, textDecoration: "underline" }}>
        {formatBusinessName(value)}
      </a>
    </HighlightableSpan>
  );

  const NotesCellRenderer = ({
    value,
    onClick,
    searchText = "",
  }: {
    value: string;
    onClick: () => void;
    searchText?: string;
  }) => {
    if (!searchText) {
      return <HighlightableSpan onClick={onClick}>{value}</HighlightableSpan>;
    }

    return <HighlightableSpan>{highlightText(value, searchText, onClick)}</HighlightableSpan>;
  };

  const DefaultCellRenderer = ({ value, onClick }: { value: string; onClick: () => void }) => (
    <HighlightableSpan onClick={onClick}>{value}</HighlightableSpan>
  );

  const DefaultNumberCellRenderer = ({ value, onClick }: { value: number; onClick: () => void }) => (
    <HighlightableSpan
      style={{
        color: theme.PRIMARY500,
      }}
      onClick={onClick}
    >
      {value?.toLocaleString()}
    </HighlightableSpan>
  );

  const DateTimeCellRenderer = ({ value, onClick }: any) => (
    <HighlightableSpan onClick={onClick}>{value ? formatDateTime(value) : ""}</HighlightableSpan>
  );

  const CheckboxRenderer = ({ value, data }: any) => {
    return (
      <div
        style={{
          display: "flex",
          height: "100%",
          alignItems: "center",
          width: "max-content",
          padding: "0px 0px 0px 16px",
          borderRight: `1px solid ${theme.border.brand.tertiary},`,
        }}
      >
        <PhoenixCheckbox
          checked={isAllSelected}
          disabled={loggedInUser()?.visible_all_leads_sm}
          onChange={() => {
            if (loadingTableAndHeaders) return;

            handleAllSelected();
          }}
        />
      </div>
    );
  };

  const CheckBoxCellRenderer = ({ value, data }: any) => {
    const item = data;
    const isCheckboxDisabled =
      ((loggedInUser().role === "AE" || loggedInUser().role === "SDR") &&
        (INACTIONABLE_STATUSES.includes(item?.hidden_status) ||
          (["Owned"].includes(item?.hidden_status) && item?.hidden_rep_id !== loggedInUser().id))) ||
      loggedInUser()?.visible_all_leads_sm;
    return (
      <div
        style={{
          display: "flex",
          height: "100%",
          alignItems: "center",
          width: "max-content",
        }}
        data-for="checkbox-tooltip"
        data-tip={
          isCheckboxDisabled
            ? loggedInUser()?.visible_all_leads_sm
              ? `Sales Managers cannot perform this action`
              : item?.hidden_status === "Owned"
              ? `Cannot perform this action because this lead is owned by another rep`
              : `Cannot perform an action on a lead in ${item?.hidden_status} status`
            : null
        }
      >
        {!!isCheckboxDisabled && <PhoenixStyledTooltip multiline place="right" effect="solid" id="checkbox-tooltip" />}

        <PhoenixCheckbox
          checked={selectedLeadIds.includes(item.id)}
          onChange={() => {
            selectedLeadIds.includes(item.id)
              ? setSelectedLeadIds(selectedLeadIds.filter((val: string) => val !== item.id))
              : setSelectedLeadIds([...selectedLeadIds, item.id]);
          }}
          disabled={isCheckboxDisabled}
        />
      </div>
    );
  };

  const HeaderRenderer = (params: any) => {
    const { column } = params;
    const item = column.colId;

    return (
      <div
        className="ag-lead-search-header"
        style={{
          cursor: nonSortableColumns.includes(item) ? "default" : "pointer",
          background: leadSort === item ? theme.fill.brand.disabled : theme.fill.brand.secondary,
          border: leadSort === item ? `1px solid ${theme.border.brand.primary}` : undefined,
          borderRight:
            leadSort !== item ? `1px solid ${theme.border.brand.tertiary}` : `1px solid ${theme.border.brand.primary}`,
        }}
        onClick={() => {
          if (nonSortableColumns.includes(item)) return;

          if (leadSort === item) {
            setLeadSortAscending(!leadSortAscending);
          } else {
            setLeadSort(item);
          }
        }}
      >
        <AppText
          fontWeight={600}
          fontSize={12}
          style={{
            // no wrap
            whiteSpace: "nowrap",
          }}
        >
          {getHeaderLabel(item, mrrLabel)}
        </AppText>
        {!nonSortableColumns.includes(item) && (
          <PhoenixIcon
            svg={caret_down}
            size={16}
            color={theme.icon.brand.default}
            hoverColor={theme.icon.brand.default}
            fillIcon
            style={{
              // @ts-ignore
              transform:
                leadSort === item
                  ? leadSortAscending
                    ? "rotate(0deg)"
                    : "rotate(180deg)"
                  : leadSortAscending
                  ? "rotate(180deg)"
                  : "rotate(0deg)",
            }}
          />
        )}
      </div>
    );
  };

  const LinkCellRenderer = ({ value, onClick }: any) => {
    const isLink = new RegExp(
      "([a-zA-Z0-9]+://)?([a-zA-Z0-9_]+:[a-zA-Z0-9_]+@)?([a-zA-Z0-9.-]+\\.[A-Za-z]{2,4})(:[0-9]+)?(/.*)?",
    ).test(value);

    return (
      <HighlightableSpan>
        {isLink ? (
          <a
            href={value.startsWith("http") ? value : `http://${value}`}
            target="_blank"
            style={{
              textDecoration: "underline",
              color: theme.PRIMARY500,
              cursor: "pointer",
            }}
          >
            {value}
          </a>
        ) : (
          <span onClick={onClick}>
            {typeof value === "boolean" ? (value ? "true" : "false") : Array.isArray(value) ? value.join(",") : value}
          </span>
        )}
      </HighlightableSpan>
    );
  };

  const columnDefs = [
    {
      headerName: "checkbox",
      field: "selected",
      headerClass: "ag-lead-search-header",
      cellClass: "ag-lead-search-cell-checkbox",
      cellRenderer: CheckBoxCellRenderer,
      headerComponent: CheckboxRenderer,
      width: 48,
      minWidth: 48,
      maxWidth: 48,
      resizable: false,
      pinned: "left" as any,
    },
    ...(formatedUserSavedHeaders || [])
      .filter((item) => item !== "Notes" || (leadSystemFilter.notes && leadSystemFilter.notes.trim() !== ""))
      .filter((item) => !DATA_TO_NEVER_DISPLAY.includes(item))
      ?.map((item) => {
        const field = getTitle(item, mrrLabel);

        // Calculate the minimum width dynamically based on the widest content
        const minWidth = getHeaderMinWidth(item);

        return {
          headerName: getHeaderLabel(item, mrrLabel),
          field: field,
          minWidth: minWidth,
          // flex: 1,
          resizable: true,
          cellRendererFramework: (params: any) => {
            const { value, data } = params;
            const { id } = data;
            const columnHeader = getTitle(params.colDef.field, mrrLabel);

            const handleCellClick = () => {
              copyToClipboard(value);
            };

            let cellRenderer;

            switch (columnHeader) {
              case "Dials: Lifetime":
              case "Dials: Current Sales Cycle":
                cellRenderer = <DefaultNumberCellRenderer value={value} onClick={handleCellClick} />;
                break;
              case "Business Name":
                cellRenderer = <BusinessNameCellRenderer id={id} value={value ?? "N/A"} onClick={handleCellClick} />;
                break;
              case "Rep":
                cellRenderer = <DefaultCellRenderer value={value || "Not Assigned"} onClick={handleCellClick} />;
                break;
              case "Last Call Result Time":
              case "Lead Creation Date":
              case "Last Lead Assignment Origin Time":
              case "Next Scheduled Event":
                cellRenderer = <DateTimeCellRenderer value={value} onClick={handleCellClick} />;
                break;

              case "Notes":
                cellRenderer = (
                  <NotesCellRenderer value={value} onClick={handleCellClick} searchText={leadSystemFilter.notes} />
                );
                break;

              case "Remaining days of ownership":
                cellRenderer = <DefaultCellRenderer value={value || "N/A"} onClick={handleCellClick} />;
                break;
              case "Country":
                let computedVal = value;
                if (computedVal?.length === 2) {
                  const country = findCountryWithCode(value);
                  if (country) {
                    computedVal = `${value} (${country})`;
                  }
                }

                cellRenderer = <LinkCellRenderer value={computedVal} onClick={handleCellClick} />;
                break;

              default:
                cellRenderer = <LinkCellRenderer value={value} onClick={handleCellClick} />;
                break;
            }

            return cellRenderer;
          },
          sortable: false,
          headerComponent: HeaderRenderer,
          cellClass: "ag-lead-search-cell",
        };
      }),
  ];

  if (globalError) return <TableErrorState error={globalError} resetLeadSystemFilters={resetLeadSystemFilters} />;

  return (
    <Sentry.ErrorBoundary fallback={"An error has occued in LeadSearchPage"}>
      {reassignLeadModal && (
        <BulkReassignLeadsModalV2
          claim={clientData?.isUserRep && !my_leads}
          resetSelectedLeads={() => {
            setSelectedLeadIds([]);
            setIsAllSelected(false);
          }}
        />
      )}

      {showBulkMergeLeadsModal && (
        <BulkMergeLeadsModal
          resetSelectedLeads={() => {
            setSelectedLeadIds([]);
            setIsAllSelected(false);
          }}
        />
      )}
      {showBulkStatusChangeModal && <BulkStatusChangeModal />}

      <FlexDiv
        align="center"
        justify="space-between"
        style={{
          width: "100%",
          paddingBottom: "24px",
          marginTop: "11px",
          borderBottom: `1px solid ${theme.border.neutral.primary}`,
        }}
      >
        <FlexDiv
          align="center"
          gap={8}
          style={{
            paddingLeft: 8,
            paddingRight: 8,
          }}
        >
          <>
            <PhoenixStyledTooltip multiline place="top" effect="solid" id="tooltip" />
            {loadingCount || loadingTable || loadingTableAndHeaders ? (
              <SkeletonBlock width={60} height={20} borderRadius={6} />
            ) : errorCount ? (
              <AppErrorText>Error loading count.</AppErrorText>
            ) : (
              <AppText>{dataCount?.fetchFilteredLeadCount?.toLocaleString()} Leads</AppText>
            )}

            {loggedInUser().role === "ADMIN" && (
              <div
                style={{ margin: "0px 0px 3px 3px" }}
                data-tip={
                  loadingCount
                    ? "loading count..."
                    : dataCount?.fetchFilteredLeadCount > 75000
                    ? "Cannot export more than 75,000 at a time. Use filters to refine your results."
                    : canExport
                    ? "Export these leads (75,000 lead limit)"
                    : "Please wait 15 seconds before exporting again."
                }
                onClick={async () => {
                  if (dataCount?.fetchFilteredLeadCount <= 75000 && canExport) {
                    await exportFilteredLead();
                    canExport && setCanExport(false);
                  }
                }}
              >
                {loadingExport || loadingCount ? (
                  <SkeletonBlock width={"100%"} height={"100%"} borderRadius={6} />
                ) : (
                  <PhoenixIcon
                    svg={share}
                    size={14}
                    variant="neutral"
                    pointer={dataCount?.fetchFilteredLeadCount <= 75000 && canExport}
                  />
                )}
              </div>
            )}
          </>
          <div />
          <AppText>{leadsNumberSelected.toLocaleString()} Selected</AppText>

          <PhoenixAppButton buttonType="ghost-large" variant="brand" onClick={() => setMyLeadsColumnModal(true)}>
            Manage Columns
          </PhoenixAppButton>
          {loadingTableAndHeaders && (
            <div
              style={{
                // @ts-ignore
                transform: "scale(0.5)",
                height: 15,
              }}
            >
              <Loading />
            </div>
          )}
          {myLeadsColumnModal && (
            <LeadSearchManageColumnsModal
              open={myLeadsColumnModal}
              onClose={() => setMyLeadsColumnModal(false)}
              refetchTable={refetchTable}
            />
          )}
        </FlexDiv>
        <FlexDiv align="center" gap={8}>
          {!showNewLeadFilters ? (
            <FilterButton onClick={() => setShowFiltersModal(!showFiltersModal)}>
              <PhoenixIcon
                svg={filter}
                size={16}
                variant="neutral"
                pointer={true}
                color={theme.WHITE_COLOR}
                hoverColor={theme.WHITE_COLOR}
              />
              {currentFilterCount > 0 && <RedDot>{currentFilterCount}</RedDot>}
            </FilterButton>
          ) : null}
          {!loggedInUser().visible_all_leads_sm && (
            <div
              style={{
                position: "relative",
              }}
            >
              <PhoenixMenuSelect
                title="Bulk Actions"
                width={224}
                containerZIndex={999}
                loading={loadingTableAndHeaders}
                error={!!errorTable}
                containerTop={40}
                onClick={() => setShowBulkActionsMenu(true)}
                setShow={setShowBulkActionsMenu}
                show={showBulkActionsMenu}
                containerMaxHeight={"max-content"}
                containerMinHeight={"max-content"}
                options={generateBulkActionsOptions()}
                containerWidth={224}
              />
            </div>
          )}
          <SearchContainer>
            <PhoenixInput
              value={localSearchValue}
              placeholder="Search Leads"
              displayNoContextText
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                e.persist();
                // debouncedChange(e);
                setLocalSearchValue(e.target.value);
              }}
              insideLeftElementOverride={
                <PhoenixIcon
                  svg={searchValue.length ? xIcon : search}
                  size={16}
                  pointer
                  onClick={() => {
                    if (searchValue.length) setSearchValue("");
                    if (localSearchValue.length) setLocalSearchValue("");
                    if (searchInput && searchInput.current) searchInput.current.focus();
                  }}
                />
              }
            />
          </SearchContainer>
        </FlexDiv>
      </FlexDiv>
      {showNewLeadFilters ? <LeadsFilterV2 myLeads={my_leads} /> : null}
      {loadingTableAndHeaders && isInitialPageLoad ? (
        <TableLoadingState userSavedHeaders={userSavedHeaders} />
      ) : errorTable ? (
        <AppErrorText>Error loading table</AppErrorText>
      ) : (
        <>
          <TableContainer className="ag-theme-lead-search-list">
            <AgGridReact
              columnDefs={columnDefs}
              rowData={dataTable?.filterLeads}
              suppressRowClickSelection={true}
              suppressMultiSort={true}
              defaultColDef={{
                resizable: true,
                sortable: false,
                wrapHeaderText: true,
                autoHeaderHeight: true,
                cellStyle: { "padding-left": 0, "padding-right": 0 },
              }}
              // update widths on table load
              onFirstDataRendered={(event) => {
                event.columnApi.autoSizeAllColumns();
              }}
              suppressCellSelection={false}
              enableCellTextSelection={true}
              suppressMovableColumns={true}
            />
          </TableContainer>
          <FlexDiv
            justify="space-between"
            style={{
              width: "100%",
              borderTop: `1px solid ${theme.border.neutral.primary}`,
              paddingTop: "24px",
            }}
          >
            <CornerDiv>
              <LeadsPerPageText>Leads Per Page</LeadsPerPageText>
              <LeadsNumberText onClick={() => setTake(25)} selected={take === 25}>
                25
              </LeadsNumberText>

              <LeadsNumberText onClick={() => setTake(50)} selected={take === 50}>
                50
              </LeadsNumberText>

              <LeadsNumberText onClick={() => setTake(75)} selected={take === 75}>
                75
              </LeadsNumberText>

              <LeadsNumberText onClick={() => setTake(100)} selected={take === 100}>
                100
              </LeadsNumberText>
            </CornerDiv>
            <CornerDiv>
              {loadingCount ? (
                <SkeletonBlock width={"95%"} height={"95%"} borderRadius={6} />
              ) : errorCount ? (
                <AppErrorText>Error loading count.</AppErrorText>
              ) : (
                <>
                  <LeadsPerPageText style={{ marginLeft: "40px" }}>
                    Page{" "}
                    <PageNumberText
                      contentEditable
                      suppressContentEditableWarning
                      onKeyDown={handlePageEditorKeyDown}
                      onBlur={handlePageChange}
                    >
                      {skip + 1}
                    </PageNumberText>{" "}
                    of {Math.ceil(Number(dataCount?.fetchFilteredLeadCount) / take).toLocaleString()}
                  </LeadsPerPageText>
                  <div style={{ display: "flex", gap: "8px" }}>
                    <NavIcon
                      disabled={loadingCount || loadingTableAndHeaders || skip <= 0}
                      onClick={() => {
                        tableRef?.current?.scrollTo({
                          top: 0,
                          behavior: "smooth",
                        });
                        setSkip(skip - 1);
                      }}
                    >
                      <PhoenixIcon
                        svg={arrow_left}
                        size={24}
                        color={
                          loadingCount || loadingTableAndHeaders || skip <= 0 ? theme.NEUTRAL400 : theme.PRIMARY600
                        }
                        pointer={!loadingCount && !loadingTableAndHeaders && skip > 0}
                      />
                    </NavIcon>
                    <NavIcon
                      disabled={
                        loadingTableAndHeaders ||
                        loadingCount ||
                        skip * take + take >= Number(dataCount?.fetchFilteredLeadCount)
                      }
                      onClick={() => {
                        tableRef?.current?.scrollTo({
                          top: 0,
                          behavior: "smooth",
                        });
                        setSkip(skip + 1);
                      }}
                    >
                      <PhoenixIcon
                        svg={arrow_right}
                        pointer={
                          !(
                            loadingTableAndHeaders ||
                            loadingCount ||
                            skip * take + take >= Number(dataCount?.fetchFilteredLeadCount)
                          )
                        }
                        size={24}
                        color={
                          loadingTableAndHeaders ||
                          loadingCount ||
                          skip * take + take >= Number(dataCount?.fetchFilteredLeadCount)
                            ? theme.NEUTRAL400
                            : theme.PRIMARY600
                        }
                      />
                    </NavIcon>
                  </div>
                </>
              )}
            </CornerDiv>
          </FlexDiv>
        </>
      )}
    </Sentry.ErrorBoundary>
  );
};

const TableErrorState = ({
  error,
  resetLeadSystemFilters,
}: {
  error: ApolloError;
  resetLeadSystemFilters: () => void;
}) => {
  return (
    <div style={{ height: "100vh", width: "100%" }}>
      <AppErrorText>Unable to load table. Please try refreshing or clearning filters and contact support.</AppErrorText>
      <ResetFilterError onClick={resetLeadSystemFilters}>
        <AppText color={theme.PRIMARY500} fontWeight={500}>
          Reset System Filters
        </AppText>
        <PhoenixIcon svg={refresh} size={14} variant="brand" pointer />
      </ResetFilterError>
    </div>
  );
};

const TableLoadingState = ({ userSavedHeaders }: { userSavedHeaders: string[] }) => {
  return (
    <TableContainer
      style={{
        width: "100%",
        overflowX: "hidden",
      }}
    >
      <GridTable
        style={{
          width: "100%",
        }}
      >
        <thead>
          <GridTR style={{ position: "sticky", top: "0px", zIndex: 3, overflowX: "hidden" }}>
            {userSavedHeaders?.map((item: string, index) => (
              <GridTH
                key={item}
                style={{
                  padding: "4px",
                }}
              >
                <SkeletonBlock width={200} height={"95%"} borderRadius={0} delayNumber={index} />
              </GridTH>
            ))}
          </GridTR>
        </thead>
      </GridTable>
      {new Array(userSavedHeaders)?.map((_, index) => {
        return new Array(userSavedHeaders?.length - 1).fill(0)?.map((_, rowIndex) => (
          <div
            style={{
              marginTop: "4px",
              marginBottom: "4px",
            }}
          >
            <SkeletonBlock width={"100vw"} height={"40px"} borderRadius={0} delayNumber={rowIndex} />
          </div>
        ));
      })}
    </TableContainer>
  );
};
const CornerDiv = styled.div`
  display: flex;
  align-items: center;
`;

const LeadsPerPageText = styled(AppText)`
  font-size: 12px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0.2px;
  margin-right: 16px;
`;

interface DisabledProps {
  disabled: boolean;
}

const NavIcon = styled.div<DisabledProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: ${(props) => (props.disabled ? "auto" : "pointer")};
  pointer-events: ${(props) => (props.disabled ? "none" : "auto")};
  background-color: transparent;
  border-radius: 50%;
  height: 30px;
  width: 30px;
`;

interface SelectedProps {
  selected?: boolean;
}
const LeadsNumberText = styled(AppText)<SelectedProps>`
  font-size: 12px;
  line-height: 21px;
  letter-spacing: 0.2px;
  padding: 0px 8px;
  color: ${(props) => (props.selected ? theme.PRIMARY600 : theme.BLACK_COLOR)};
  border-bottom: none;
  font-weight: ${(props) => (props.selected ? 600 : 400)};
  cursor: pointer;
  border-right: 1px solid #c4c4c4;
  :last-child {
    border-right: none;
  }
`;

const FilterButton = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 40px;
  background-color: ${theme.PRIMARY600};
  border-radius: 4px;
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 40px;
  background-color: ${theme.PRIMARY600};
  border-radius: 4px;
`;

const GridTR = styled.tr`
  ${loggedInUser().visible_all_leads_sm && `td:first-child {border-left: 1px solid ${theme.NEUTRAL200};}`}
`;

const GridTH = styled.th`
  background: ${theme.NEUTRAL100};

  height: 40px;
  padding: 0px;
  margin: 0px;

  ${loggedInUser().visible_all_leads_sm && `:first-child {border-left: 1px solid ${theme.NEUTRAL200};}`}
`;

const GridTable = styled.table`
  font-size: 10px;
  font-weight: 500;
  border-collapse: collapse;
`;

const TableContainer = styled.div`
  height: 650px;
  // on larger screens we want the table to stretch
  min-height: 68vh;
  margin: 40px 0px 0px 0px;
  width: 100%;
  overflow: auto;
  position: relative;
  width: 100%;
`;

const SearchContainer = styled.div`
  display: flex;
  align-items: center;
  width: 250px;
`;

const RedDot = styled.div`
  position: absolute;
  top: 6px;
  right: 5px;
  background-color: red;
  border-radius: 50%;
  width: 12px;
  height: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 10px;
`;

const ResetFilterError = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  margin-top: 16px;
`;

const HighlightableSpan = styled.span`
  user-select: text;
  cursor: text;
`;

const PageNumberText = styled.span`
  border-radius: 4px;
  border: 1px solid ${theme.NEUTRAL300};
  font-size: 12px;
  font-weight: 600;
  line-height: 21px;
  padding: 2px 4px;
`;

export { LeadSearchPage };
