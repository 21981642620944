import { theme } from "./theme";
import { cloneDeep, isEmpty, isObject, round, some } from "lodash";
import { v4 as uuidv4 } from "uuid";
import { appToast, successToast } from "./toast";
import { formatUSD, stringifyNumber, capitalizeFirstLetter } from "./format";
import { iconCallStroke, iconCallbackStroke, iconInboundCallStroke, iconPausedStroke } from "../images/";
import moment from "moment";
import { ObjectType, OptionItem, TokenForFE } from "../types";

import { CurrentLeadType, AlternateContactType, SystemConfigNavShape } from "../types/StateTypes";
import { DEFAULT_DOCUMENT_TITLE, DraggableFieldType } from "./variables";
import { URL_REGEX, EMAIL_REGEX } from "./regex";
/**
 * Takes string and converts it into an uuid with dashes
 * @param id uuid formatted id
 */

const sanitizeUrl = require("@braintree/sanitize-url").sanitizeUrl;
export function addDashBack(id: string) {
  const dashIndex = [8, 12, 16, 20];
  let text = "";
  for (let i = 0; i < id.length; i++) {
    if (dashIndex.includes(i)) {
      text += "-";
      dashIndex.shift();
      i--;
    } else {
      text += id[i];
    }
  }
  return text;
}

/**
 * groups array of objects by an object's key
 *  */
export const groupBy = (array: any, key: any) => {
  return array.reduce((acc: any, curr: any) => {
    (acc[curr[key]] = acc[curr[key]] || []).push(curr);
    return acc;
  }, []);
};

/**
 * groups array of objects by an object's key
 *  */
export const groupUserBy = (array: any, key: any) => {
  return array.reduce((acc: any, curr: any) => {
    if (curr["user_id"]) {
      (acc[curr["row_label"]] = acc[curr["row_label"]] || []).push(curr);
    }
    return acc;
  }, []);
};

export function selectQuantityOptions(range: number) {
  const options = [];
  let i = 1;
  while (i <= range) {
    options.push({
      label: i.toString(),
      value: i,
    });
    i++;
  }
  return options;
}

export const validateIfEmailAddress = (email: string) => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

function forceDownload(blob: any, filename?: any) {
  var a = document.createElement("a");
  a.download = filename;
  a.href = blob;
  // For Firefox https://stackoverflow.com/a/32226068
  document.body.appendChild(a);
  a.click();
  a.remove();
}

function forceDownloadForReport(blob: any, filename?: any) {
  var a = document.createElement("a");
  a.download = filename;
  a.href = blob;
  // For Firefox https://stackoverflow.com/a/32226068
  a.click();
  a.remove();
}

// Current blob size limit is around 500MB for browsers
export const downloadResource = (url: any, filename?: any) => {
  console.log("url before parse: ", url);
  if (!filename) filename = url.split("\\").pop().split("/").pop();
  console.log("filename after parse: ", filename);
  fetch(url, {
    headers: new Headers({
      Origin: window.location.origin.toString(),
    }),
    mode: "no-cors",
  })
    .then((response) => response.blob())
    .then((blob) => {
      let blobUrl = window.URL.createObjectURL(blob);
      forceDownload(blobUrl, filename);
    })
    .catch((e) => console.error(e));
};

// Current blob size limit is around 500MB for browsers
export const downloadResourceCors = (url: any, filename?: any, callback?: any) => {
  if (!filename) filename = url.split("\\").pop().split("/").pop().split("?").shift();
  fetch(url, {
    headers: new Headers({
      Origin: window.location.origin.toString(),
    }),
    mode: "cors",
  })
    .then((response) => response.blob())
    .then((blob) => {
      let blobUrl = window.URL.createObjectURL(blob);
      forceDownload(blobUrl, filename);
      if (callback) callback();
    })
    .catch((e) => console.error(e));
};

export const downloadResourceAWS = (url: any, filename?: any) => {
  if (!filename) filename = url.split("\\").pop().split("/").pop();
  forceDownloadForReport(url, filename);
};

interface EventTypeObject {
  [key: string]: any;
}

export const renderLeadIntentEventTypeValues = (lead: EventTypeObject) => {
  const intent_is_active = isIntentActive(lead);
  const nextIntent = lead.next_intent_scheduled_or_unscheduled;

  const numDialsInSalesCycle = nextIntent?.dial_num_in_sales_cycle
    ? capitalizeFirstLetter(stringifyNumber(nextIntent.dial_num_in_sales_cycle))
    : null;
  let eventTypeValues: EventTypeObject = {
    label: "No next action",
    color: theme.NEUTRAL200,
    tooltip: numDialsInSalesCycle
      ? `${numDialsInSalesCycle || ""} dial since you were last assigned this lead.`
      : "No action required.",
  };
  eventTypeValues.leadValue = formatUSD(round(lead?.lead_value?.value || 0, 2));
  switch (nextIntent?.event_type_label) {
    case "Call Back":
      eventTypeValues.label = "Call Back";
      if (intent_is_active) {
        eventTypeValues.color = theme.PRIMARY500;
        eventTypeValues.icon = iconCallbackStroke;
        eventTypeValues.message = `${moment(nextIntent?.general_time_start_date).format("h:mm A")} 
        to ${moment(nextIntent?.general_time_end_date).format("h:mm A")} 
        on ${moment(nextIntent?.general_time_start_date).format("MMMM Do")}`;
      } else {
        eventTypeValues.color = theme.NEUTRAL200;
        eventTypeValues.message = `You scheduled a call back for this lead on ${moment(
          nextIntent?.general_time_start_date,
        ).format("MMMM Do")} 
          from ${moment(nextIntent?.general_time_start_date).format("h:mm A")} 
          to ${moment(nextIntent?.general_time_end_date).format("h:mm A")}.`;
      }
      break;
    case "Cold Call":
      eventTypeValues.label = "Cold Call";
      eventTypeValues.icon = iconCallStroke;
      eventTypeValues.color = theme.PRIMARY500;
      eventTypeValues.message = `${stringifyNumber(
        nextIntent.dial_num_in_sales_cycle,
      )} dial attempt in this sales cycle.`;
      break;
    case "Automatic Call Followup":
    case "Demo Held Call Followup":
    case "Demo Not Held Call Followup":
      eventTypeValues.label = "Call Followup";
      if (intent_is_active) {
        eventTypeValues.color = theme.PRIMARY500;
        eventTypeValues.message = `${numDialsInSalesCycle} dial in the sales cycle.`;
      } else {
        eventTypeValues.message = `Sellfire will create a second dial attempt of this sales cycle on ${moment(
          nextIntent?.anytime_day,
        ).format("MMMM Do")}. But if you'd like to dial this lead sooner you can! `;
      }
      break;
    case "Sequence Action":
      console.log("Event Type Label: ", nextIntent?.event_type_label);

      eventTypeValues.label = "Sequence Action";
      eventTypeValues.message = nextIntent?.current_sequence_step;

      break;
    case "Demo":
      // eventTypeValues.label = "Demo";
      // eventTypeValues.icon = iconDemoStroke;
      // eventTypeValues.color = intent_is_active ? theme.PRIMARY500 : theme.NEUTRAL200;
      // eventTypeValues.message = `${moment(nextIntent?.schedule_item.start_time).format("h:mm A")}
      // to ${moment(nextIntent?.schedule_item.end_time).format("h:mm A")}
      // on ${moment(nextIntent?.schedule_item.start_time).format("MMMM Do")}`;
      break;
    case "Scheduled Call Back":
      // eventTypeValues.label = "Scheduled Call Back";
      // eventTypeValues.icon = iconCallbackStroke;
      // eventTypeValues.color = intent_is_active ? theme.PRIMARY500 : theme.NEUTRAL200;
      // eventTypeValues.message = `${moment(nextIntent?.schedule_item.start_time).format("h:mm A")}
      // to ${moment(nextIntent?.schedule_item.end_time).format("h:mm A")}
      // on ${moment(nextIntent?.schedule_item.start_time).format("MMMM Do")}`;
      break;
    case "Other":
    case "Scheduled Event":
      break;
    case "Follow-up Demo":
      // eventTypeValues.label = "Follow-up Demo";
      // eventTypeValues.icon = iconCallbackStroke;
      // eventTypeValues.color = intent_is_active ? theme.PRIMARY500 : theme.NEUTRAL200;
      // eventTypeValues.message = `${moment(nextIntent?.schedule_item.start_time).format("h:mm A")}
      // to ${moment(nextIntent?.schedule_item.end_time).format("h:mm A")}
      // on ${moment(nextIntent?.schedule_item.start_time).format("MMMM Do")}`;
      break;
    case "Rescheduled Demo":
      // eventTypeValues.label = "Rescheduled Demo";
      // eventTypeValues.icon = iconCallbackStroke;
      // eventTypeValues.color = intent_is_active ? theme.PRIMARY500 : theme.NEUTRAL200;
      // eventTypeValues.message = `${moment(nextIntent?.schedule_item.start_time).format("h:mm A")}
      // to ${moment(nextIntent?.schedule_item.end_time).format("h:mm A")}
      // on ${moment(nextIntent?.schedule_item.start_time).format("MMMM Do")}`;
      break;
    case "Decision Call":
      // eventTypeValues.label = "Decision Call";
      // eventTypeValues.icon = iconDecisionCallStroke;
      // eventTypeValues.color = intent_is_active ? theme.PRIMARY500 : theme.NEUTRAL200;
      // eventTypeValues.message = `${moment(nextIntent?.schedule_item.start_time).format("h:mm A")}
      // to ${moment(nextIntent?.schedule_item.end_time).format("h:mm A")}
      // on ${moment(nextIntent?.schedule_item.start_time).format("MMMM Do")}`;
      break;
    case "Inbound Call":
      eventTypeValues.icon = iconInboundCallStroke;
      break;
    case "Paused":
      eventTypeValues.icon = iconPausedStroke;
      break;
    default:
      break;
  }
  return eventTypeValues;
};

export const isIntentActive = (lead: EventTypeObject) => {
  if (!lead?.next_intent_scheduled_or_unscheduled) {
    return false;
  }
  const nextIntent = lead.next_intent_scheduled_or_unscheduled;
  const systemTime = moment();
  switch (nextIntent?.event_type_label) {
    case "Callback":
      if (
        systemTime > moment(nextIntent?.anytime_day) &&
        systemTime > moment(nextIntent?.general_time_start_date) &&
        systemTime < moment(nextIntent?.general_time_end_date)
      ) {
        return true;
      } else {
        return false;
      }
    case "Cold Call":
      return true;
    case "Automatic Call Back":
    case "Demo Held Call Back":
    case "Demo Not Held Call Back":
      if (systemTime > moment(nextIntent?.anytime_day)) {
        return true;
      } else {
        return false;
      }
    case "Demo": //commenting this out as it will be needed for call module
    case "Scheduled Call Back":
      if (systemTime > moment(nextIntent?.schedule_item.start_time).subtract(15, "minutes")) {
        return true;
      } else {
        return false;
      }
    case "Other":
    case "Decision Call":
    case "Inbound Call":
    case "Paused":
    default:
      return true;
  }
};

export const displaySanitizedHTML = (item: string) => {
  let newString = item?.replace(/(<([^>]+)>)/gi, "");
  newString = newString?.replace(/&gt;/g, ">");
  newString = newString?.replace(/&lt;/g, "<");
  newString = newString?.replace(/&quot;/g, '"');
  newString = newString?.replace(/&apos;/g, "'");
  newString = newString?.replace(/&amp;/g, "&");
  return newString;
};

export const copyTextToClipboard = (text: string) => {
  navigator.clipboard.writeText(text);
  successToast("Copied to clipboard");
};

export const redirectToLeadDetailPage = (lead_id: string) => {
  window.open(`/lead-detail/${lead_id}`, "_blank");
};

export const LEAD_CREATION_STATUSES = [
  {
    id: "Create",
    text: "Only apply rule when a lead is newly created",
    tip: "A Lead gets created via Hubspot, Salesforce, Zapier, or CSV upload",
  },
  {
    id: "Update",
    text: "Apply rule only when an existing lead is updated",
    tip: "A Lead in Sellfire gets updated via Salesforce, Hubspot, or CSV upload",
  },
  {
    id: "Both",
    text: "Apply rule both when a lead is newly created or when an existing lead is updated",
  },
];

export const LEAD_STATUSES = [
  { title: "Unassigned", value: "Unassigned" },
  { title: "Assigned", value: "Assigned" },
  { title: "Owned", value: "Owned" },
  { title: "Resting", value: "Resting" },
  { title: "Retired", value: "Retired" },
  { title: "Customer", value: "Customer" },
];

export const testIfFieldsAreUnique = (array: any[]) => {
  const arrayFields = array?.map((item: any) => {
    return item.object ? `${item.object}.${item.field}` : item.field;
  });
  const uniqueFields = [...new Set(arrayFields)];

  return arrayFields.length === uniqueFields.length;
};

export const testForArrayWithMultipleElements = (value: string) => {
  try {
    const array = JSON.parse(value);
    console.log("array", array);
    if (Array.isArray(array) && array.length > 1) {
      return true;
    } else return false;
  } catch (error: any) {
    return false;
  }
};

export const testForArrayFormatedAsAString = (value: string) => {
  try {
    const array = JSON.parse(value);
    if (Array.isArray(array)) {
      return true;
    } else return false;
  } catch (error: any) {
    return false;
  }
};

export const arraysAreEqual = (a: string[], b: string[]) => {
  const aSorted = a.slice().sort();
  const bSorted = b.slice().sort();
  return (
    Array.isArray(a) &&
    Array.isArray(b) &&
    a.length === b.length &&
    aSorted.every((val, index) => val === bSorted[index])
  );
};

export const returnIndividualDataFromArrayBasedOnID = ({ id, array }: { id: string; array: string[] }) => {
  if (!array || !array.length) return {};
  if (!id) return {};

  const data = array.slice().filter((item: any) => id === item.id);
  return data[0] ?? { id };
};

export const DUMMY_AVAILABILITY = [
  {
    day: "Monday",
    start_minute: null,
    start_hour: null,
    end_minute: null,
    end_hour: null,
  },
  {
    day: "Tuesday",
    start_minute: null,
    start_hour: null,
    end_minute: null,
    end_hour: null,
  },
  {
    day: "Wednesday",
    start_minute: null,
    start_hour: null,
    end_minute: null,
    end_hour: null,
  },
  {
    day: "Thursday",
    start_minute: null,
    start_hour: null,
    end_minute: null,
    end_hour: null,
  },
  {
    day: "Friday",
    start_minute: null,
    start_hour: null,
    end_minute: null,
    end_hour: null,
  },
  {
    day: "Saturday",
    start_minute: null,
    start_hour: null,
    end_minute: null,
    end_hour: null,
  },
  {
    day: "Sunday",
    start_minute: null,
    start_hour: null,
    end_minute: null,
    end_hour: null,
  },
];

/**
 *  calulates the total price of a product in a sale
 * */
export function calculateProductPrice({
  price_override,
  pricing,
  quantity,
  concession_amount,
}: {
  price_override: number | null | undefined;
  pricing: number | null | undefined;
  quantity: number | null | undefined;
  concession_amount: number | null;
}) {
  if (!pricing || !quantity) return 0;

  return (price_override ?? pricing) * (quantity ?? 1) - (concession_amount ?? 0);
}

/*
 *  sanitizes a url to prevent xss attacks
 *
 * @param url string
 * @returns string
 * */

export const sanitizeURL = (url: string) => {
  if (!url) return "";
  return sanitizeUrl(url) ?? "INVALID URL";
};

/*
 *  validates a url to detect xss attacks
 *
 * @param url string
 * @returns boolean
 * */
export const checkForDangerousURL = (url: string) => {
  if (!url) return false;

  // use dompurify to sanitize url
  let clean = sanitizeURL(url);

  if (url !== clean) {
    return true;
  }
  return false;
};

export enum LEAD_OWNERSHIP_STATUS {
  Unassigned = "Unassigned",
  Assigned = "Assigned",
  Owned = "Owned",
  Resting = "Resting",
  Customer = "Customer",
}

interface makeNextActionDisabledCheckType {
  isOwnedByRep: boolean;
  inCallableStatus: boolean;
  currentLeadStatus?: string;
  isAssociatedLead: boolean;
  nextDialOverrideBlocked: boolean;
  expandedState: boolean;
  showPromptToDial?: boolean;
  leadIsInNextDial: boolean;
}

/*
* checks if a lead can be made the next dial

* @param isOwnedByRep boolean (if the lead is owned by the rep)
* @param inCallableStatus boolean (if the lead is in a callable status aka assigned or owned)
* @param currentLeadStatus string (the current lead status for showing in the tooltip)
* @param isAssociatedLead boolean (if the lead is an associated lead)
* @param nextDialOverrideBlocked boolean (if the lead is blocked from being made the next dial by the current next dial)
* @param expandedState boolean (if the lead is expanded for showing in the tooltip)
* @param showPromptToDial boolean (if we should show the prompt to dial in the tooltip, no for sequence top component)
* @param leadIsInNextDial boolean (if the lead is already in the next dial)
*
* @returns {disabled: boolean, tooltip: string | undefined}
* */
export const makeNextActionDisabledCheck = ({
  isOwnedByRep,
  isAssociatedLead,
  inCallableStatus = true,
  currentLeadStatus,
  nextDialOverrideBlocked,
  expandedState,
  showPromptToDial = true,
  leadIsInNextDial,
}: makeNextActionDisabledCheckType): {
  disabled: boolean;
  tooltip: string | undefined;
} => {
  if (leadIsInNextDial) {
    return {
      disabled: true,
      tooltip: "This lead is already in the Next Dial.",
    };
  }

  if (!isOwnedByRep) {
    return {
      disabled: true,
      tooltip: "You cannot dial leads that you do not own.",
    };
  }

  if (!inCallableStatus) {
    return {
      disabled: true,
      tooltip: currentLeadStatus
        ? `Lead is in the ${currentLeadStatus} status and cannot be dialed.`
        : "Lead must be in the assigned or owned stutus to be dialed.",
    };
  }

  if (nextDialOverrideBlocked) {
    return {
      disabled: true,
      tooltip: `Cannot make another lead the Next Dial when there is an upcoming event. 
        ${
          !showPromptToDial
            ? ""
            : expandedState
            ? " To dial this lead, click the dial icon next to the phone number."
            : " To dial this lead now, expand the lead card."
        }`,
    };
  }

  if (isAssociatedLead) {
    return {
      disabled: true,
      tooltip: "This is an associated lead please select a primary lead to make the next dial",
    };
  }

  return {
    disabled: false,
    tooltip: undefined,
  };
};

/** Get local storage value. If no value is available an empty object is returned.
 * @param key string - Local storage key
 * @param fallback any - Optional fallback value
 * @returns object - Local storage value for the given key or an empty object if no value is available
 */
export const getLocalStorage = (key: string, fallback?: any) => {
  const localStorageData = localStorage.getItem(key);
  if (localStorageData) return JSON.parse(localStorageData);
  return fallback;
};

/** Counts the occurrences of CONTACT_TEMPLATE_TOKENS, LEAD_TEMPLATE_TOKENS and REP_TEMPLATE_TOKENS in the given input string.
 *
 * @param {string} input - The input string to search for template tokens.
 * @returns {{ contactCount: number; repCount: number; leadCount: number }} - An object containing the counts for CONTACT_TEMPLATE_TOKENS, LEAD_TEMPLATE_TOKENS and REP_TEMPLATE_TOKENS.
 * @example
 * const inputString = "<p>[contact_address]</p><p>[rep_email]</p>";
 * const { contactCount, repCount } = countTemplateTokens(inputString);
 * console.log('Contact Token Count:', contactCount); // Output: Contact Token Count: 1
 * console.log('Rep Token Count:', repCount); // Output: Rep Token Count: 1
 */
export const countTemplateTokens = ({
  input,
  contactTokens,
  repTokens,
  leadTokens,
}: {
  input: string;
  contactTokens: TokenForFE[];
  repTokens: TokenForFE[];
  leadTokens: TokenForFE[];
}): { contactCount: number; repCount: number; leadCount: number } => {
  // escape regex special characters
  const escapedContactTokens = contactTokens?.map((token) =>
    token?.token_formatted.replace(/[-\/\\^$*+?.()|[\]{}]/g, "\\$&"),
  );
  const escapedLeadTokens = leadTokens?.map((token) =>
    token?.token_formatted?.replace(/[-\/\\^$*+?.()|[\]{}]/g, "\\$&"),
  );
  const escapedRepTokens = repTokens?.map((token) => token?.token_formatted.replace(/[-\/\\^$*+?.()|[\]{}]/g, "\\$&"));

  const contactRegex = new RegExp(escapedContactTokens.join("|"), "g");
  const leadRegex = new RegExp(escapedLeadTokens.join("|"), "g");
  const repRegex = new RegExp(escapedRepTokens.join("|"), "g");

  const contactMatches = input.match(contactRegex) || [];
  const leadMatches = input.match(leadRegex) || [];
  const repMatches = input.match(repRegex) || [];

  return {
    contactCount: contactMatches.length,
    repCount: repMatches.length,
    leadCount: leadMatches.length,
  };
};

/** Recursively trims string values within an object. Mutates the original object.
 *
 * @param {ObjectType} obj - The object to be trimmed.
 * @returns {void}
 *
 * @example
 * const myObject = {
 *   name: '   John Doe   ',
 *   age: 25,
 *   address: {
 *     street: '   123 Main Street   ',
 *     city: '   Anytown   ',
 *   },
 * };
 *
 * recursiveObjectTrim(myObject);
 * console.log(myObject);
 * // Output:
 * // {
 * //   name: 'John Doe',
 * //   age: 25,
 * //   address: {
 * //     street: '123 Main Street',
 * //     city: 'Anytown',
 * //   },
 * // }
 */
export const recursiveObjectTrim = (obj: ObjectType): void => {
  for (const key in obj) {
    const value = obj[key];

    if (typeof value === "string") {
      // Trim the string value
      obj[key] = value.trim();
    } else if (typeof value === "object" && value !== null) {
      // If the value is an object, recursively call the function
      recursiveObjectTrim(value as ObjectType);
    }
  }
};

export const eventManagerFilterOptions: OptionItem[] = [
  { label: "Follow up demo", value: "Follow Up Demo" },
  { label: "Decision Call", value: "Decision Call" },
  { label: "Demo", value: "Demo" },
  { label: "Scheduled CallBack", value: "ScheduledCallBack" },
];
// ----------- Lead Card Helper Functions ------------

// the lead card displays and checks info from the lead group, consisting of the selected lead and its possibly many associated leads

// the below functions are helper functions for the lead card to get info from the lead group or selected lead in a clean way

/**
 * Returns the primary phone number from selected lead.
 * @param {CurrentLeadType} lead - The selected lead.
 * @returns {string | undefined} The primary phone number.
 */
export const returnLeadPrimaryPhoneNumber = (lead: CurrentLeadType): string | undefined => {
  return lead?.primary_phone_number;
};

/**
 * Returns the primary email from selected lead.
 * @param {CurrentLeadType} lead - The selected lead.
 * @returns {string | undefined} The primary email.
 */
export const returnLeadPrimaryEmail = (lead: CurrentLeadType): string | undefined => {
  return lead?.primary_email;
};

/**
 * Returns the alternate phone numbers from selected lead.
 * @param {CurrentLeadType} lead - The selected lead.
 * @returns {AlternateContactType[] | undefined} The alternate phone numbers.
 */
export const returnLeadAlternatePhoneNumbers = (lead: CurrentLeadType): AlternateContactType[] | undefined => {
  const numbers = lead?.alternate_contacts?.filter((contact: AlternateContactType) => contact.channel === "Phone");

  // sort the numbers bassed on mapping order which is defined in the backend (shows up in UI as Phone Number Label and is a required field.)
  const sortedNumbers = numbers?.sort((a: AlternateContactType, b: AlternateContactType) => {
    return a?.mapping_order - b?.mapping_order || 0;
  });

  return sortedNumbers;
};

/**
 * Returns the alternate emails from selected lead.
 * @param {CurrentLeadType} lead - The selected lead.
 * @returns {AlternateContactType[] | undefined} The alternate emails.
 */
export const returnLeadAlternateEmails = (lead: CurrentLeadType): AlternateContactType[] | undefined => {
  return lead?.alternate_contacts?.filter((contact: AlternateContactType) => contact.channel === "Email");
};

/**
 * Returns the screensharing number from selected lead.
 * @param {CurrentLeadType} lead - The selected lead.
 * @returns {string | undefined} The screensharing number.
 */
export const returnLeadScreensharingNumber = (lead: CurrentLeadType): string | undefined => {
  return lead?.conference_number;
};

/**
 * given a array of leads, returns all the lead's primary phone numbers
 * @typedef {Object} leadGroupPrimaryNumberReturn
 * @property {string} lead_id - The lead ID.
 * @property {string} primary_phone_number - The primary phone number.
 * @returns {LeadGroupPrimaryNumberReturn[] | undefined} The primary phone numbers.
 *
 */
export const returnLeadGroupPrimaryPhoneNumbers = (
  leadGroup: CurrentLeadType[],
): LeadGroupPrimaryNumberReturn[] | undefined => {
  return leadGroup?.map((lead: CurrentLeadType) => {
    return {
      lead_id: lead?.id,
      primary_phone_number: returnLeadPrimaryPhoneNumber(lead) || "",
    };
  });
};

/**
 * given a array of leads, returns all the lead's alternate phone numbers
 * @typedef {Object} leadGroupAlternateNumberReturn
 * @property {string} lead_id - The lead ID.
 * @property {AlternateContactType[]} alternate_phone_numbers - The alternate phone numbers.
 * @param {boolean} returnValueUndefinedLeads - If true, returns all leads, even if they have no alternate phone numbers.
 * @returns {LeadGroupAlternateNumberReturn[] | undefined} The alternate phone numbers.
 */

export const returnLeadGroupAlternatePhoneNumbers = (
  leadGroup: CurrentLeadType[],
  returnValueUndefinedLeads = false,
): LeadGroupAlternateNumberReturn[] | undefined => {
  const leadGroupAlternatePhoneNumbers = leadGroup?.map((lead: CurrentLeadType) => {
    return {
      lead_id: lead?.id,
      alternate_phone_numbers: returnLeadAlternatePhoneNumbers(lead) || [],
    };
  });

  if (returnValueUndefinedLeads) return leadGroupAlternatePhoneNumbers;

  return leadGroupAlternatePhoneNumbers.filter((lead: LeadGroupAlternateNumberReturn) => {
    return lead.alternate_phone_numbers.length > 0;
  });
};

/**
 * given a array of leads, returns all the lead's primary emails
 * @typedef {Object} leadGroupPrimaryEmailReturn
 * @property {string} lead_id - The lead ID.
 * @property {string} primary_email - The primary email.
 * @param {boolean} returnValueUndefinedLeads - If true, returns all leads, even if they have no primary email.
 * @returns {LeadGroupPrimaryEmailReturn[] | undefined} The primary emails.
 */
export const returnLeadGroupPrimaryEmails = (
  leadGroup: CurrentLeadType[],
  returnValueUndefinedLeads = false,
): LeadGroupPrimaryEmailReturn[] | undefined => {
  const leadGroupPrimaryEmails = leadGroup?.map((lead: CurrentLeadType) => {
    return {
      lead_id: lead?.id,
      primary_email: returnLeadPrimaryEmail(lead) || "",
    };
  });

  if (returnValueUndefinedLeads) return leadGroupPrimaryEmails;

  const leadGroupPrimaryEmailsFiltered: LeadGroupPrimaryEmailReturn[] = leadGroupPrimaryEmails.filter(
    (lead: LeadGroupPrimaryEmailReturn) => {
      return lead.primary_email !== "" && !!lead.primary_email;
    },
  );

  return leadGroupPrimaryEmailsFiltered;
};

/**
 *  * given a array of leads, returns all the lead's alternate emails
 * @typedef {Object} leadGroupAlternateEmailReturn
 * @property {string} lead_id - The lead ID.
 * @property {AlternateContactType[]} alternate_emails - The alternate emails.
 * @returns {LeadGroupAlternateEmailReturn[] | undefined} The alternate emails.
 * @param {boolean} returnValueUndefinedLeads - If true, returns all leads, even if they have no alternate emails.
 */
export const returnLeadGroupAlternateEmails = (
  leadGroup: CurrentLeadType[],
  returnValueUndefinedLeads = false,
): LeadGroupAlternateEmailReturn[] | undefined => {
  const leadGroupAlternateEmails = leadGroup?.map((lead: CurrentLeadType) => {
    return {
      lead_id: lead?.id,
      alternate_emails: returnLeadAlternateEmails(lead) || [],
    };
  });

  if (returnValueUndefinedLeads) return leadGroupAlternateEmails;

  const leadGroupAlternateEmailsFiltered: LeadGroupAlternateEmailReturn[] = leadGroupAlternateEmails.filter(
    (lead: LeadGroupAlternateEmailReturn) => {
      return !!lead.alternate_emails.length;
    },
  );

  return leadGroupAlternateEmailsFiltered;
};

/**
 * given a array of leads, returns all the lead's screensharing numbers
 * @typedef {Object} leadGroupScreensharingNumberReturn
 * @property {string} lead_id - The lead ID.
 * @property {string} conference_number - The conference number.
 * @returns {LeadGroupScreensharingNumberReturn[] | undefined} The conference numbers.
 * @param   {boolean} returnValueUndefinedLeads - If true, returns all leads, even if they have no screensharing numbers.
 */
export const returnLeadGroupScreensharingNumber = (
  leadGroup: CurrentLeadType[],
  returnValueUndefinedLeads = false,
): LeadGroupScreensharingNumberReturn[] | undefined => {
  const leadGroupScreensharingNumbers = leadGroup?.map((lead: CurrentLeadType) => {
    return {
      lead_id: lead?.id,
      conference_number: returnLeadScreensharingNumber(lead) || "",
    };
  });

  if (returnValueUndefinedLeads) return leadGroupScreensharingNumbers;

  return leadGroupScreensharingNumbers.filter((lead: LeadGroupScreensharingNumberReturn) => {
    return lead.conference_number !== "" && !!lead.conference_number;
  });
};

// return interfaces for the above functions
export interface LeadGroupPrimaryNumberReturn {
  lead_id: string;
  primary_phone_number: string;
}

export interface LeadGroupAlternateNumberReturn {
  lead_id: string;
  alternate_phone_numbers: AlternateContactType[];
}

export interface LeadGroupPrimaryEmailReturn {
  lead_id: string;
  primary_email: string;
}

export interface LeadGroupAlternateEmailReturn {
  lead_id: string;
  alternate_emails: AlternateContactType[];
}

export interface LeadGroupScreensharingNumberReturn {
  lead_id: string;
  conference_number: string;
}
// ----------- Twilio Device Tab Sync

/**
 * Initializes a new device identifier for tab sync.
 * @param {string} tab_id - The identifier for the new tab device.
 * @returns {boolean} - Whether or not the new tab is active on initialization.
 */
const initializeNewDevice = (tab_id: string) => {
  let isActive = document.visibilityState === "visible";
  const isOnCallLocal = getLocalStorage("userIsOnCallLocal", false);

  if (!!sessionStorage.getItem("tab_id")) {
    refreshToken(tab_id);
    return isActive;
  }

  sessionStorage.setItem("tab_id", tab_id);

  const localStoredDevices = getLocalStorage("saved_devices", []);
  let storedDevices = cloneDeep(localStoredDevices);

  if (!storedDevices.includes(tab_id)) {
    if (isActive && !isOnCallLocal) {
      storedDevices.push(tab_id);
    } else {
      // If we create a new tab without it being focused, we dont want it to be the new focused device.
      // This can happen when the user CTRL+clicks an Sellfire link to open a new tab.
      storedDevices.unshift(tab_id);
    }
  }

  localStorage.setItem("saved_devices", JSON.stringify(storedDevices));
  window.dispatchEvent(new Event("storage"));
  return isActive;
};

/**
 * Removes device identifier from tab sync when unregistering.
 */
const handleUnregister = () => {
  const tab_id = sessionStorage.getItem("tab_id");
  const localStoredDevices = getLocalStorage("saved_devices", []);

  let storedDevices = cloneDeep(localStoredDevices);
  storedDevices = storedDevices.filter((device: string) => device !== tab_id);

  localStorage.setItem("saved_devices", JSON.stringify(storedDevices));
  window.dispatchEvent(new Event("storage"));
};

/**
 * Refreshes the tab with a new device identifier.
 * @param {string} new_tab_id - The new device identifier.
 */
const refreshToken = (new_tab_id: string) => {
  const oldTabId = sessionStorage.getItem("tab_id");

  const localStoredDevices = getLocalStorage("saved_devices", []);
  let storedDevices = cloneDeep(localStoredDevices);

  if (storedDevices.includes(oldTabId)) {
    // replace old tab_id with new tab_id
    storedDevices = storedDevices?.map((id: string) => (id === oldTabId ? new_tab_id : id));
  } else {
    storedDevices.push(new_tab_id);
  }

  localStorage.setItem("saved_devices", JSON.stringify(storedDevices));
  sessionStorage.setItem("tab_id", new_tab_id);
  window.dispatchEvent(new Event("storage"));
};

/**
 * Handles device priority order when switching tabs.
 */
const handleTabSwitch = () => {
  const tab_id = sessionStorage.getItem("tab_id");
  let localStoredDevices = getLocalStorage("saved_devices", []);
  let storedDevices = cloneDeep(localStoredDevices);

  storedDevices = storedDevices.filter((device: string) => device !== tab_id);
  storedDevices.push(tab_id);

  localStorage.setItem("saved_devices", JSON.stringify(storedDevices));
  window.dispatchEvent(new Event("storage"));
};

/** Function container for Twilio Device Tab Sync.
 * @namespace
 * @property {Function} initializeNewDevice - Initializes a new device identifier for tab sync.
 * @property {Function} handleUnregister - Removes device identifier from tab sync when unregistering.
 * @property {Function} refreshToken - Refreshes the tab with a new device identifier.
 * @property {Function} handleTabSwitch - Handles device priority order when switching tabs.
 */
export const tabSync = {
  initializeNewDevice,
  handleUnregister,
  refreshToken,
  handleTabSwitch,
};

export const setEnvDocTitle = (optionalSubtitle?: string) => {
  // this should be Production but just in case there are changes made to the env in the future
  const possibleProdEnvs = ["production", "prod", "main", "master"];
  if (
    process.env.REACT_APP_ENVIRONMENT &&
    !possibleProdEnvs.includes(process.env.REACT_APP_ENVIRONMENT?.toLowerCase() ?? "")
  ) {
    document.title = `${process.env.REACT_APP_ENVIRONMENT.toUpperCase()} ${DEFAULT_DOCUMENT_TITLE} ${
      optionalSubtitle ? ` - ${optionalSubtitle}` : ""
    }`;
  } else {
    document.title = `${DEFAULT_DOCUMENT_TITLE}${optionalSubtitle ? ` - ${optionalSubtitle}` : ""}`;
  }
};

interface createSystemConfigNavProps {
  showFieldManager?: boolean;
  showCustomObject?: boolean;
  showLeadRanking: boolean;
  showRegisterDomain: boolean;
  showMakeSaleConfig: boolean;
  showCallRecordingStorage: boolean;
  showSMSSettings: boolean;
  showOpsiqForms: boolean;
  showCallNoteTemplates: boolean;
  emailSignature: boolean;
  leadCardConfig: boolean;
  sellfireLabels: boolean;
  inboundCallRouting: boolean;
}
export function createSystemConfigNav({
  showFieldManager = false,
  showCustomObject = false,
  showLeadRanking = false,
  leadCardConfig = false,
  showRegisterDomain = false,
  showMakeSaleConfig = false,
  showCallRecordingStorage = false,
  showSMSSettings = false,
  showOpsiqForms = false,
  showCallNoteTemplates = false,
  emailSignature = false,
  sellfireLabels = false,
  inboundCallRouting = false,
}: createSystemConfigNavProps): SystemConfigNavShape[] {
  // add any optional nav items here

  const fieldManagerItem = showFieldManager
    ? {
        title: "Field Manager",
        path: "/system-config/field-manager",
        tab_name: "field-manager",
        description:
          "Create as many custom fields as you need, determine if reps can view and/or edit those fields in Lead Cards, and manage your organization's Lead Source, Industry, Sub-Industry, Default Revenue Forecast, and Default Close Date values.",
      }
    : false;

  const customObjectItem = showCustomObject
    ? {
        tab_name: "object-manager",
        title: "Object Manager",
        path: "/system-config/object-manager",
        description: "N/A",
      }
    : false;

  const leadRankingItem = showLeadRanking
    ? {
        title: "Lead Ranking",
        path: "/system-config/lead-ranking",
        tab_name: "lead-ranking",
        description: "Here you can set up your organization's lead ranking system",
      }
    : false;

  const customizeLeadCardItem = leadCardConfig
    ? {
        title: "Customize Lead Card",
        path: "/system-config/customize-lead-card",
        tab_name: "customize-lead-card",
        description: "Here you can customize the layout of the lead card",
      }
    : false;

  const smsSettingsItem = showSMSSettings
    ? { title: "SMS Settings", path: "/system-config/sms-settings", tab_name: "sms-settings", description: "N/A" }
    : false;

  const callNoteTemplatesItem = showCallNoteTemplates
    ? {
        title: "Call Notes Template",
        path: "/system-config/call-notes-template",
        tab_name: "call-notes-template",
        description: "Here you can create and edit a call notes template for your reps to use",
      }
    : false;

  const registerDomainItem = showRegisterDomain
    ? {
        title: "Register Domain",
        path: "/system-config/register-domain",
        tab_name: "register-domain",
        description: "Here you can register your organization's domain that will be used in automated actions",
      }
    : false;

  const makeSaleConfigItem = showMakeSaleConfig
    ? {
        title: "Make Sale Configuration",
        path: "/system-config/make-sale-config",
      }
    : false;

  const makeSaleConfigProductsItem = showMakeSaleConfig
    ? {
        title: "Manage Products",
        path: "/system-config/manage-products",
      }
    : false;

  const manageLabelsItem = sellfireLabels
    ? {
        title: "Manage Labels",
        path: "/system-config/manage-labels",
      }
    : false;

  const callRecordingStorageItem = showCallRecordingStorage
    ? {
        title: "Call Recording Storage",
        path: "/system-config/recording-storage",
        tab_name: "recording-storage",
        description: "Here you can update your call recording storage settings",
      }
    : false;

  const opsiqFormsItem = showOpsiqForms
    ? {
        title: "Sellfire Forms",
        path: "/system-config/sellfire-forms",
        tab_name: "sellfire-forms",
        description: "Here you can create and manage your Sellfire forms",
      }
    : false;

  const emailSettings = emailSignature
    ? {
        title: "Email Settings",
        path: "/system-config/email-settings",
        tab_name: "email-settings",
      }
    : false;
  // returns the system config nav items with the optional items filtered out if falsey

  const inboundRoutingRuleItem = inboundCallRouting
    ? {
        title: "Inbound Routing Rules",
        path: "/system-config/inbound-routing-rules",
        tab_name: "inbound-routing-rules",
        description: "Here you can create and manage your inbound routing rules",
      }
    : false;

  return [
    {
      drawer_name: "Your Organization",
      items: [
        {
          path: "/system-config/scheduling",
          tab_name: "scheduling",
          title: "Scheduling Details",
          description:
            "Here you can configure the opening and closing times for your Organization. These times will set the availability for reps to schedule demos and other Events",
        },
        {
          path: "/system-config/enablement",
          tab_name: "enablement",
          title: "Enablement Users",
          description:
            "Here you can manage who has administrator access to Sellfire. Enablement Users will be able to view data from a company-wide level, edit the company-wide settings in System Configuration, see how the algorithm is performing in System View, and veto Lead updates.",
        },
        {
          title: "Sales Teams",
          path: "/system-config/sales-team",
          tab_name: "sales-team",
          description:
            "Here you will set up your organization's sales teams, and set assignments for reps and their sales managers. You may either upload your teams via bulk CSV upload or create each new user individually. Once uploaded, the use the module below to click and drag reps for re-assignment. The metric averages are intended to help you balance the teams. For reference on how to properly upload users via CSV, please download the CSV template.",
        },
        fieldManagerItem as any,
        customObjectItem as any,
        {
          title: "Rep Goals",
          path: "/system-config/rep-goals",
          tab_name: "rep-goals",
          description: "Here you can change the daily goals of a rep",
        },
        registerDomainItem as any,
        makeSaleConfigItem as any,
        makeSaleConfigProductsItem as any,
        manageLabelsItem as any,
      ].filter((item) => item !== false),
    },
    {
      drawer_name: "Templates and Automation",
      items: [
        {
          title: "Automated Messaging",
          path: "/system-config/automated-campaign",
          tab_name: "automated-campaign",
          description:
            "Here you can set up automated texts and emails to send to Leads around a scheduled event, such as a Demos, Decision Calls and Call Backs.",
        },
        emailSettings as any,
        {
          title: "Email Templates",
          path: "/system-config/email-templates",
          tab_name: "email-templates",
          description: "Here you can create and edit email templates for your reps to use",
        },
        {
          title: "SMS Templates",
          path: "/system-config/sms-templates",
          tab_name: "sms-templates",
          description: "Here you can create and edit SMS templates for your reps to use",
        },

        smsSettingsItem as any,

        callNoteTemplatesItem as any,
      ].filter((item) => item !== false),
    },
    {
      drawer_name: "Lead Management",
      items: [
        {
          title: "Import Leads",
          path: "/system-config/import-leads",
          tab_name: "import-leads",
          description: `Here you can import leads in bulk via CSV or individually. If importing via CSV, the column (field) names must match the column (field) names in Sellfire exactly. To ensure you are using the correct names, download the CSV template.
          There are three required fields: primary_phone_number, business_name, and state. If including industry and sub_industry, those must match industries and sub industries that are already in Sellfire under "System Configuration > Field Manager > Industry."
          To see any error reasons, scroll to the far right column of the error report.`,
        },
        {
          title: "Import Activity",
          path: "/system-config/import-activity",
          tab_name: "import-activity",
          description: `Here you can import lead activity in bulk via CSV or individually. If importing via CSV, the column (field) names must match the column (field) names in Sellfire exactly. To ensure you are using the correct names, download the CSV template.`,
        },
        {
          title: "Rules of Engagement",
          path: "/system-config/engagement-rules",
          tab_name: "engagement-rules",

          description: "You can configure rules for how long your leads stay in the resting pool here",
        },
        leadRankingItem as any,
        customizeLeadCardItem,
      ].filter((item) => item !== false),
    },
    {
      drawer_name: "Transfers and Routing",
      items: [
        opsiqFormsItem as any,
        {
          title: "Inbound Concierge",
          path: "/system-config/inbound-concierge",
          tab_name: "inbound-concierge",
          description: "Here you can configure the rules for your inbound concierge widget.",
        },
        {
          title: "Routing Rules",
          path: "/system-config/routing-rules",
          tab_name: "routing-rules",
          description: "Here you can tell Sellfire to automatically assign leads if they meet your conditions",
        },
        {
          title: "Event Transfer Rules",
          path: "/system-config/transfer-rules",
          tab_name: "transfer-rules",
          description: "Here you can configure your event transfer rules for both live calls and future bookings",
        },
        inboundRoutingRuleItem as any,
      ].filter((item: any) => item !== false),
    },
    {
      drawer_name: "Call Settings",
      items: [
        {
          title: "Call Pacing",
          path: "/system-config/call-pacing",
          tab_name: "call-pacing",
          description: "Here you can select if auto-dial will be enabled and its timer",
        },
        callRecordingStorageItem as any,
      ].filter((item: any) => item !== false),
    },
    {
      drawer_name: "Integrations",
      items: [],
      path: "/system-config/integrations",
      tab_name: "integrations",
      title: "Integrations",
      description: "Here you can migrate your Salesforce and HubSpot accounts to Sellfire",
    },
  ];
}

export const TERMS_OPTIONS = [
  { label: "Weekly", value: "WEEKLY" },
  { label: "Monthly", value: "MONTH_TO_MONTH" },
  { label: "Quarterly", value: "QUARTERLY" },
  { label: "Annually", value: "YEARLY" },
  { label: "Biannually", value: "TWO_YEAR" },
];

/** Converts BE field type to UI field type
 *
 * Used in Make Sale Config page */
export const getFieldType = (field: string, required: boolean) => {
  switch (field) {
    case "SYSTEM_FIELD":
      return required ? DraggableFieldType.REQUIRED_FIELD : DraggableFieldType.FIELD;
    case "CUSTOM_FIELD":
      return DraggableFieldType.FIELD;
    case "NOTE":
      return DraggableFieldType.NOTE;
    default:
      return DraggableFieldType.FIELD;
  }
};

export type FieldItem<T> = T & {
  baseType: DraggableFieldType;
  inputType?: string;
};

export type FormField = {
  custom_field_id: string | null;
  custom_field: CustomField | null;
  hidden_field: boolean | null;
  hidden_value: any;
  id: string;
  is_referrer: boolean | null;
  order: number | null;
  required_by_form: boolean;
  required_by_IBC: boolean;
  required: boolean | null;
  row?: number;
  system_field: string;
  type: "SYSTEM_FIELD" | "CUSTOM_FIELD";
};

export type SaleConfigSection = {
  content?: string;
  custom_field_id?: string;
  custom_field?: string;
  id: string;
  required_field?: boolean;
  required: boolean;
  system_field?: string;
  type: "NOTE" | "WARNING" | "LINK" | "HEADER" | "SYSTEM_FIELD" | "CUSTOM_FIELD" | "PANDADOC";
};

export type SaleConfigPage = {
  id: string;
  order: number;
  title: string;
  sections: SaleConfigSection[];
  required?: boolean;
};

export interface CustomField {
  allow_reps_to_edit?: boolean;
  id: string;
  value: string | number | boolean;
  num_value?: number;
  list_value?: string[];
  boo_value?: boolean;
  date_value?: string;
  type: string;
  key: string;
  options: string[];
  visible?: boolean;
}

export type SaleFlowSection = {
  id: string;
  type: "NOTE" | "WARNING" | "LINK" | "HEADER" | "SYSTEM_FIELD" | "CUSTOM_FIELD" | "PANDADOC";
  required: boolean;
  required_field?: boolean;
  content?: string;
  system_field?: string;
  custom_field?: CustomField;
};

export type SaleFlowPage = {
  id: string;
  order: number;
  title: string;
  sections: SaleFlowSection[];
  required?: boolean;
};

export const getSystemFieldInputType = (field: string | undefined) => {
  switch (field) {
    case "BUSINESS_NAME":
    case "FIRST_NAME":
    case "LAST_NAME":
    case "EMAIL":
    case "ADDRESS":
    case "PRIMARY_PHONE_NUMBER":
    case "CITY":
    case "ZIP_CODE":
      return "Text";

    case "COUNTRY":
    case "STATE":
    case "INDUSTRY":
    case "SUB_INDUSTRY":
    case "LEAD_SOURCE":
    case "CONTRACT_DURATION":
    case "PAYMENT_TERMS":
      return "Dropdown";

    default:
      return "Text";
  }
};

export const leadFieldOptions = [
  { label: "Business name", value: "BUSINESS_NAME" },
  { label: "First name", value: "FIRST_NAME" },
  { label: "Last name", value: "LAST_NAME" },
  { label: "Full name", value: "FULL_NAME" },
  { label: "Channel", value: "CHANNEL" },
  { label: "Email", value: "EMAIL" },
  { label: "Address", value: "ADDRESS" },
  { label: "Primary phone number", value: "PRIMARY_PHONE_NUMBER" },
  { label: "City", value: "CITY" },
  { label: "Zip code", value: "ZIP_CODE" },
  { label: "Country", value: "COUNTRY" },
  { label: "State/Province", value: "STATE" },
  { label: "Industry", value: "INDUSTRY" },
  { label: "Sub Industry", value: "SUB_INDUSTRY" },
  { label: "Lead Source", value: "LEAD_SOURCE" },
  { label: "MRR", value: "MRR" },
  { label: "Current Close Date", value: "CURRENT_CLOSE_DATE" },
];

export const systemFieldOptions = [
  { label: "Business name", value: "BUSINESS_NAME" },
  { label: "First name", value: "FIRST_NAME" },
  { label: "Last name", value: "LAST_NAME" },
  { label: "Email", value: "EMAIL" },
  { label: "Address", value: "ADDRESS" },
  { label: "Primary phone number", value: "PRIMARY_PHONE_NUMBER" },
  { label: "City", value: "CITY" },
  { label: "Zip code", value: "ZIP_CODE" },
  { label: "Country", value: "COUNTRY" },
  { label: "State/Province", value: "STATE" },
  { label: "Industry", value: "INDUSTRY" },
  { label: "Sub Industry", value: "SUB_INDUSTRY" },
  { label: "Lead Source", value: "LEAD_SOURCE" },
  { label: "Contract Duration", value: "CONTRACT_DURATION" },
  { label: "Payment Terms", value: "PAYMENT_TERMS" },
  { label: "Product Selection", value: "PRODUCT_SELECTION" },
  { label: "Sale Note", value: "SALE_NOTE" },
  { label: "Lead ID Label", value: "LEAD_ID_LABEL" },
  { label: "MRR", value: "MRR" },
  { label: "Channel", value: "CHANNEL" },
];

export const SYSTEM_FIELD_NAME_TO_LABEL = {
  BUSINESS_NAME: "Business name",
  FIRST_NAME: "First name",
  LAST_NAME: "Last name",
  EMAIL: "Email",
  ADDRESS: "Address",
  PRIMARY_PHONE_NUMBER: "Primary phone number",
  CITY: "City",
  ZIP_CODE: "Zip code",
  COUNTRY: "Country",
  STATE: "State/Province",
  INDUSTRY: "Industry",
  SUB_INDUSTRY: "Sub Industry",
  LEAD_SOURCE: "Lead Source",
  CONTRACT_DURATION: "Contract Duration",
  PAYMENT_TERMS: "Payment Terms",
  PRODUCT_SELECTION: "Product Selection",
  SALE_NOTE: "Sale Note",
  LEAD_ID_LABEL: "Lead ID Label",
  MRR: "MRR",
  CHANNEL: "Channel",
};

/** Formats the field data for make sale config page UI. Assigns field types and determines if a field is required to be on a page. */
export const formatMakeSaleConfigFields = (
  fieldData: SaleConfigSection[] | undefined,
  requiredFieldData: string[],
  customFieldData: any[], // TODO: add type
) => {
  const newFieldData = fieldData?.map((f: SaleConfigSection) =>
    requiredFieldData.includes(f?.system_field || "") ? { ...f, required_field: true } : f,
  );

  return newFieldData?.map((f: SaleConfigSection) => {
    const customFieldItem = customFieldData?.find((cf: any) => f.custom_field_id === cf.id);

    const fieldBaseType =
      f.required_field === true
        ? DraggableFieldType.REQUIRED_FIELD
        : f.type === "NOTE" || f.type === "WARNING" || f.type === "LINK"
        ? DraggableFieldType.NOTE
        : f.type === "HEADER"
        ? DraggableFieldType.HEADER
        : f.type === "PANDADOC"
        ? DraggableFieldType.PANDADOC
        : DraggableFieldType.FIELD;
    return {
      ...f,
      baseType: fieldBaseType,
      custom_field: customFieldItem ? customFieldItem.id : null,
      inputType: !!f.custom_field_id ? customFieldItem?.type || "Text" : getSystemFieldInputType(f?.system_field || ""),
    };
  });
};

const convertHiddenValueToField = (field: FormField & { value?: string }) => {
  const customField = field.custom_field;

  if (!customField) {
    return {
      value: field.value ?? field.hidden_value,
    };
  }

  switch (customField.type) {
    case "Boolean":
      return {
        boo_value: field?.hidden_value?.toLowerCase() === "true" ? true : false,
      };
    case "Date":
      return {
        value: field.value ?? field.hidden_value,
      };
    case "DateTime":
      return {
        date_value: field.hidden_value,
      };
    case "MultiDropdown":
      return {
        // @ts-ignore
        list_value: field.hidden_value?.split(", ")?.map((k) => k) ?? [],
      };

    case "MultiText":
      return {
        list_value:
          // @ts-ignore
          field.hidden_value?.split(", ")?.map((k) => ({
            value: k,
            label: k,
            __isNew__: true,
          })) ?? [],
      };

    case "Rate":
    case "Number":
    case "Percentage":
      return {
        num_value: parseFloat(field.hidden_value as any),
      };

    case "Dropdown":
    case "Text":
    default:
      return {
        value: field.value ?? field.hidden_value,
      };
  }
};

export const formatFormFields = (
  fieldData: (FormField & { value?: string })[] | undefined,
  allSystemFields: string[],
  allCustomFields: any[],
): FieldItem<FormField>[] => {
  const newFieldData = fieldData?.map((f, index) => {
    const isSystemField = f?.type === "SYSTEM_FIELD" || allSystemFields.includes(f.system_field || "");
    const isHiddenField = Boolean(f.hidden_field);
    const customFieldItem = allCustomFields.find((cf: CustomField) => f.custom_field_id === cf.id);

    const fieldBaseType =
      f.required_by_IBC || f.required_by_form ? DraggableFieldType.REQUIRED_FIELD : DraggableFieldType.FIELD;

    return {
      ...f,
      row: f.row ?? index,
      baseType: fieldBaseType,
      custom_field: customFieldItem ?? null,
      custom_field_id: customFieldItem?.id ?? null,
      inputType: customFieldItem?.type ?? "Text",
      type: (isSystemField ? "SYSTEM_FIELD" : "CUSTOM_FIELD") as "SYSTEM_FIELD" | "CUSTOM_FIELD",
      ...(isHiddenField ? convertHiddenValueToField(f) : {}),
    };
  });

  return newFieldData ?? [];
};

export const fieldItemToFormFieldInput = (fieldItem: (FormField | FieldItem<FormField>) & { custom_id?: boolean }) => {
  return {
    custom_field_id: fieldItem.custom_field_id ?? null,
    hidden_field: fieldItem.hidden_field,
    hidden_value: fieldItem.hidden_value,
    id: fieldItem.custom_id ? undefined : fieldItem.id,
    is_referrer: fieldItem.is_referrer,
    order: fieldItem.order,
    required: fieldItem.required,
    system_field: fieldItem.system_field ?? null,
    row: fieldItem.row ?? null,
  };
};

export const convertHiddenCustomFieldValueToValue = (
  field: (FormField | FieldItem<FormField>) & {
    boo_value?: any;
    date_value?: any;
    list_value?: any;
    num_value?: any;
    value?: string;
  },
) => {
  const customField = field.custom_field;
  let value;

  if (!customField) {
    return field.value;
  }

  switch (customField.type) {
    case "MultiDropdown":
    case "MultiText":
      value = field?.list_value?.map((k: any) => k.value ?? k).join(", ");
      break;
    case "Date":
      value = field.date_value;
      break;
    case "Number":
    case "Rate":
    case "Percentage":
      value = parseFloat(field.num_value as any).toString();
      break;
    case "Boolean":
      value = JSON.stringify(field.boo_value);
      break;
    case "Dropdown":
    case "Text":
    default:
      value = field.value;
      break;
  }

  return value;
};

export type FormFieldRow = {
  id: string;
  fields: any[];
};

export const generateRowsFromFields = (fields: any[]): FormFieldRow[] => {
  const rows: FormFieldRow[] = [];
  fields.forEach((field) => {
    const rowIndex = field.row || 0;
    if (!rows[rowIndex]) {
      rows[rowIndex] = { id: uuidv4(), fields: [] };
    }
    rows[rowIndex].fields.push(field);
  });
  return rows.filter((row) => row !== undefined);
};

export const cleanUpFormFieldRows = (fields: any[]) => {
  const sortedFields = [...fields].sort((a, b) => {
    if (a.row === b.row) {
      return a.order - b.order;
    }
    return a.row - b.row;
  });

  const groupedFields = sortedFields.reduce((acc, field) => {
    if (!acc[field.row]) {
      acc[field.row] = [];
    }
    acc[field.row].push(field);
    return acc;
  }, {});

  let newRow = 0;
  let order = 0;

  return Object.values(groupedFields).flatMap((rowFields: any) => {
    newRow++;
    return rowFields?.map((field: any) => ({
      ...fieldItemToFormFieldInput(field),
      row: newRow - 1,
      order: order++,
    }));
  });
};

export const copyToClipboard = (text: string) => {
  navigator.clipboard.writeText(text);
  appToast("Copied to clipboard.");
};

export const ON_DIAL_WARNING_TOOLTIP =
  "To dial, select a call result and click the “Would you like to try an alternate contact” checkbox before confirming it.";

export const QUEUE_SORT_OPTIONS = [
  { label: "Organization Order", value: "OrganizationOrder" },
  { label: "Predicted Dial Value", value: "HighestPDV" },
  { label: "Most Recently Dialed", value: "MostRecentDial" },
  { label: "Longest Dial Since", value: "LastSinceDialed" },
  { label: "Most Recently Added", value: "MostRecentlyAdded" },
];

// last minute request and not ideal.

// used for replacing native sort icons with custom icons in AGGrid. Wasn't working with img/phoenixIcon. need to refactor when we have bandwidth

export const getUpIconHTML = (): string => {
  return `
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M18.5 15L12.5 9L6.5 15" stroke="#3E65FF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  `;
};

export const getDownIconHTML = (): string => {
  return `
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M6.5 9L12.5 15L18.5 9" stroke="#3E65FF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  `;
};

export const isValidURL = (str: string) => {
  return URL_REGEX.test(str) && !EMAIL_REGEX.test(str);
};
export function isBalancedParentheses(str: string) {
  const stack = [];
  for (let char of str) {
    if (char === "(") {
      stack.push(char);
    } else if (char === ")") {
      if (!stack.length) return false;
      stack.pop();
    }
  }
  return stack.length === 0;
}

export const ROUTING_LOG_TABLE_DATE_OPTIONS = [
  {
    label: "All Time",
    value: {
      lowerbound_date: null, // passing undefined to BE will return all time
      upperbound_date: null,
    },
  },
  {
    label: "Today",
    value: {
      lowerbound_date: moment().startOf("day"),
      upperbound_date: moment().endOf("day"),
    },
  },
  {
    label: "Last Month",
    value: {
      lowerbound_date: moment().subtract(1, "month").startOf("month"),
      upperbound_date: moment().subtract(1, "month").endOf("month"),
    },
  },
  {
    label: "This Month",
    value: {
      lowerbound_date: moment().startOf("month"),
      upperbound_date: moment().endOf("month"),
    },
  },
  {
    label: "Last Six Months",
    value: {
      lowerbound_date: moment().subtract(6, "months").startOf("month"),
      upperbound_date: moment(),
    },
  },
  {
    label: "Last 12 Months",
    value: {
      lowerbound_date: moment().subtract(1, "years").startOf("month"),
      upperbound_date: moment(),
    },
  },
  {
    label: "Last Year",
    value: {
      lowerbound_date: moment().subtract(1, "year").startOf("year"),
      upperbound_date: moment().subtract(1, "year").endOf("year"),
    },
  },
];
export const CALL_LIBRARY_TIME_FRAME_OPTIONS = [
  { label: "All Time", value: null },
  { label: "Custom Time Frame", value: [null, null] },
  {
    label: "Last Month",
    value: [
      moment().subtract(1, "months").utc().startOf("month").toDate(),
      moment().subtract(1, "months").utc().endOf("month").toDate(),
    ],
  },
  { label: "This Month", value: [moment().utc().startOf("month").toDate(), moment().utc().endOf("month").toDate()] },
  {
    label: "Last Six Months",
    value: [moment().subtract(6, "months").utc().startOf("month").toDate(), moment().utc().endOf("month").toDate()],
  },
  {
    label: "Last 12 Months",
    value: [moment().subtract(12, "months").utc().startOf("month").toDate(), moment().utc().endOf("month").toDate()],
  },
  {
    label: "Last Year",
    value: [
      moment().subtract(1, "year").utc().startOf("year").toDate(),
      moment().subtract(1, "year").utc().endOf("year").toDate(),
    ],
  },
];

export const CALL_LIBRARY_CALL_TYPE_OPTIONS = [
  { label: "Transfer Demo", value: "Transfer Demo" },
  { label: "Schedule FU Demo/Schedule Callback", value: "Schedule Follow Up Demo/Schedule Callback" },
  { label: "Schedule FU Demo", value: "Schedule Follow Up Demo" },
  { label: "Schedule Demo", value: "Schedule Demo" },
  { label: "Schedule Decision Call", value: "Schedule Decision Call" },
  { label: "Schedule Callback", value: "Schedule Callback" },
  { label: "Reschedule Demo/Schedule Callback", value: "Reschedule Demo/Schedule Callback" },
  { label: "Reschedule Demo", value: "Reschedule Demo" },
  { label: "Make Sale", value: "Make Sale" },
];

export const CALL_LIBRARY_RECORDING_TRACK_OPTIONS = [
  { label: "Both", value: "both" },
  { label: "Inbound", value: "inbound" },
  { label: "Outbound", value: "outbound" },
];

/**
 * Converts a duration in seconds to a formatted timestamp string.
 * The format of the timestamp is "h:mm:ss", where hours are not zero-padded.
 *
 * @param {number} seconds - The duration in seconds to convert.
 * @returns {string} The formatted timestamp as a string.
 */
export const convertSecondsToTimestamp = (seconds: number) => {
  const duration = moment.duration(seconds, "seconds");
  return `${duration.hours()}:${duration
    .minutes()
    .toString()
    .padStart(2, "0")}:${duration.seconds().toString().padStart(2, "0")}`;
};

/** Returns an Sellfire call score color based on score value */
export const getScoreColor = (score: number) => {
  if (score >= 80) return theme.fill.success.primary;
  else if (score >= 60) return theme.fill.warning.primary;
  else if (score >= 40) return theme.fill.dataviz4.inverse;
  else return theme.fill.danger.primary;
};

/** Returns description of call score metric */
export const getCallScoreDescription = (title: string) => {
  switch (title) {
    case "Communication & Engagement":
      return "Gauges the effectiveness of a sales representative's communication style and their ability to engage with the prospect. It considers aspects such as the setting agenda, creating alignment, active listening signals, and the ability to maintain a balanced conversation flow.";
    case "Forward Motion":
      return "Evaluates the progression of the sales conversation towards a positive outcome, such as setting a follow-up meeting, securing a commitment, or closing a deal. It measures the representative's ability to move the conversation forward effectively and purposefully.";
    case "Question Handling":
      return "Assesses the sales representative's ability to answer questions and handle objections from the prospect. It checks the quality of responses and how well the sales representative handles objections.";
    case "Sale Process":
      return "Measures how well the sales representative adheres to the organization's sales process and protocols. It involves checking if the representative is following the BANT methodology in qualifying a prospect.";
    default:
      return "";
  }
};

export const hasValue = (value: any): boolean => {
  if (isEmpty(value)) return false;

  if (isObject(value)) {
    return some(value, (val) => hasValue(val));
  }

  return Boolean(value);
};

export const defaultLeadCardConfig = [
  { label: "Full name", value: "FULL_NAME" },
  { label: "State", value: "STATE" },
  { label: "City", value: "CITY" },
  { label: "Zip code", value: "ZIP_CODE" },
  { label: "Industry", value: "INDUSTRY" },
  { label: "Sub Industry", value: "SUB_INDUSTRY" },
  { label: "Lead Source", value: "LEAD_SOURCE" },
  { label: "Channel", value: "CHANNEL" },
  { label: "MRR", value: "MRR" },
  { label: "Current Close Date", value: "CURRENT_CLOSE_DATE" },
];
